import {
  Dialog,
  TextField,
  PrimaryButton,
  DialogFooter,
  IconButton,
} from "@fluentui/react"
import isEmpty from "lodash/isEmpty"

import { EMAIL_REGEX } from 'constants/email'

import "./EditApprovers.scss"

const EditApprovers = ({ isOpen, onChange, onCreate, onDelete, onClose, modalProps, approverEmail, approverEmailList }) => {
  const handleEmailKey = (event) => {
    if (event.charCode === 13) {
      onCreate()
    }
  }
  const handleEmailError = (value) => {
    if (EMAIL_REGEX.test(value) || value === "") {
      return ''
    } else {
      return "Email must be a valid syntax"
    }
  }

  const handleDeleteApproverCurry = (email) => {
    return () => {
      onDelete(email)
    }
  }

  return (
    <Dialog
      className="editApproversDialog"
      hidden={!isOpen}
      onDismiss={onClose}
      dialogContentProps={modalProps}
      >
      <TextField
        type="email"
        onChange={onChange}
        value={approverEmail}
        onKeyPress={handleEmailKey}
        onGetErrorMessage={handleEmailError}
        deferredValidationTime={750}
      />
      <PrimaryButton className="approverAddButton" iconProps={{ iconName: 'Add' }} onClick={onCreate}>
        Add
      </PrimaryButton>
      <div className="approverEmailList">
        { !isEmpty(approverEmailList) && approverEmailList.map((email) => {
          return (
            <div key={email} className="approverEmail">
              {email}
              <IconButton
                className="deleteButton"
                iconProps={{ iconName: "Delete" }}
                title="Delete"
                ariaLabel="Delete"
                onClick={handleDeleteApproverCurry(email)}
              />
            </div>
          )
        })}  
      </div>
      <DialogFooter>
        <PrimaryButton onClick={onClose} text="Done" />
      </DialogFooter>
    </Dialog>
  )
}

EditApprovers.defaultProps = {
  approverEmailList: [],
}

export default EditApprovers