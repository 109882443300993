import { DefaultButton } from "@fluentui/react"
import { useHistory } from "react-router"

import './BackButton.scss'

const BackButton = () => {
  const history = useHistory()

  return (
    <DefaultButton className="backButton" onClick={() => history.goBack()}>
      Back
    </DefaultButton>
  )
}

export default BackButton
