import './SetSalary.scss'

import { Position, PrimaryButton, SpinButton } from '@fluentui/react'
import { useDispatch } from 'react-redux'
import { setPreviewSalary, setPreviewStatus } from 'actions/preview'
import { SELECT_REWARDS } from 'constants/preview'
import { useSelector } from 'react-redux'
import { selectPreviewSalary } from 'selectors/preview'

const SetSalary = () => {
  const dispatch = useDispatch()
  const salary = useSelector(selectPreviewSalary)

  const handleSalaryChange = (_ev, value) => {
    const newValue = parseInt(value)
    dispatch(setPreviewSalary(newValue))
  }

  const handleValidateBaseSalary = (value, _ev) => {
    return Number(value.replace(/[^0-9.]+/g,""))
  }

  const handleIncrementBaseSalary = () => {
    return salary + 100
  }

  const handleDecrementBaseSalary = () => {
    return Math.max(0, salary - 100)
  }

  const handleSubmit = () => {
    dispatch(setPreviewStatus(SELECT_REWARDS))
  }

  return (
    <>
      <h1>Input Your Salary</h1>
      <div className="setSalary">
        <p>
        First, input your desired base salary. Don’t worry, this is just a demonstration so type in whatever salary you want!
        </p>
        <SpinButton
          className="salarySpinner"
          label="Salary"
          labelPosition={Position.top}
          value={`$${salary.toLocaleString('en-us')}`}
          min={0}
          step={100}
          onChange={handleSalaryChange}
          onValidate={handleValidateBaseSalary}
          onIncrement={handleIncrementBaseSalary}
          onDecrement={handleDecrementBaseSalary}
          incrementButtonAriaLabel="Increase value by 100"
          decrementButtonAriaLabel="Decrease value by 100"
        />
        <PrimaryButton className="nextButton" disabled={salary <= 0} onClick={handleSubmit}>
          Next Step
        </PrimaryButton>
      </div>
    </>
  )
}

export default SetSalary
