import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectNotifications } from 'selectors/notifications'

import { CLEAR_NOTIFICATION } from 'constants/actions'

import './Notifications.scss'

const Notification = ({ notification }) => {
  const dispatch = useDispatch()
  const handleClear = useCallback((id) => {
    dispatch({
      type: CLEAR_NOTIFICATION,
      data: {
        id,
      }
    })
  }, [dispatch])

  useEffect(() => {
    setTimeout(() => {
      handleClear(notification.id)
    }, 8000)
  }, [notification, handleClear])

  return (
    <div className={notification.type}>
      {notification.message}
    </div>
  )
}

const Notifications = () => {
  const notifications = useSelector(selectNotifications)

  return (
    <div className="notifications">
      {notifications.map((notification) => <Notification key={notification.id} notification={notification} />)}
    </div>
  )
}

export default Notifications
