import { JWT_TOKEN } from 'constants/auth'

export const setAuthHeader = (token) => {
  localStorage.setItem(JWT_TOKEN, token)
}

export const clearAuthHeader = () => {
  localStorage.removeItem(JWT_TOKEN)
}

export const getAuthHeader = () => {
  const token = localStorage.getItem(JWT_TOKEN)

  return token && `Bearer ${token}`
}

export const isAuthenticated = () => Boolean(getAuthHeader())

export const parseJwt = (token) => {
  let jsonPayload
  token = token || localStorage.getItem(JWT_TOKEN)

  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
  } catch (e) {
    return null
  }

  return JSON.parse(jsonPayload)
}
