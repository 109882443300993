import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { useEffect, useState } from "react"
import { find, isNumber } from "lodash"

import { getCompanyRewards, getCompanyRoles, archiveEmployee, unarchiveEmployee, getCompanyUser } from "actions/company"
import { verifyBundle } from "actions/bundle"
import { selectCompanyUserBundle, selectCompanyUserById, selectCompany, selectCompanyRoles, selectAllCompanyRewards } from "selectors/company"
import { generateRewardMapping } from "util/rewards"
import BundleSelections from "components/shared/BundleSelections"
import BackButton from 'components/shared/BackButton'
import { Dialog, DialogFooter, PrimaryButton, DefaultButton, Icon } from "@fluentui/react"

import './Employee.scss'
import EditEmployee from "./EditEmployee"
import ArchiveButton from "./ArchiveButton"

const Employee = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  let employeeStatus

  const [verifyDialogOpen, setVerifyDialogOpen] = useState(false)

  const companyId = useSelector(selectCompany)?.id
  const roles = useSelector(selectCompanyRoles)
  const user = useSelector((state) => selectCompanyUserById(state, id)) || {}
  const userRole = roles.find(role => role.id === user.companyRoleId) || {}
  const userId = user.id
  const bundle = useSelector((state) => selectCompanyUserBundle(state, user.bundleId))
  const bundleConfig = bundle?.recentVersion?.config || null
  const fourDayEnabled = bundle?.recentVersion?.["four_day_enabled"] || false
  const parsed = bundleConfig || []
  const parsedValues = parsed.reduce((obj, item) => (obj[item.id] = item.value, obj), {}) //eslint-disable-line
  const parsedKeys = Object.keys(parsedValues) || []
  const rewards = useSelector(selectAllCompanyRewards) || []
  const filteredRewards = rewards.filter((reward) => parsedKeys.includes(reward.id))

  const mappedRewards = generateRewardMapping(filteredRewards, parsedValues, user.baseSalary, true)
  const chartData = bundle?.recentVersion && user?.hasActiveBundle ? bundle?.recentVersion?.config.map((item) => {
    return {
      id: item.name,
      value: item.computedValue,
    }
  }) : null

  useEffect(() => {
    dispatch(getCompanyUser(companyId, id))
    dispatch(getCompanyRewards(companyId))
    dispatch(getCompanyRoles(companyId))
  }, [dispatch, companyId, userId, id])

  if (user.status === 'archived') {
    employeeStatus = 'Archived'
  } else if (!user.email) {
    employeeStatus = 'Needs Email'
  } else {
    const bundleSubmittedStatus = bundle?.needsVerification ? "Needs Verification" : "Verified"
    employeeStatus = user.hasActiveBundle ? bundleSubmittedStatus : "Needs Submission"
  }

  const toggleHideDialog = () => {
    setVerifyDialogOpen(false)
  }

  const toggleShowDialog = () => {
    setVerifyDialogOpen(true)
  }

  const handleVerify = () => {
    setVerifyDialogOpen(false)
    dispatch(verifyBundle(bundle.id))
  }

  const handleArchive = () => {
    dispatch(archiveEmployee(id, companyId))
  }

  const handleUnarchive = () => {
    dispatch(unarchiveEmployee(id, companyId))
  }

  const dialogContentProps = {
    title: 'Verify Bundl Submission',
    closeButtonAriaLabel: 'Go Back',
    subText: 'You are about to verify this Bundl, which cannot be undone.  Do you want to continue?',
  }

  const baseValue = find(chartData, { id: "Base Salary"})?.value
  const totalPointValue = chartData?.map(items => items.value).reduce((accumulator, current) => accumulator + current, 0)
  const displayedTotalPointValue = isNumber(baseValue) ? totalPointValue - baseValue : totalPointValue
  const displayedBaseSalary = isNumber(baseValue) ? baseValue : user?.baseSalary
  return (
    <>
      <div>
        <BackButton />
        { user && userRole.id && <div className="employeeCard">
                                        <div className="employeeActions">
                                          <ArchiveButton
                                            status={user.status}
                                            onArchive={handleArchive}
                                            onUnarchive={handleUnarchive}
                                          />
                                          <EditEmployee
                                            id={id}
                                            firstName={user.firstName}
                                            lastName={user.lastName}
                                            email={user.email}
                                            roleId={userRole.id}
                                            baseSalary={user.baseSalary}
                                          />
                                        </div>
                                        <div><strong>Status:</strong> {employeeStatus}</div><br/>
                                        <div>
                                          <span className="cardLabel">Email:</span> {!user.email ? (
                                            <div className="emailWarning">
                                              <Icon iconName="alertSolid" /> <span>This employee needs an email address</span>
                                            </div>
                                          ): (<span>{user.email}</span>)}
                                        </div>
                                        <div>
                                          <span className="cardLabel">Name:</span> {user.firstName} {user.lastName}
                                        </div>
                                        <div>
                                          <span className="cardLabel">Base Salary:</span> ${user.baseSalary?.toLocaleString('en-us')}
                                        </div>
                                        <div>
                                        <span className="cardLabel">Role:</span> {userRole.name}
                                        </div>
                                        <div>
                                        <span className="cardLabel">Department:</span> {userRole.department}
                                        </div>
                                      </div>
        }
        { employeeStatus === "Needs Verification" ? <PrimaryButton onClick={toggleShowDialog}>
                                              Verify Bundl
                                            </PrimaryButton>
                                            : null
        }
        <div className="employeeBundle">
          { user.baseSalary && chartData && mappedRewards ?
            <>
              <h2>Employee Bundl</h2>
              <div className="totalCompensation">
                  ${displayedBaseSalary?.toLocaleString('en-us')} + ${displayedTotalPointValue?.toLocaleString('en-us')} = ${(displayedBaseSalary + displayedTotalPointValue).toLocaleString('en-us')}
                  {' '}TOTAL COMPENSATION
              </div>
              {
                fourDayEnabled && (
                  <div className="fourDayEnabled">
                    Employee opted for 4 day work week
                  </div>
                )
              }
              <BundleSelections
                chartData={chartData}
                sliderData={mappedRewards}
                readOnly
              />
            </> : null
          }
        </div>
      </div>
      <Dialog
        hidden={!verifyDialogOpen}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={handleVerify} text="Verify" />
          <DefaultButton onClick={toggleHideDialog} text="Go Back" />
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default Employee
