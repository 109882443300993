import './Pending.scss'

const Pending = () => {
  return (
    <div className="companyPending">
      <h3>
        Thank you for completing the introductory setup!
      </h3>
      You will be contacted shortly by a Bundl representative to complete the onboarding process.<br/><br/>
      Have any questions? Email us at <strong>support@bundl.co</strong>
    </div>
  )
}

export default Pending
