import { CHARITABLE_GIVING, COMMUTER_BENEFITS, COMPANY_EQUITY, EDUCATION_SAVINGS, HEALTHCARE, MATCH_401K, MONETARY_STIPEND, PAID_TIME_OFF, PEER_TO_PEER_REC, PROFESSIONAL_DEVELOPMENT, WFH_STIPEND } from "./rewards"

export const WELCOME = 'WELCOME'
export const SET_SALARY = 'SET_SALARY'
export const SELECT_REWARDS = 'SELECT_REWARDS'
export const PREVIEW_BUNDLE = 'PREVIEW_BUNDLE'

export const REWARDS = [
  {
    id: HEALTHCARE,
    label: 'Healthcare',
    description: 'This is how much your employer contributes to your individual or family healthcare (medical, dental, vision, life) costs. We are partnered with ABC Healthcare for our company healthcare needs.',
    required: true,
    valueType: 'dollar',
    salaryRatio: 1,
    stepSize: 100,
    employmentConfig: {"full_time":{"enabled":true,"customize":false,"min":0,"max":5500,"default":5500}},
    valueMultiplier: null,
    notes: 'For legal purposes, this is a locked benefit and cannot be modified. Please make all healthcare selections through ABC Healthcare. If terminated or you voluntarily leave the company, this benefit will be available for 30 days after leave.',
    vendorName: 'ABC Healthcare',
    vendorUrl: 'https://abchealthcare.com'
  },
  {
    id: MATCH_401K,
    label: '401K Matching',
    description: 'This reward allows your employer to contribute funds to your 401K retirement account. We are partnered with Honest Retirement for our company 401k Vendor. We have set up an employer non-elective contribution of 3%, regardless of employee contributions into the plan.',
    required: true,
    valueType: 'percent',
    salaryRatio: 1,
    stepSize: 0.25,
    employmentConfig: {"full_time":{"enabled":true,"customize":true,"min":0,"max":3,"default":1.5}},
    valueMultiplier: null,
    notes: 'You may adjust your 401K matching amount up to a maximum of 3%.  Changes will are effective at the beginning of each calendar year.',
    vendorName: 'Honest Retirement',
    vendorUrl: 'https://honestretirement.com'
  },
  {
    id: PAID_TIME_OFF,
    label: 'PTO',
    description: 'Paid Time Off is an accrued benefit used to take time off work.',
    required: false,
    valueType: 'day',
    salaryRatio: 1,
    stepSize: 1,
    employmentConfig: {"full_time":{"enabled":true,"customize":true,"min":5,"max":25,"default":5}},
    valueMultiplier: null,
    notes: 'Your selection will determine how many hours of PTO you accrue every pay period. It is still up to the employee to request and use their accrued time off. If terminated or you voluntarily leave the company, this benefit will cease contributions. Your remaining balance will be paid out once you leave the company and is still taxable.',
    vendorName: 'ADV Solutions',
    vendorUrl: 'https://advsolutions.com'
  },
  {
    id: COMPANY_EQUITY,
    label: 'Company Equity',
    description: 'We have partnered with EquityBuddy as our corporate equity provider. If chosen you can receive company options that can be purchased at a discount of $1.50/share.',
    required: false,
    valueType: 'share',
    salaryRatio: 1,
    stepSize: 100,
    employmentConfig: {"full_time":{"enabled":true,"customize":true,"min":0,"max":7500,"default":0}},
    valueMultiplier: 1.5,
    notes: 'The options chosen will vest after 1 year. You then have the option to purchase your options at the discount listed above. If terminated or you voluntarily leave the company prior to vesting, your options will not be available. If your departure is after your options have vested, you can access and purchase your shares with EquityBuddy. Any earnings made through your options are taxable.',
    vendorName: 'EquityBuddy',
    vendorUrl: 'https://equitybuddy.com'
  },
  {
    id: COMMUTER_BENEFITS,
    label: 'Commuter Benefits',
    description: 'Take advantage of our $100/month commuter budget to be used for transportation or parking.',
    required: false,
    valueType: 'dollar',
    salaryRatio: 1,
    stepSize: 25,
    employmentConfig: {"full_time":{"enabled":true,"customize":true,"min":0,"max":100,"default":0}},
    valueMultiplier: 1.5,
    notes: 'This only includes parking for our company garage location.  Ask about our new parking pass options too!',
    vendorName: null,
    vendorUrl: null,
  },
  {
    id: WFH_STIPEND,
    label: 'WFH Stipend',
    description: 'If you have the ability to work from home, you can get a monthly stipend to enhance your work at home experience. This can be used for whatever you please: food, office supplies, equipment, etc.',
    required: false,
    valueType: 'dollar',
    salaryRatio: 1,
    stepSize: 50,
    employmentConfig: {"full_time":{"enabled":true,"customize":true,"min":0,"max":2500,"default":0}},
    valueMultiplier: null,
    notes: 'The monthly payment will be 1/12th of your Bundl selection. So for example, if you select $1,200 in WFH Stipend, you will receive $100 every month ($1,200/12= $100). If terminated or you voluntarily leave the company prior to vesting, your WFH Stipend will be stopped. Your WFH stipend will be added to your paycheck on a monthly basis. Your WFH stipend is taxable.',
    vendorName: null,
    vendorUrl: null
  },
  {
    id: MONETARY_STIPEND,
    label: 'Monetary Stipend',
    description: 'This is a monetary stipend that can be utilized for extra monetary assistance. This can be used for whatever you please: food, movies, sports, travel, bills, etc.',
    required: false,
    valueType: 'dollar',
    salaryRatio: 1,
    stepSize: 25,
    employmentConfig: {"full_time":{"enabled":true,"customize":true,"min":0,"max":2500,"default":0}},
    valueMultiplier: null,
    notes: 'The monthly payment will be 1/12th of your Bundl selection. So for example, if you select $1,200 in Monetary Stipend, you will receive $100 every month ($1,200/12= $100). If terminated or you voluntarily leave the company prior to vesting, your Monetary Stipend will be stopped. Your Monetary stipend will be added to your paycheck on a monthly basis. Your Monetary stipend is taxable.',
    vendorName: null,
    vendorUrl: null
  },
  {
    id: CHARITABLE_GIVING,
    label: 'Charitable Giving',
    description: 'These are funds that can be donated to the charity of your choice, based on the available selections from our vendor.',
    required: false,
    valueType: 'dollar',
    salaryRatio: 1,
    stepSize: 25,
    employmentConfig: {"full_time":{"enabled":true,"customize":true,"min":0,"max":1500,"default":0}},
    valueMultiplier: null,
    notes: 'We will make your charitable donation to your selected charity after 1 year. If terminated or you voluntarily leave the company prior to vesting, your charitable donation will not be made. Your charitable donation will be made by your employer on your behalf. Your charitable donation is not taxable.',
    vendorName: 'CharityBond',
    vendorUrl: 'https://charitybond.com'
  },
  {
    id: PEER_TO_PEER_REC,
    label: 'Peer-to-peer Recognition',
    description: 'These funds may be used for recognizing your peers! We allow up to $100/month for this stipend.',
    required: false,
    valueType: 'dollar',
    salaryRatio: 1,
    stepSize: 25,
    employmentConfig: {"full_time":{"enabled":true,"customize":true,"min":0,"max":100,"default":0}},
    valueMultiplier: 0.5,
    notes: 'Support you colleageus! These stipends only reduce your points by half instead of the full amount.  It is free money!',
    vendorName: null,
    vendorUrl: null
  },
  {
    id: PROFESSIONAL_DEVELOPMENT,
    label: 'Professional Development',
    description: 'Professional Development funds can be used to take classes, courses, and trainings to assist with your personal or professional development. Examples include: online certifications, masterclasses, executive coaching, and more.',
    required: false,
    valueType: 'dollar',
    salaryRatio: 1,
    stepSize: 10,
    employmentConfig: {"full_time":{"enabled":true,"customize":true,"min":0,"max":1500,"default":0}},
    valueMultiplier: null,
    notes: 'If terminated or you voluntarily leave the company prior to vesting, your unused Professional Development costs will be forfeited. Your Professional Development funds are paid for by the employer and are not taxable.',
    vendorName: null,
    vendorUrl: null
  },
  {
    id: EDUCATION_SAVINGS,
    label: 'Education Savings',
    description: 'These are tax-free funds that will be contributed to a state 529 for you or significant others. These can be used for collegiate educational costs, tuition, room and board, books, and more. Any unused costs can be refunded or added to another loved one.',
    required: false,
    valueType: 'dollar',
    salaryRatio: 1,
    stepSize: 100,
    employmentConfig: {"full_time":{"enabled":true,"customize":true,"min":0,"max":5000,"default":0}},
    valueMultiplier: null,
    notes: 'We will make your charitable donation to your selected charity after 1 year. If terminated or you voluntarily leave the company prior to vesting, your charitable donation will not be made. Your charitable donation will be made by your employer on your behalf. Your charitable donation is not taxable.',
    vendorName: 'InvestInCollege',
    vendorUrl: 'https://investInCollege.com'
  }
]

export const REWARD_DEFAULTS = {
  HEALTHCARE: { value: 2500, visible: true },
  MATCH_401K: { value: 1.5, visible: true },
  PAID_TIME_OFF: { value: 10, visible: true },
  COMPANY_EQUITY: { value: 2800, visible: true },
  WFH_STIPEND: { value: 700, visible: true },
  MONETARY_STIPEND: { value: 700, visible: true },
  CHARITABLE_GIVING: { value: 550, visible: true },
  PROFESSIONAL_DEVELOPMENT: { value: 310, visible: true },
  EDUCATION_SAVINGS: { value: 0, visible: true },
  COMMUTER_BENEFITS: { value: 0, visible: true },
  PEER_TO_PEER_REC: { value: 0, visible: true },
}
