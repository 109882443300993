import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import classnames from 'classnames'

import { signOut } from 'actions/user'
import { selectUserIsAdmin, selectUserIsLoading } from "selectors/user"
import { selectCompany, selectCompanyIsOnboarding } from "selectors/company"

import { DefaultButton, Spinner, SpinnerSize, Nav } from "@fluentui/react"
import Notifications from "./Notifications"

import logo from 'assets/logo.png'
import './AppLayout.scss'


const adminLinks = [
  {
    links: [
      {
        name: 'Dashboard',
        key: 'dashboard',
        url: '/dashboard',
      },
      {
        name: 'Employees',
        key: 'employees',
        url: '/employees',
      },
      {
        name: 'Roles',
        key: 'roles',
        url: '/roles',
      },
      {
        name: 'Offers',
        key: 'offers',
        url: '/offers',
      },
      {
        name: 'Rewards',
        key: 'rewards',
        url: '/rewards',
      },
    ]
  }
]

const trialLinks = [
  {
    links: [
      {
        name: 'Invite Your Team',
        key: 'invite',
        url: '/',
      },
      {
        name: 'Rewards',
        key: 'rewards',
        url: '/rewards',
      },
      {
        name: 'Upgrade to Standard',
        key: 'upgrade',
        url: '/upgrade',
      },
    ]
  }
]

const AdminNav = () => {
  const history = useHistory()
  const company = useSelector(selectCompany)
  const navLinks = company?.status === 'active' ? adminLinks : trialLinks
  const handleNavClick = (ev, element) => {
    ev.preventDefault()
    history.push(element.url)
  }

  return (
    <div className="navBar">
      <Nav groups={navLinks} onLinkClick={handleNavClick} />
      <div className="navBarFooter">
        <div>
          Questions?<br/>
          <a href="mailto:support@bundl.co">support@bundl.co</a>
        </div>
        <div className="subtext">
          Terms &bull; Privacy
        </div>
        <div className="subtext">
          Bundl LLC
        </div>
      </div>
    </div>
  )
}

const AppLayout = ({ children }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const loading = useSelector(selectUserIsLoading)
  const isAdmin = useSelector(selectUserIsAdmin)
  const isOnboarding = useSelector(selectCompanyIsOnboarding)

  const handleSignOut = () => {
    dispatch(signOut())
    history.push('/login')
  }

  const showNav = isAdmin && !isOnboarding

  return (
    <>
      <header className="header">
        { isAdmin ? (
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        ): (
          <a href="https://bundl.co">
            <img src={logo} alt="logo" />
          </a>
        )}
        { isAdmin ? <DefaultButton className="signOutButton" onClick={handleSignOut}>
                      Sign Out
                    </DefaultButton> : null
        }
      </header>
      { showNav ? <AdminNav /> : null }
      <div className={classnames("content", { navPresent: showNav })}>
        { loading ? <Spinner size={SpinnerSize.large} /> : children }
      </div>
      <Notifications />
    </>
  )
}

export default AppLayout
