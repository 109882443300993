
import { useSelector } from "react-redux"


import { selectCompanyIsOnboarding, selectCompanyIsTrial } from 'selectors/company'

import InviteTeam from './admin/InviteTeam'
import AdminDashboard from './dashboards/AdminDashboard'
import OnboardingDashboard from "./dashboards/OnboardingDashboard"

const Dashboard = () => {
  const isTrial = useSelector(selectCompanyIsTrial)
  const isOnboarding = useSelector(selectCompanyIsOnboarding)

  if (isOnboarding) {
    return <OnboardingDashboard />
  } else if (isTrial) {
    return <InviteTeam />
  } else {
    return <AdminDashboard />
  }
}

export default Dashboard
