export const USER_SIGN_OUT = 'USER_SIGN_OUT'

export const AUTHENTICATE_USER_REQUESTED = 'AUTHENTICATE_USER_REQUESTED'
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS'
export const AUTHENTICATE_USER_FAILURE = 'AUTHENTICATE_USER_FAILURE'

export const SIGN_UP_REQUESTED = 'SIGN_UP_REQUESTED'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE'

export const SET_PASSWORD_REQUESTED = 'SET_PASSWORD_REQUESTED'
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS'
export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE'

export const FETCH_USER_REQUESTED = 'FETCH_USER_REQUESTED'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'

export const CREATE_OFFER_LETTER_REQUESTED = 'CREATE_OFFER_LETTER_REQUESTED'
export const CREATE_OFFER_LETTER_SUCCESS = 'CREATE_OFFER_LETTER_SUCCESS'
export const CREATE_OFFER_LETTER_FAILURE = 'CREATE_OFFER_LETTER_FAILURE'

export const UPDATE_OFFER_LETTER_REQUESTED = 'UPDATE_OFFER_LETTER_REQUESTED'
export const UPDATE_OFFER_LETTER_SUCCESS = 'UPDATE_OFFER_LETTER_SUCCESS'
export const UPDATE_OFFER_LETTER_FAILURE = 'UPDATE_OFFER_LETTER_FAILURE'

export const FETCH_OFFER_LETTERS_REQUESTED = 'FETCH_OFFER_LETTERS_REQUESTED'
export const FETCH_OFFER_LETTERS_SUCCESS = 'FETCH_OFFER_LETTERS_SUCCESS'
export const FETCH_OFFER_LETTERS_FAILURE = 'FETCH_OFFER_LETTERS_FAILURE'

export const FETCH_OFFER_LETTER_REQUESTED = 'FETCH_OFFER_LETTER_REQUESTED'
export const FETCH_OFFER_LETTER_SUCCESS = 'FETCH_OFFER_LETTER_SUCCESS'
export const FETCH_OFFER_LETTER_FAILURE = 'FETCH_OFFER_LETTER_FAILURE'

export const FETCH_OFFER_LETTER_APPROVERS_REQUESTED = 'FETCH_OFFER_LETTER_APPROVERS_REQUESTED'
export const FETCH_OFFER_LETTER_APPROVERS_SUCCESS = 'FETCH_OFFER_LETTER_APPROVERS_SUCCESS'
export const FETCH_OFFER_LETTER_APPROVERS_FAILURE = 'FETCH_OFFER_LETTER_APPROVERS_FAILURE'

export const CREATE_DEFAULT_OFFER_APPROVER_REQUESTED = 'CREATE_DEFAULT_OFFER_APPROVER_REQUESTED'
export const CREATE_DEFAULT_OFFER_APPROVER_SUCCESS = 'CREATE_DEFAULT_OFFER_APPROVER_SUCCESS'
export const CREATE_DEFAULT_OFFER_APPROVER_FAILURE = 'CREATE_DEFAULT_OFFER_APPROVER_FAILURE'

export const DELETE_DEFAULT_OFFER_APPROVER_REQUESTED = 'DELETE_DEFAULT_OFFER_APPROVER_REQUESTED'
export const DELETE_DEFAULT_OFFER_APPROVER_SUCCESS = 'DELETE_DEFAULT_OFFER_APPROVER_SUCCESS'
export const DELETE_DEFAULT_OFFER_APPROVER_FAILURE = 'DELETE_DEFAULT_OFFER_APPROVER_FAILURE'

export const CREATE_OFFER_APPROVER_REQUESTED = 'CREATE_OFFER_APPROVER_REQUESTED'
export const CREATE_OFFER_APPROVER_SUCCESS = 'CREATE_OFFER_APPROVER_SUCCESS'
export const CREATE_OFFER_APPROVER_FAILURE = 'CREATE_OFFER_APPROVER_FAILURE'

export const DELETE_OFFER_APPROVER_REQUESTED = 'DELETE_OFFER_APPROVER_REQUESTED'
export const DELETE_OFFER_APPROVER_SUCCESS = 'DELETE_OFFER_APPROVER_SUCCESS'
export const DELETE_OFFER_APPROVER_FAILURE = 'DELETE_OFFER_APPROVER_FAILURE'

export const SEND_OFFER_APPROVERS_REQUESTED = 'SEND_OFFER_APPROVERS_REQUESTED'
export const SEND_OFFER_APPROVERS_SUCCESS = 'SEND_OFFER_APPROVERS_SUCCESS'
export const SEND_OFFER_APPROVERS_FAILURE = 'SEND_OFFER_APPROVERS_FAILURE'

export const APPROVE_OFFER_REQUESTED = 'APPROVE_OFFER_REQUESTED'
export const APPROVE_OFFER_SUCCESS = 'APPROVE_OFFER_SUCCESS'
export const APPROVE_OFFER_FAILURE = 'APPROVE_OFFER_FAILURE'

export const REJECT_OFFER_REQUESTED = 'REJECT_OFFER_REQUESTED'
export const REJECT_OFFER_SUCCESS = 'REJECT_OFFER_SUCCESS'
export const REJECT_OFFER_FAILURE = 'REJECT_OFFER_FAILURE'

export const ARCHIVE_OFFER_REQUESTED = 'ARCHIVE_OFFER_REQUESTED'
export const ARCHIVE_OFFER_SUCCESS = 'ARCHIVE_OFFER_SUCCESS'
export const ARCHIVE_OFFER_FAILURE = 'ARCHIVE_OFFER_FAILURE'

export const UNARCHIVE_OFFER_REQUESTED = 'UNARCHIVE_OFFER_REQUESTED'
export const UNARCHIVE_OFFER_SUCCESS = 'UNARCHIVE_OFFER_SUCCESS'
export const UNARCHIVE_OFFER_FAILURE = 'UNARCHIVE_OFFER_FAILURE'

export const FETCH_COMPANY_REWARDS_REQUESTED = 'FETCH_COMPANY_REWARDS_REQUESTED'
export const FETCH_COMPANY_REWARDS_SUCCESS = 'FETCH_COMPANY_REWARDS_SUCCESS'
export const FETCH_COMPANY_REWARDS_FAILURE = 'FETCH_COMPANY_REWARDS_FAILURE'

export const FETCH_COMPANY_USERS_REQUESTED = 'FETCH_COMPANY_USERS_REQUESTED'
export const FETCH_COMPANY_USERS_SUCCESS = 'FETCH_COMPANY_USERS_SUCCESS'
export const FETCH_COMPANY_USERS_FAILURE = 'FETCH_COMPANY_USERS_FAILURE'

export const FETCH_COMPANY_USER_REQUESTED = 'FETCH_COMPANY_USER_REQUESTED'
export const FETCH_COMPANY_USER_SUCCESS = 'FETCH_COMPANY_USER_SUCCESS'
export const FETCH_COMPANY_USER_FAILURE = 'FETCH_COMPANY_USER_FAILURE'

export const FETCH_COMPANY_ROLES_REQUESTED = 'FETCH_COMPANY_ROLES_REQUESTED'
export const FETCH_COMPANY_ROLES_SUCCESS = 'FETCH_COMPANY_ROLES_SUCCESS'
export const FETCH_COMPANY_ROLES_FAILURE = 'FETCH_COMPANY_ROLES_FAILURE'

export const SEND_USER_BUNDLE_REQUESTED = 'SEND_USER_BUNDLE_REQUESTED'
export const SEND_USER_BUNDLE_SUCCESS = 'SEND_USER_BUNDLE_SUCCESS'
export const SEND_USER_BUNDLE_FAILURE = 'SEND_USER_BUNDLE_FAILURE'

export const CREATE_COMPANY_REQUESTED = 'CREATE_COMPANY_REQUESTED'
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS'
export const CREATE_COMPANY_FAILURE = 'CREATE_COMPANY_FAILURE'

export const SELECT_REWARDS = 'SELECT_REWARDS'

export const SET_REWARDS_REQUESTED = 'SET_REWARDS_REQUESTED'
export const SET_REWARDS_SUCCESS = 'SET_REWARDS_SUCCESS'
export const SET_REWARDS_FAILURE = 'SET_REWARDS_FAILURE'

export const DELETE_REWARD_REQUESTED = 'DELETE_REWARD_REQUESTED'
export const DELETE_REWARD_SUCCESS = 'DELETE_REWARD_SUCCESS'
export const DELETE_REWARD_FAILURE = 'DELETE_REWARD_FAILURE'

export const SET_DEFAULT_BUNDLE_REQUESTED = 'SET_DEFAULT_BUNDLE_REQUESTED'
export const SET_DEFAULT_BUNDLE_SUCCESS = 'SET_DEFAULT_BUNDLE_SUCCESS'
export const SET_DEFAULT_BUNDLE_FAILURE = 'SET_DEFAULT_BUNDLE_FAILURE'

export const CREATE_BUNDLE_REQUESTED = 'CREATE_BUNDLE_REQUESTED'
export const CREATE_BUNDLE_SUCCESS = 'CREATE_BUNDLE_SUCCESS'
export const CREATE_BUNDLE_FAILURE = 'CREATE_BUNDLE_FAILURE'

export const VERIFY_BUNDLE_REQUESTED = 'VERIFY_BUNDLE_REQUESTED'
export const VERIFY_BUNDLE_SUCCESS = 'VERIFY_BUNDLE_SUCCESS'
export const VERIFY_BUNDLE_FAILURE = 'VERIFY_BUNDLE_FAILURE'

export const UPLOAD_EMPLOYEES_REQUESTED = 'UPLOAD_EMPLOYEES_REQUESTED'
export const UPLOAD_EMPLOYEES_SUCCESS = 'UPLOAD_EMPLOYEES_SUCCESS'
export const UPLOAD_EMPLOYEES_FAILURE = 'UPLOAD_EMPLOYEES_FAILURE'

export const EXPORT_EMPLOYEES_REQUESTED = 'EXPORT_EMPLOYEES_REQUESTED'
export const EXPORT_EMPLOYEES_SUCCESS = 'EXPORT_EMPLOYEES_SUCCESS'
export const EXPORT_EMPLOYEES_FAILURE = 'EXPORT_EMPLOYEES_FAILURE'

export const ADD_ROLE_REQUESTED = 'ADD_ROLE_REQUESTED'
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS'
export const ADD_ROLE_FAILURE = 'ADD_ROLE_FAILURE'

export const EDIT_ROLE_REQUESTED = 'EDIT_ROLE_REQUESTED'
export const EDIT_ROLE_SUCCESS = 'EDIT_ROLE_SUCCESS'
export const EDIT_ROLE_FAILURE = 'EDIT_ROLE_FAILURE'

export const ADD_EMPLOYEE_REQUESTED = 'ADD_EMPLOYEE_REQUESTED'
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS'
export const ADD_EMPLOYEE_FAILURE = 'ADD_EMPLOYEE_FAILURE'

export const EDIT_EMPLOYEE_REQUESTED = 'EDIT_EMPLOYEE_REQUESTED'
export const EDIT_EMPLOYEE_SUCCESS = 'EDIT_EMPLOYEE_SUCCESS'
export const EDIT_EMPLOYEE_FAILURE = 'EDIT_EMPLOYEE_FAILURE'

export const FETCH_ANALYTICS_REQUESTED = 'FETCH_ANALYTICS_REQUESTED'
export const FETCH_ANALYTICS_SUCCESS = 'FETCH_ANALYTICS_SUCCESS'
export const FETCH_ANALYTICS_FAILURE = 'FETCH_ANALYTICS_FAILURE'

export const BULK_SEND_BUNDLE_REQUESTED = 'BULK_SEND_BUNDLE_REQUESTED'
export const BULK_SEND_BUNDLE_SUCCESS = 'BULK_SEND_BUNDLE_SUCCESS'
export const BULK_SEND_BUNDLE_FAILURE = 'BULK_SEND_BUNDLE_FAILURE'

export const SUBMIT_FEEDBACK_REQUESTED = 'SUBMIT_FEEDBACK_REQUESTED'
export const SUBMIT_FEEDBACK_SUCCESS = 'SUBMIT_FEEDBACK_SUCCESS'
export const SUBMIT_FEEDBACK_FAILURE = 'SUBMIT_FEEDBACK_FAILURE'

export const FETCH_FEEDBACK_SUMMARY_REQUESTED = 'FETCH_FEEDBACK_SUMMARY_REQUESTED'
export const FETCH_FEEDBACK_SUMMARY_SUCCESS = 'FETCH_FEEDBACK_SUMMARY_SUCCESS'
export const FETCH_FEEDBACK_SUMMARY_FAILURE = 'FETCH_FEEDBACK_SUMMARY_FAILURE'

export const REQUEST_DEMO_REQUESTED = 'REQUEST_DEMO_REQUESTED'
export const REQUEST_DEMO_SUCCESS = 'REQUEST_DEMO_SUCCESS'
export const REQUEST_DEMO_FAILURE = 'REQUEST_DEMO_FAILURE'

export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'

export const OFFER_LETTER_LINK_COPIED = 'OFFER_LETTER_LINK_COPIED'

export const SET_PREVIEW_STATUS = 'SET_PREVIEW_STATUS'
export const SELECT_PREVIEW_REWARDS = 'SELECT_PREVIEW_REWARDS'
export const SET_PREVIEW_SALARY = 'SET_PREVIEW_SALARY'

export const PREVIEW_CREATED_REQUESTED = 'PREVIEW_CREATED_REQUESTED'
export const PREVIEW_CREATED_SUCCESS = 'PREVIEW_CREATED_SUCCESS'
export const PREVIEW_CREATED_FAILURE = 'PREVIEW_CREATED_FAILURE'

export const FETCH_PREVIEW_REQUESTED = 'FETCH_PREVIEW_REQUESTED'
export const FETCH_PREVIEW_SUCCESS = 'FETCH_PREVIEW_SUCCESS'
export const FETCH_PREVIEW_FAILURE = 'FETCH_PREVIEW_FAILURE'
