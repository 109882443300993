import { useState } from 'react'

import { Checkbox, DefaultButton, Dialog, DialogFooter, Dropdown, Icon, IconButton, PrimaryButton, SpinButton, TextField, TooltipHost } from '@fluentui/react'

import './EditReward.scss'

const VALUE_TYPE_OPTIONS = [
  { key: 'dollar', text: 'Dollar' },
  { key: 'percent', text: 'Percent'},
  { key: 'day', text: 'Day' },
  { key: 'share', text: 'Shares' },
]

const EditReward = ({
  rewardId,
  label,
  description,
  valueType,
  // minValue,
  maxValue,
  discountPercent,
  defaultValue,
  valueMultiplier,
  customize,
  onUpdateDescription,
  onToggleModify,
  onUpdateValueType,
  onUpdateValueMultiplier,
  onUpdateDiscount,
  // onUpdateMin,
  onUpdateMax,
  onUpdateDefault,
  onUpdateName,
  onDelete,
}) => {
  const [expanded, setExpanded] = useState(false)
  const [toggleEdit, setToggleEdit] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const handleToggle = () => {
    setExpanded(!expanded)
  }

  const chevronIcon = expanded ? "ChevronUp" : "ChevronDown"

  const handleModifyToggle = (e, isChecked) => {
    onToggleModify(isChecked)
  }

  const handleUpdateDescription = (e, newValue) => {
    onUpdateDescription(newValue)
  }

  const handleUpdateValueMultiplier = (e, newValue) => {
    onUpdateValueMultiplier(parseFloat(newValue))
  }

  const handleValidateValueMultiplier = (value, _ev) => {
    return Number(value.replace(/[^0-9.]+/g,""))
  }

  const handleIncrementValueMultiplier = () => {
    return valueMultiplier + 0.01
  }

  const handleDecrementValueMultiplier = () => {
    return Math.max(0, valueMultiplier - 0.01)
  }

  const handleUpdateDiscount = (e, newValue) => {
    onUpdateDiscount(parseFloat((newValue/100).toFixed(2)))
  }

  const handleIncrementDiscount = (newValue) => {
    if (parseInt(newValue) + 1 <= 100) {
      return parseInt(newValue) + 1
    }
  }

  const handleDecrementDiscount = (newValue) => {
    return Math.max(0, parseInt(newValue) - 1)
  }

  // const handleUpdateMin = (e, newValue) => {
  //   onUpdateMin(newValue)
  // }
  const handleUpdateMax = (e, newValue) => {
    onUpdateMax(newValue)
  }
  const handleUpdateDefault = (e, newValue) => {
    onUpdateDefault(newValue)
  }

  const handleUpdateValueType = (e, newValue) => {
    onUpdateValueType(newValue.key)
  }

  const handleUpdateName = (e, newValue) => {
    onUpdateName(newValue)
  }

  const handleToggleEdit = (e) => {
    e.stopPropagation()
    setToggleEdit(!toggleEdit)
  }

  const handleClickText = (e) => {
    e.stopPropagation()
  }

  const handleDelete = (e) => {
    e.stopPropagation()
    setDeleteModalOpen(true)
  }

  const toggleHideDeleteModal = () => {
    setDeleteModalOpen(false)
  }

  const handleConfirmDelete = () => {
    onDelete()
    setDeleteModalOpen(false)
  }

  const modifyTooltipCopy = (
    <div className="modifyTooltipCopy">
      If you would like this reward amount to be customizable by your employees, please check the box and fill in the appropriate maximum available value.
    </div>
  )

  const dialogContentProps = {
    title: 'Confirm Deletion',
    closeButtonAriaLabel: 'Go Back',
    subText: 'You are about to delete this reward, which cannot be undone.  Do you want to continue?',
  }

  return (
    <>
      <div className="editReward" onClick={handleToggle}>
        <div className="rewardActions">
          <IconButton
            className="deleteButton"
            iconProps={{ iconName: "Delete" }}
            title="Delete"
            ariaLabel="Delete"
            onClick={handleDelete}
          />
          <IconButton
            className="expandButton"
            iconProps={{ iconName: chevronIcon }}
            title="Toggle Expand"
            ariaLabel="Expand"
            onClick={handleToggle}
          />
        </div>
        <div className="rewardLabel">
          {toggleEdit ? (
            <TextField
              className="rewardEditText"
              value={label}
              onClick={handleClickText}
              onChange={handleUpdateName}
              onBlur={handleToggleEdit}
            />
          ) : (
            <span>{label}</span>
          )}
          <Icon className="rewardEdit" iconName="Edit" onClick={handleToggleEdit} />
        </div>

        { expanded && (
          <div onClick={(e) => e.stopPropagation()}>
            <TextField
              label="Description"
              multiline rows={3}
              onChange={handleUpdateDescription}
              value={description}
            />
            <div className="rewardConfig">
              <div className="rewardCheckboxes">
                <Checkbox
                  className="rewardCheckbox"
                  onRenderLabel={() => {
                    return (
                      <TooltipHost
                        content={modifyTooltipCopy}
                      >
                        <div className="rewardModifyLabel">
                          Modifiable reward
                          <Icon
                            id={`reward-${label}`}
                            className="rewardInfoIcon"
                            iconName="InfoSolid"
                          />
                        </div>
                      </TooltipHost>
                    )
                  }}
                  onChange={handleModifyToggle}
                  checked={customize}
                />
              </div>
              <Dropdown
                className="rewardDropdown"
                options={VALUE_TYPE_OPTIONS}
                selectedKey={valueType}
                onChange={handleUpdateValueType}
                label="Value Type"
              />
              {valueType === "share" && (
                <SpinButton
                  className="rewardNumber"
                  label="Share Value"
                  value={`$${Number.parseFloat(valueMultiplier).toFixed(2)}`}
                  min={0}
                  step={.01}
                  onChange={handleUpdateValueMultiplier}
                  onIncrement={handleIncrementValueMultiplier}
                  onDecrement={handleDecrementValueMultiplier}
                  onValidate={handleValidateValueMultiplier}
                  incrementButtonAriaLabel="Increase by $0.01"
                  decrementButtonAriaLabel="Decrease by $0.01"
                />
              )}
              {/* <SpinButton
                className="rewardNumber"
                label="Minimum Value"
                value={minValue}
                min={0}
                step={1}
                onChange={handleUpdateMin}
                incrementButtonAriaLabel="Increase by 1"
                decrementButtonAriaLabel="Decrease by 1"
                disabled={!customize}
              /> */}
              <SpinButton
                className="rewardNumber"
                label="Maximum Value"
                value={maxValue}
                min={0}
                step={valueType === "percent" ? 0.1 : 1}
                onChange={handleUpdateMax}
                incrementButtonAriaLabel="Increase by 1"
                decrementButtonAriaLabel="Decrease by 1"
                disabled={!customize}
              />
              { !customize && <SpinButton
                    className="rewardNumber"
                    label="Default Value"
                    value={defaultValue}
                    min={0}
                    step={valueType === "percent" ? 0.1 : 1}
                    onChange={handleUpdateDefault}
                    incrementButtonAriaLabel="Increase by 1"
                    decrementButtonAriaLabel="Decrease by 1"
                  />
              }
              <SpinButton
                className="rewardNumber"
                label="Discount %"
                value={`${Math.round(discountPercent*100)}`}
                min={0}
                max={100}
                step={1}
                onChange={handleUpdateDiscount}
                onIncrement={handleIncrementDiscount}
                onDecrement={handleDecrementDiscount}
                incrementButtonAriaLabel="Increase by 1%"
                decrementButtonAriaLabel="Decrease by 1%"
              />
            </div>
          </div>
        )}
      </div>
      <Dialog
        hidden={!deleteModalOpen}
        onDismiss={toggleHideDeleteModal}
        dialogContentProps={dialogContentProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={handleConfirmDelete} text="Confirm" />
          <DefaultButton onClick={toggleHideDeleteModal} text="Go Back" />
        </DialogFooter>
      </Dialog>
    </>
  )
}

EditReward.defaultProps = {
  rewardId: null,
  label: '',
  description: '',
  valueType: '',
  required: false,
  minValue: 0,
  maxValue: 1000,
  discountPercent: 0,
  defaultValue: 0,
  customize: false,
  valueMultiplier: 1.0,
  onToggleRequired: () => {},
  onUpdateDescription: () => {},
  onUpdateValueType: () => {},
  onUpdateMin: () => {},
  onUpdateMax: () => {},
  onUpdateDefault: () => {},
  onUpdateName: () => {},
  onDelete: () => {},
  onUpdateDiscount: () => {},
}

export default EditReward
