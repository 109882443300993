export const selectOfferLetters = (state) => {
  return state.offerLetters.offerLetters || []
}

export const selectCurrentOfferLetter = (state) => {
  return state.offerLetters.currentOfferLetter || {}
}

export const selectOfferLetterApprovers = (state) => {
  return state.offerLetters.approvers || []
}

