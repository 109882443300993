import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import startCase from 'lodash/startCase'

import {
  selectUserId,
  selectUserIsError,
} from "selectors/user"
import {
  selectCompany,
  selectCompanyRoles,
} from "selectors/company"

import { getCompanyRewards, getCompanyRoles } from "actions/company"

import { DefaultButton, PrimaryButton, TextField } from "@fluentui/react"
import BundleBuilder from "components/shared/BundleBuilder"
import { selectCurrentOfferLetter } from "selectors/offerLetters"
import { approveOffer, rejectOffer, getOfferLetter } from "actions/offerLetters"

import "./ApproveOfferLetter.scss"

const ApproveOfferLetter = () => {
  const { id: offerId } = useParams()

  const history = useHistory()
  const dispatch = useDispatch()

  const userId = useSelector(selectUserId)
  const currentOffer = useSelector(selectCurrentOfferLetter)
  const { firstName, lastName, baseSalary, approvalSummary } = currentOffer
  const userError = useSelector(selectUserIsError)

  const companyId = useSelector(selectCompany)?.id
  const roles = useSelector(selectCompanyRoles)
  const companyRole = roles.find(role => role.id === currentOffer.companyRoleId)
  const pointPercent = companyRole?.pointPercent || 0

  const currentApproval = useMemo(() => {
    return approvalSummary ? approvalSummary.find((approval) => approval.id === userId) : {}
  }, [approvalSummary, userId])

  const [note, setNote] = useState("")

  useEffect(() => {
    if (offerId) {
      dispatch(getOfferLetter(offerId))
    }

  }, [dispatch, offerId])

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyRewards(companyId))
      dispatch(getCompanyRoles(companyId))
    }
  }, [dispatch, companyId])

  useEffect(() => {
    if (userError) {
      history.push('/link-expired')
    }
  }, [userError, history])

  useEffect(() => {
    setNote(currentApproval?.note)
  }, [currentApproval])
  
  const handleApprove = () => {
    dispatch(approveOffer(offerId, note))
  }

  const handleReject = () => {
    dispatch(rejectOffer(offerId, note))
  }

  const handleUpdateNote = (_event, newValue) => {
    setNote(newValue)
  }

  return (
    <>
      <div className="approveOfferLetter">
        <h1>Approve Offer Letter</h1>
        <div className="employeeCard">
          <div>
            <span className="cardLabel">Name:</span> {firstName} {lastName}
          </div>
          <div>
            <span className="cardLabel">Starting Base Salary:</span> ${baseSalary?.toLocaleString('en-us')}
          </div>
          <div>
            <span className="cardLabel">Role:</span> {companyRole?.name}
          </div>
          <div>
            <span className="cardLabel">Department:</span> {companyRole?.department}
          </div>
        </div>

        { companyRole?.rewardDefaults && (
          <>
            <div className="approvalStatus">
              <p className="approvalText">
              This is the Bundl offer that will be sent to your prospect for custom configuration. Please review and approve the max offerings available for all rewards. If rejecting, please list why in the available text box.
              </p>
              Your current approval status: <strong>{startCase(currentApproval?.status)}</strong>
              <TextField
                className="approvalNote"
                label="Note / Rejection Reason"
                multiline
                rows={3}
                onChange={handleUpdateNote}
                value={note}
              />
              <div className="approvalActions">
                <PrimaryButton onClick={handleApprove}>
                  Approve
                </PrimaryButton>
                <DefaultButton onClick={handleReject}>
                  Reject
                </DefaultButton>
              </div>
            </div>
            <BundleBuilder
              className="candidateBundle"
              showTotalValue
              disabled
              baseSalary={baseSalary}
              minSalary={companyRole?.minSalary}
              maxSalary={companyRole?.maxSalary}
              pointPercent={pointPercent}
              fourDayPercentage={companyRole?.fourDayRatio}
              rewardDefaults={companyRole?.rewardDefaults}
              includeBase
            />
          </>
        )}
      </div>
    </>
  )
}

export default ApproveOfferLetter
