export const MATCH_401K = 'MATCH_401k'
export const HEALTHCARE = 'HEALTHCARE'
export const PAID_TIME_OFF = 'PAID_TIME_OFF'
export const COMPANY_EQUITY = 'COMPANY_EQUITY'
export const COMMUTER_BENEFITS = 'COMMUTER_BENEFITS'
export const WFH_STIPEND = 'WFH_STIPEND'
export const MONETARY_STIPEND = 'MONETARY_STIPEND'
export const CHARITABLE_GIVING = 'CHARITABLE_GIVING'
export const PEER_TO_PEER_REC = 'PEER_TO_PEER_REC'
export const BASE_SALARY = 'BASE_SALARY'
export const PROFESSIONAL_DEVELOPMENT = 'PROFESSIONAL_DEVELOPMENT'
export const EDUCATION_SAVINGS = 'EDUCATION_SAVINGS'
