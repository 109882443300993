import axios from 'axios'

import { getAuthHeader } from 'util/auth'

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  transformRequest: [
    function (data, headers) {
      headers['Authorization'] = getAuthHeader()
      return data
    }
  ],
})

export default client
