import { useState } from "react"
import xor from 'lodash/xor'
import { useDispatch } from "react-redux"

import {
  MATCH_401K,
  HEALTHCARE,
  PAID_TIME_OFF,
  COMPANY_EQUITY,
  COMMUTER_BENEFITS,
  WFH_STIPEND,
  MONETARY_STIPEND,
  CHARITABLE_GIVING,
  PEER_TO_PEER_REC,
  PROFESSIONAL_DEVELOPMENT,
  EDUCATION_SAVINGS,
} from 'constants/rewards'

import { selectRewards } from "actions/company"

import { PrimaryButton, TextField } from "@fluentui/react"
import { ReactComponent as BriefcaseIcon } from "@fluentui/svg-icons/icons/briefcase_24_filled.svg"
import { ReactComponent as DoctorIcon } from "@fluentui/svg-icons/icons/doctor_24_filled.svg"
import { ReactComponent as HistoryIcon } from "@fluentui/svg-icons/icons/history_24_filled.svg"
import { ReactComponent as BuildingIcon } from "@fluentui/svg-icons/icons/building_24_filled.svg"
import { ReactComponent as CarIcon } from "@fluentui/svg-icons/icons/vehicle_car_24_filled.svg"
import { ReactComponent as HomePersonIcon } from "@fluentui/svg-icons/icons/home_person_24_filled.svg"
import { ReactComponent as MoneyIcon } from "@fluentui/svg-icons/icons/money_24_filled.svg"
import { ReactComponent as HeartIcon } from "@fluentui/svg-icons/icons/heart_24_filled.svg"
import { ReactComponent as CertificateIcon } from "@fluentui/svg-icons/icons/certificate_24_filled.svg"
import { ReactComponent as StarIcon } from "@fluentui/svg-icons/icons/star_24_filled.svg"
import { ReactComponent as BookIcon } from "@fluentui/svg-icons/icons/book_24_filled.svg"
import { ReactComponent as GraduateIcon } from "@fluentui/svg-icons/icons/hat_graduation_24_filled.svg"

import RewardTile from "./RewardTile"

import './SelectRewards.scss'

const SelectRewards = ({ onSubmit, includeCustom, includePreviewText }) => {
  const dispatch = useDispatch()

  const [selections, setSelections] = useState([])
  const [customSelections, setCustomSelections] = useState([])
  const [editing, setEditing] = useState(false)
  const [customText, setCustomText] = useState("")

  const toggleSelection = (id) => {
    setSelections(xor(selections, [id]))
  }

  const removeCustomSelection = (id) => {
    setCustomSelections(xor(customSelections, [id]))
  }

  const submitCustom = (event) => {
    event.stopPropagation()
    setEditing(false)
    setCustomSelections(customSelections.concat([customText]))
    setCustomText("")
  }

  const submit = () => {
    dispatch(onSubmit({
      selections,
      customSelections,
    }))
  }

  return (
    <div className="selectRewards">
      <h1>Select Company Rewards</h1>
      { includePreviewText && (
        <p>
          Great, your base salary has been input, now go ahead and select what rewards and benefits you want in your compensation package! For this demonstration, we recommend you at least select 4 rewards.
        </p>
      )}
      <div className="rewards">
        <RewardTile
          id={MATCH_401K}
          label="401K Matching"
          onClick={toggleSelection}
          isActive={selections.includes(MATCH_401K)}
        >
          <BriefcaseIcon width="60" height="60" fill="#062677" />
        </RewardTile>

        <RewardTile
          id={HEALTHCARE}
          label="Healthcare"
          onClick={toggleSelection}
          isActive={selections.includes(HEALTHCARE)}
        >
          <DoctorIcon width="60" height="60" fill="#062677" />
        </RewardTile>

        <RewardTile
          id={PAID_TIME_OFF}
          label="Paid Time Off"
          onClick={toggleSelection}
          isActive={selections.includes(PAID_TIME_OFF)}
        >
          <HistoryIcon width="60" height="60" fill="#062677" />
        </RewardTile>

        <RewardTile
          id={COMPANY_EQUITY}
          label="Company Equity"
          onClick={toggleSelection}
          isActive={selections.includes(COMPANY_EQUITY)}
        >
          <BuildingIcon width="60" height="60" fill="#062677" />
        </RewardTile>

        <RewardTile
          id={COMMUTER_BENEFITS}
          label="Commuter Benefits"
          onClick={toggleSelection}
          isActive={selections.includes(COMMUTER_BENEFITS)}
        >
          <CarIcon width="60" height="60" fill="#062677" />
        </RewardTile>

        <RewardTile
          id={WFH_STIPEND}
          label="WFH Stipend"
          onClick={toggleSelection}
          isActive={selections.includes(WFH_STIPEND)}
        >
          <HomePersonIcon width="60" height="60" fill="#062677" />
        </RewardTile>

        <RewardTile
          id={MONETARY_STIPEND}
          label="Monetary Stipend"
          onClick={toggleSelection}
          isActive={selections.includes(MONETARY_STIPEND)}
        >
          <MoneyIcon width="60" height="60" fill="#062677" />
        </RewardTile>

        <RewardTile
          id={CHARITABLE_GIVING}
          label="Charitable Giving"
          onClick={toggleSelection}
          isActive={selections.includes(CHARITABLE_GIVING)}
        >
          <HeartIcon width="60" height="60" fill="#062677" />
        </RewardTile>

        <RewardTile
          id={PEER_TO_PEER_REC}
          label="Peer-to-peer Recognition"
          onClick={toggleSelection}
          isActive={selections.includes(PEER_TO_PEER_REC)}
        >
          <CertificateIcon width="60" height="60" fill="#062677" />
        </RewardTile>

        <RewardTile
          id={PROFESSIONAL_DEVELOPMENT}
          label="Professional Development"
          onClick={toggleSelection}
          isActive={selections.includes(PROFESSIONAL_DEVELOPMENT)}
        >
          <BookIcon width="60" height="60" fill="#062677" />
        </RewardTile>

        <RewardTile
          id={EDUCATION_SAVINGS}
          label="Education Savings"
          onClick={toggleSelection}
          isActive={selections.includes(EDUCATION_SAVINGS)}
        >
          <GraduateIcon width="60" height="60" fill="#062677" />
        </RewardTile>

        { customSelections.map((selection, index) => (
          <RewardTile
            key={index}
            id={selection}
            label={selection}
            deleteable
            isActive={true}
            onDelete={removeCustomSelection}
          >
            <StarIcon width="60" height="60" fill="#062677" />
          </RewardTile>
        ))}

        {
          includeCustom && (
            <div className="addCustom" onClick={() => setEditing(true)}>
              { !editing ? <div>
                            Add a custom benefit
                          </div> :
                          <>
                            <TextField
                              borderless
                              underlined
                              multiline
                              resizable={false}
                              placeholder="Enter reward..."
                              onChange={e => setCustomText(e.target.value)}
                            />
                            <PrimaryButton onClick={submitCustom} disabled={!customText}>
                              Submit
                            </PrimaryButton>
                          </>
              }
            </div>
          )
        }
      </div>
      <PrimaryButton className="submit" onClick={submit}>
        Next Step
      </PrimaryButton>
    </div>
  )
}

SelectRewards.defaultProps = {
  onSubmit: selectRewards,
  includeCustom: true,
  includePreviewText: false,
}

export default SelectRewards
