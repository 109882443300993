import { FETCH_PREVIEW_FAILURE, FETCH_PREVIEW_REQUESTED, FETCH_PREVIEW_SUCCESS, PREVIEW_CREATED_FAILURE, PREVIEW_CREATED_REQUESTED, PREVIEW_CREATED_SUCCESS, SELECT_PREVIEW_REWARDS, SET_PREVIEW_SALARY, SET_PREVIEW_STATUS } from "constants/actions"

import {
  createBundlePreview as createBundlePreviewRequest,
  fetchBundlePreview as fetchBundlePreviewRequest,
} from "api/requests"

export const selectPreviewRewards = (selections) => async (dispatch) => {
  dispatch({
    type: SELECT_PREVIEW_REWARDS,
    data: selections,
  })
}

export const setPreviewStatus = (status) => async (dispatch) => {
  dispatch({
    type: SET_PREVIEW_STATUS,
    data: status,
  })
}

export const setPreviewSalary = (salary) => async (dispatch) => {
  dispatch({
    type: SET_PREVIEW_SALARY,
    data: salary,
  })
}


export const fetchBundlePreview = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_PREVIEW_REQUESTED,
  })

  try {
    const response = await fetchBundlePreviewRequest(id)
    const responseData = response.data?.data

    if (responseData) { 
      dispatch({
        type: FETCH_PREVIEW_SUCCESS,
        data: {
          id: responseData.id,
          ...responseData?.attributes,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: FETCH_PREVIEW_FAILURE,
    })
  }
}


export const createBundlePreview = (config, fourDayEnabled = false) => async (dispatch) => {
  dispatch({
    type: PREVIEW_CREATED_REQUESTED,
  })

  try {
    const response = await createBundlePreviewRequest(config, fourDayEnabled)

    const responseData = response.data?.data
    if (responseData) {
      dispatch({
        type: PREVIEW_CREATED_SUCCESS,
        data: {
          id: responseData.id,
          ...responseData?.attributes,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: PREVIEW_CREATED_FAILURE
    })
  }
}
