import isNumber from 'lodash/isNumber'

const YEAR_HOURS = 2087

export const normalizedRewardValue = (valueType, valueMultiplier, salaryRatio, baseSalary) => {
  return (value) => {
    if (valueType === 'dollar') {
      return value
    }
  
    if (valueType === 'percent') {
      return Math.round(baseSalary * (value/100) * salaryRatio)
    }

    if (valueType === 'share') {
      return Math.round(value * valueMultiplier)
    }
  
    if (valueType === 'day') {
      // Normalize salary based on hourly rate
      return Math.round((8*(baseSalary*salaryRatio)/YEAR_HOURS)*value)
    }
  }
}

export const generateRewardMapping = (rewards, rewardDefaults = {}, baseSalary, includeBase = false, minSalary, maxSalary, includeHidden = false) => {
  const visibleRewards = rewards.filter((reward) => {
    return rewardDefaults[reward.id] && typeof rewardDefaults[reward.id] === 'object' ? rewardDefaults[reward.id].visible : true
  })

  const rewardsToMap = includeHidden ? rewards : visibleRewards
  const mapped = rewardsToMap.map((reward) => {
    const employmentConfig = reward.employmentConfig
    const fullTimeData = employmentConfig["full_time"] || {}
    let defaultValue = isNumber(rewardDefaults[reward.id]?.value) ? rewardDefaults[reward.id]?.value : rewardDefaults[reward.id] || fullTimeData["default"] || fullTimeData["min"]
    const visible = rewardDefaults[reward.id] ? rewardDefaults[reward.id].visible : true
    let min = fullTimeData["min"]
    const max = fullTimeData["max"]
    const disabled = !Boolean(fullTimeData["customize"])
    const computeValue = normalizedRewardValue(reward.valueType, reward.valueMultiplier, reward.salaryRatio, baseSalary)
    const computeDiscountedValue = (value) => Math.round(computeValue(value) * (1 - (reward.discountPercent || 0)))
    const description = reward.description
    const notes = reward.notes
    const vendorName = reward.vendorName
    const vendorUrl = reward.vendorUrl
    const valueMultiplier = reward.valueMultiplier
    const discountPercent = reward.discountPercent

    return {
      [reward.id] : {
        id: reward.id,
        name: reward.label,
        defaultValue,
        value: defaultValue,
        computedValue: computeValue(defaultValue),
        computeValue,
        discountValue: computeDiscountedValue(defaultValue),
        computeDiscountedValue,
        min,
        computedMin: computeValue(min),
        max,
        discountPercent,
        disabled,
        customize: !disabled,
        step: reward.stepSize,
        computedStep: computeValue(reward.stepSize),
        salaryRatio: reward.salaryRatio,
        valueType: reward.valueType,
        locked: false,
        description,
        notes,
        vendorName,
        vendorUrl,
        visible,
        valueMultiplier,
      }
    }
  })

  const computedBase = normalizedRewardValue("dollar", 1, 1, baseSalary)
  const finalMappedRewards = includeBase ? {
    "BASE_SALARY": {
      name: "Base Salary",
      defaultValue: baseSalary,
      value: baseSalary,
      computedValue: computedBase(baseSalary),
      computeValue: computedBase,
      discountValue: computedBase(baseSalary),
      computeDiscountedValue: computedBase,
      min: minSalary || 0,
      computedMin: minSalary || 0,
      max: maxSalary || Math.round(baseSalary * 1.5),
      disabled: false,
      customize: true,
      step: 100,
      computedStep: 100,
      salaryRatio: 1,
      valueType: "dollar",
      locked: false,
      description: "Base Salary",
      notes: "Base Salary",
      vendorName: null,
      vendorUrl: null,
      visible: true,
    },
  } : {}

  return Object.assign(finalMappedRewards, ...mapped)
}
