import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  PrimaryButton,
} from "@fluentui/react"

import { addEmployee } from "actions/company"
import { selectCompany } from "selectors/company"

import './AddEmployee.scss'
import EmployeeModal from "./EmployeeModal"

const AddEmployee = () => {
  const dispatch = useDispatch()
  const company = useSelector(selectCompany)
  const [modalOpen, setModalOpen] = useState(false)

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleCreateEmployee = ({ firstName, lastName, email, baseSalary, selectedRoleId }) => {
    handleCloseModal()
    dispatch(addEmployee({ firstName, lastName, email, baseSalary }, selectedRoleId, company?.id))
  }

  return (
    <>
      <PrimaryButton onClick={handleOpenModal}>
        Add Employee
      </PrimaryButton>
      {modalOpen && <EmployeeModal
        modalTitle='Add New Employee'
        submitLabel='Create'
        isOpen={modalOpen}
        onClose={handleCloseModal}
        onSubmit={handleCreateEmployee}
      /> }
    </>
  )
}

export default AddEmployee
