import {
  ActionButton,
} from "@fluentui/react"

import './ArchiveButton.scss'

const ArchiveButton = ({ status, onArchive, onUnarchive }) => {
  const statusText = status === 'archived' ? "Unarchive" : "Archive"
  const statusAction = status === 'archived' ? onUnarchive : onArchive
  const statusIcon = status === 'archived' ? "Refresh" : "Archive"

  return (
    <ActionButton
      className="editPencil"
      iconProps= {{ iconName: statusIcon }}
      onClick={statusAction}
    >
      { statusText }
    </ActionButton>
  )
}

export default ArchiveButton
