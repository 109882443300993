import {
  CREATE_OFFER_LETTER_FAILURE,
  CREATE_OFFER_LETTER_REQUESTED,
  CREATE_OFFER_LETTER_SUCCESS,
  FETCH_OFFER_LETTERS_FAILURE,
  FETCH_OFFER_LETTERS_REQUESTED,
  FETCH_OFFER_LETTERS_SUCCESS,
  FETCH_OFFER_LETTER_FAILURE,
  FETCH_OFFER_LETTER_REQUESTED,
  FETCH_OFFER_LETTER_SUCCESS,
  FETCH_OFFER_LETTER_APPROVERS_FAILURE,
  FETCH_OFFER_LETTER_APPROVERS_REQUESTED,
  FETCH_OFFER_LETTER_APPROVERS_SUCCESS,
  CREATE_DEFAULT_OFFER_APPROVER_FAILURE,
  CREATE_DEFAULT_OFFER_APPROVER_REQUESTED,
  CREATE_DEFAULT_OFFER_APPROVER_SUCCESS,
  DELETE_DEFAULT_OFFER_APPROVER_FAILURE,
  DELETE_DEFAULT_OFFER_APPROVER_REQUESTED,
  DELETE_DEFAULT_OFFER_APPROVER_SUCCESS,
  CREATE_OFFER_APPROVER_REQUESTED,
  CREATE_OFFER_APPROVER_SUCCESS,
  CREATE_OFFER_APPROVER_FAILURE,
  DELETE_OFFER_APPROVER_SUCCESS,
  DELETE_OFFER_APPROVER_REQUESTED,
  DELETE_OFFER_APPROVER_FAILURE,
  UPDATE_OFFER_LETTER_REQUESTED,
  UPDATE_OFFER_LETTER_SUCCESS,
  UPDATE_OFFER_LETTER_FAILURE,
  SEND_OFFER_APPROVERS_REQUESTED,
  SEND_OFFER_APPROVERS_SUCCESS,
  SEND_OFFER_APPROVERS_FAILURE,
  APPROVE_OFFER_REQUESTED,
  APPROVE_OFFER_SUCCESS,
  APPROVE_OFFER_FAILURE,
  REJECT_OFFER_REQUESTED,
  REJECT_OFFER_SUCCESS,
  REJECT_OFFER_FAILURE,
  OFFER_LETTER_LINK_COPIED,
  ARCHIVE_OFFER_REQUESTED,
  ARCHIVE_OFFER_SUCCESS,
  ARCHIVE_OFFER_FAILURE,
  UNARCHIVE_OFFER_REQUESTED,
  UNARCHIVE_OFFER_SUCCESS,
  UNARCHIVE_OFFER_FAILURE,
} from 'constants/actions'

import {
  fetchOfferLetters as fetchOfferLettersRequest,
  fetchOfferLetter as fetchOfferLetterRequest,
  fetchOfferLetterApprovers as fetchOfferLetterApproversRequest,
  createOfferLetter as createOfferLetterRequest,
  updateOfferLetter as updateOfferLetterRequest,
  createDefaultOfferApprover as createDefaultOfferApproverRequest,
  deleteDefaultOfferApprover as deleteDefaultOfferApproverRequest,
  createOfferApprover as createOfferApproverRequest,
  deleteOfferApprover as deleteOfferApproverRequest,
  sendOfferApprovers as sendOfferApproversRequest,
  approveOffer as approveOfferRequest,
  rejectOffer as rejectOfferRequest,
  archiveOffer as archiveOfferRequest,
  unarchiveOffer as unarchiveOfferRequest,
} from 'api/requests'

export const createOfferLetter = (offerLetterData) => async (dispatch) => {
  dispatch({
    type: CREATE_OFFER_LETTER_REQUESTED,
  })

  try {
    const response = await createOfferLetterRequest(offerLetterData)
    const offers = response.data?.data

    const offerData = offers.map((offer) => {
      return {
        id: offer.id,
        ...offer.attributes,
      }
    })

    if (response.status === 201) {
      dispatch({
        type: CREATE_OFFER_LETTER_SUCCESS,
        data: {
          offerLetters: offerData,
        }
      })
    }
  } catch (error) {
    dispatch({
      type: CREATE_OFFER_LETTER_FAILURE,
    })
  }
}

export const updateOfferLetter = (offerLetterData) => async (dispatch) => {
  dispatch({
    type: UPDATE_OFFER_LETTER_REQUESTED,
  })

  try {
    const response = await updateOfferLetterRequest(offerLetterData)
    const offerData = response.data?.data

    if (offerData) {
      dispatch({
        type: UPDATE_OFFER_LETTER_SUCCESS,
        data: {
          currentOfferLetter: {
            id: offerData?.id,
            ...offerData?.attributes,
          },
        },
      })
    }
  } catch (error) {
    dispatch({
      type: UPDATE_OFFER_LETTER_FAILURE,
    })
  }
}


export const getOfferLetters = (companyId) => async (dispatch) => {
  dispatch({
    type: FETCH_OFFER_LETTERS_REQUESTED,
  })

  try {
    const response = await fetchOfferLettersRequest(companyId)
    const offers = response.data?.data

    const offerData = offers.map((offer) => {
      return {
        id: offer.id,
        ...offer.attributes,
      }
    })

    if (offerData) {
      dispatch({
        type: FETCH_OFFER_LETTERS_SUCCESS,
        data: {
          offerLetters: offerData,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: FETCH_OFFER_LETTERS_FAILURE,
    })
  }
}

export const getOfferLetter = (offerId) => async (dispatch) => {
  dispatch({
    type: FETCH_OFFER_LETTER_REQUESTED,
  })

  try {
    const response = await fetchOfferLetterRequest(offerId)
    const offerData = response.data?.data

    if (offerData) {
      dispatch({
        type: FETCH_OFFER_LETTER_SUCCESS,
        data: {
          currentOfferLetter: {
            id: offerData?.id,
            ...offerData?.attributes,
          },
        },
      })
    }
  } catch (error) {
    dispatch({
      type: FETCH_OFFER_LETTER_FAILURE,
    })
  }
}

export const getOfferLetterApprovers = (companyId) => async (dispatch) => {
  dispatch({
    type: FETCH_OFFER_LETTER_APPROVERS_REQUESTED,
  })

  try {
    const response = await fetchOfferLetterApproversRequest(companyId)
    const approvers = response.data?.data

    const approverData = approvers.map((approver) => {
      return {
        id: approver.id,
        ...approver.attributes,
      }
    })

    if (approverData) {
      dispatch({
        type: FETCH_OFFER_LETTER_APPROVERS_SUCCESS,
        data: {
          approvers: approverData,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: FETCH_OFFER_LETTER_APPROVERS_FAILURE,
    })
  }
}

export const createDefaultOfferApprover = (companyId, approverEmail) => async (dispatch) => {
  dispatch({
    type: CREATE_DEFAULT_OFFER_APPROVER_REQUESTED,
  })

  try {
    const response = await createDefaultOfferApproverRequest(companyId, approverEmail)
    const approvers = response.data?.data

    const approverData = approvers.map((approver) => {
      return {
        id: approver.id,
        ...approver.attributes,
      }
    })

    if (response.status === 201) {
      dispatch({
        type: CREATE_DEFAULT_OFFER_APPROVER_SUCCESS,
        data: {
          approvers: approverData,
        }
      })
    }
  } catch (error) {
    dispatch({
      type: CREATE_DEFAULT_OFFER_APPROVER_FAILURE,
    })
  }
}

export const deleteDefaultOfferApprover = (companyId, approverEmail) => async (dispatch) => {
  dispatch({
    type: DELETE_DEFAULT_OFFER_APPROVER_REQUESTED,
  })

  try {
    const response = await deleteDefaultOfferApproverRequest(companyId, approverEmail)
    const approvers = response.data?.data

    const approverData = approvers.map((approver) => {
      return {
        id: approver.id,
        ...approver.attributes,
      }
    })

    if (response.status === 201) {
      dispatch({
        type: DELETE_DEFAULT_OFFER_APPROVER_SUCCESS,
        data: {
          approvers: approverData,
        }
      })
    }
  } catch (error) {
    dispatch({
      type: DELETE_DEFAULT_OFFER_APPROVER_FAILURE,
    })
  }
}

export const createOfferApprover = (offerId, approverEmail) => async (dispatch) => {
  dispatch({
    type: CREATE_OFFER_APPROVER_REQUESTED,
  })

  try {
    const response = await createOfferApproverRequest(offerId, approverEmail)
    const offerData = response.data?.data

    if (offerData) {
      dispatch({
        type: CREATE_OFFER_APPROVER_SUCCESS,
        data: {
          currentOfferLetter: {
            id: offerData?.id,
            ...offerData?.attributes,
          },
        },
      })
    }
  } catch (error) {
    dispatch({
      type: CREATE_OFFER_APPROVER_FAILURE,
    })
  }
}

export const deleteOfferApprover = (offerId, approverEmail) => async (dispatch) => {
  dispatch({
    type: DELETE_OFFER_APPROVER_REQUESTED,
  })

  try {
    const response = await deleteOfferApproverRequest(offerId, approverEmail)
    const offerData = response.data?.data

    if (offerData) {
      dispatch({
        type: DELETE_OFFER_APPROVER_SUCCESS,
        data: {
          currentOfferLetter: {
            id: offerData?.id,
            ...offerData?.attributes,
          },
        },
      })
    }
  } catch (error) {
    dispatch({
      type: DELETE_OFFER_APPROVER_FAILURE,
    })
  }
}

export const sendOfferApprovers = (offerId) => async (dispatch) => {
  dispatch({
    type: SEND_OFFER_APPROVERS_REQUESTED,
  })

  try {
    const response = await sendOfferApproversRequest(offerId)

    if (response.status === 201) {
      dispatch({
        type: SEND_OFFER_APPROVERS_SUCCESS,
      })
    }
  } catch (error) {
    dispatch({
      type: SEND_OFFER_APPROVERS_FAILURE,
    })
  }
}

export const approveOffer = (offerId, note) => async (dispatch) => {
  dispatch({
    type: APPROVE_OFFER_REQUESTED,
  })

  try {
    const response = await approveOfferRequest(offerId, note)
    const offerData = response.data?.data

    if (offerData) {
      dispatch({
        type: APPROVE_OFFER_SUCCESS,
        data: {
          currentOfferLetter: {
            id: offerData?.id,
            ...offerData?.attributes,
          },
        },
      })
    }
  } catch (error) {
    dispatch({
      type: APPROVE_OFFER_FAILURE,
    })
  }
}

export const rejectOffer = (offerId, note) => async (dispatch) => {
  dispatch({
    type: REJECT_OFFER_REQUESTED,
  })

  try {
    const response = await rejectOfferRequest(offerId, note)
    const offerData = response.data?.data

    if (offerData) {
      dispatch({
        type: REJECT_OFFER_SUCCESS,
        data: {
          currentOfferLetter: {
            id: offerData?.id,
            ...offerData?.attributes,
          },
        },
      })
    }
  } catch (error) {
    dispatch({
      type: REJECT_OFFER_FAILURE,
    })
  }
}

export const archiveOffer = (offerId) => async (dispatch) => {
  dispatch({
    type: ARCHIVE_OFFER_REQUESTED,
  })

  try {
    const response = await archiveOfferRequest(offerId)
    const offerData = response.data?.data

    if (offerData) {
      dispatch({
        type: ARCHIVE_OFFER_SUCCESS,
        data: {
          currentOfferLetter: {
            id: offerData?.id,
            ...offerData?.attributes,
          },
        },
      })
    }
  } catch (error) {
    dispatch({
      type: ARCHIVE_OFFER_FAILURE,
    })
  }
}

export const unarchiveOffer = (offerId) => async (dispatch) => {
  dispatch({
    type: UNARCHIVE_OFFER_REQUESTED,
  })

  try {
    const response = await unarchiveOfferRequest(offerId)
    const offerData = response.data?.data

    if (offerData) {
      dispatch({
        type: UNARCHIVE_OFFER_SUCCESS,
        data: {
          currentOfferLetter: {
            id: offerData?.id,
            ...offerData?.attributes,
          },
        },
      })
    }
  } catch (error) {
    dispatch({
      type: UNARCHIVE_OFFER_FAILURE,
    })
  }
}

export const copiedLink = () => async (dispatch) => {
  dispatch({
    type: OFFER_LETTER_LINK_COPIED,
  })
}
