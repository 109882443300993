export const selectFeedbackLoading = (state) => {
  return state.feedback.isLoading
}

export const selectFeedbackSuccess = (state) => {
  return state.feedback.isSubmitted
}

export const selectSubmittedUserCount = (state) => {
  return state.feedback.summary.submittedUserCount || 0
}

export const selectTotalValue = (state) => {
  return state.feedback.summary.totalValue || 0
}

export const selectRewardSummary = (state) => {
  return state.feedback.summary.rewardSummary || []
}

export const selectDepartmentSummary = (state) => {
  return state.feedback.summary.departmentSummary || []
}

export const selectAverageOverallRating = (state) => {
  return state.feedback.summary.averageOverallRating || 0
}

export const selectAverageCustomizeRating = (state) => {
  return state.feedback.summary.averageCustomizeRating || 0
}

export const selectTopRewards = (state) => {
  return state.feedback.summary.topRewards || {}
}
