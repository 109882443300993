import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from 'react-router-dom'
import map from "lodash/map"
import uniq from "lodash/uniq"

import {
  getCompanyRoles,
  getCompanyRewards,
} from "actions/company"
import { selectCompany, selectCompanyRoles, selectLatestRoleId } from "selectors/company"

import {
  DetailsList,
  TextField,
  Icon,
  ChoiceGroup,
  Dropdown,
  IconButton,
} from "@fluentui/react"

import EditRole from './EditRole'

import './RoleList.scss'
import AddRole from "./AddRole"
import { useHistory } from "react-router-dom"


const filters = [
  { key: 'all', text: 'All Roles' },
  { key: 'needDefaultBundl', text: 'Needs Default Bundl' },
]

const defaultColumns = [
  {
    key: 'status',
    name: 'Status',
    minWidth: 120,
    maxWidth: 120,
    fieldName: 'status',
    isResizable: true,
  },
  {
    key: 'name',
    name: 'Name',
    minWidth: 200,
    fieldName: 'name',
    isResizable: true,
  },
  {
    key: 'department',
    name: 'Department',
    minWidth: 200,
    fieldName: 'department',
    isResizable: true,
  },
  {
    key: 'pointPercent',
    name: 'Point Allocation',
    minWidth: 125,
    maxWidth: 125,
    fieldName: 'pointPercent',
    isResizable: true,
    onRender: (item) => (
      `${item.pointPercent}%`
    ),
  },
  {
    key: 'default',
    name: 'Default Bundl',
    minWidth: 100,
    maxWidth: 100,
    isResizable: false,
    onRender: (item) => (
      <Link className="tableIcon" to={`/role/${item.id}`}>
        <Icon className="navigateIcon" iconName="NavigateExternalInline" />
      </Link>
    ),
  },
  {
    key: 'edit',
    name: 'Edit',
    minWidth: 40,
    maxWidth: 40,
    isResizable: false,
    onRender: (item) => (
      <EditRole
        className="tableIcon"
        id={item.id}
        name={item.name}
        percent={item.pointPercent}
        fourDayRatio={item.fourDayRatio}
        minSalary={item.minSalary}
        maxSalary={item.maxSalary}
        defaultMinSalary={item.defaultMinSalary}
        department={item.department}
      />
    ),
  },
]

const RoleList = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const company = useSelector(selectCompany)
  const roles = useSelector(selectCompanyRoles)
  const latestRoleId = useSelector(selectLatestRoleId)

  const [statusFilter, setStatusFilter] = useState('all')
  const [columns, setColumns] = useState(defaultColumns)
  const [roleList, setRoleList] = useState(roles)
  const [nameFilter, setNameFilter] = useState(null)
  const [departmentFilter, setDepartmentFilter] = useState(null)

  const departments = uniq(map(roles, "department"))
  const departmentItems = map(departments, (dept) => ({ key: dept, text: dept }))

  const computedRolesList = roleList.map((role) => {
    const status =  !role.hasValidDefaultBundle ? "Needs Default Bundl" : "Active"
    return {
      ...role,
      status,
    }
  })

  // TODO: This is a little hacky.  Consider a better strategy
  // This is for redirecting to default bundl after creating role
  useEffect(() => {
    if (roles.length > 0 && latestRoleId) {
      history.push(`/role/${latestRoleId}`)
    }
  }, [roles, latestRoleId, history])

  useEffect(() => {
    dispatch(getCompanyRoles(company.id))
    dispatch(getCompanyRewards(company.id))
  }, [dispatch, company])

  useEffect(() => {
    setRoleList(roles)
  }, [roles])

  const handleSetStatusKey = (ev, filter) => {
    setStatusFilter(filter.key)
  }

  const handleColumnClick = (ev, column) => {
    if (column.key === 'edit') { return }
  
    const newColumns = columns.slice()

    const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0]
    newColumns.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending
        currColumn.isSorted = true
      } else {
        newCol.isSorted = false
        newCol.isSortedDescending = true
      }
    })

    const newItems = copyAndSort(computedRolesList, currColumn.fieldName, currColumn.isSortedDescending)

    setColumns(columns)
    setRoleList(newItems)
  }

  const copyAndSort = (items, columnKey, isSortedDescending) => {
    const key = columnKey
    return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1))
  }

  const columnsWithClickHandler = columns.map((column) => {
    return {
      ...column,
      onColumnClick: handleColumnClick,
    }
  })

  const statusFilteredList = computedRolesList.filter((role) => {
    switch (statusFilter) {
      case 'all':
        return true
      case 'needDefaultBundl':
        return role.status === "Needs Default Bundl"
      default:
        return true
    }
  })

  const filteredRoleList = statusFilteredList.filter((role) => {
    if (nameFilter) {
      return role.name.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1
    } else {
      return true
    }
  })

  const filteredDepartmentList = filteredRoleList.filter((role) => {
    if (departmentFilter?.key) {
      return role.department.indexOf(departmentFilter.key) > -1
    } else {
      return true
    }
  })

  const handleFilterName = (ev, text) => {
    setNameFilter(text)
  }

  const handleFilterDepartment = (ev, department) => {
    setDepartmentFilter(department)
  }

  const handleClearDepartmentFilter = () => {
    setDepartmentFilter(null)
  }

  return (
    <div className="roleList">
      <h1>Roles</h1>
      <div className="roleActions">
        <AddRole buttonType="primary" />
      </div>
      <ChoiceGroup
        className="roleStatusFilters"
        label="Filter by status"
        defaultSelectedKey="all"
        options={filters}
        onChange={handleSetStatusKey}
      />
      <div className="roleFilterGroup">
        <TextField className="roleFilter" label="Filter by name:" onChange={handleFilterName} />
        <div className="roleFilter departmentFilter">
          <Dropdown
            className="departmentDropdown"
            label="Filter by department:"
            onChange={handleFilterDepartment}
            options={departmentItems}
            selectedKey={departmentFilter?.key || null}
          />
          <IconButton className="departmentClear" iconProps={{ iconName: "ChromeClose" }} onClick={handleClearDepartmentFilter} />
        </div>
      </div>
      <DetailsList
        className="roleTable"
        items={filteredDepartmentList}
        columns={columnsWithClickHandler}
        getKey={(item) => item.id}
        setKey="none"
        selectionMode="none"
        checkboxVisibility={2}
      />
    </div>
  )
}

export default RoleList
