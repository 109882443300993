import { Callout, Icon, Text } from "@fluentui/react"
import { useState } from "react"

import './RewardCallout.scss'

const RewardCallout = ({ id, description, notes, vendorName, vendorUrl }) => {
  const [calloutVisible, setCalloutVisible] = useState(false)

  const handleHideCallout = () => {
    setCalloutVisible(false)
  }

  const handleShowCallout = () => {
    setCalloutVisible(true)
  }

  return (
    <>
      <Icon
        id={`reward-${id}`}
        className="rewardInfoIcon"
        iconName="InfoSolid"
        onClick={handleShowCallout}
      />
      {calloutVisible && (
        <Callout
          className='rewardCallout'
          role="infodialog"
          ariaLabel="Reward Description"
          gapSpace={0}
          target={`#reward-${id}`}
          onDismiss={handleHideCallout}
          preventDismissOnScroll
          doNotLayer
        >
          <Text className="rewardCalloutTitle" block variant="large">
            Description
          </Text>
          <Text block variant="medium">
            {description}
          </Text>
          {
            notes && (
              <>
                <Text className="rewardCalloutTitle" block variant="large">
                  Notes From Admin
                </Text>
                <Text block variant="medium">
                  {notes}
                </Text>
              </>
            )
          }
          {
            (vendorName || vendorUrl) && (
              <>
                <Text className="rewardCalloutTitle" block variant="large">
                  Vendor Information
                </Text>
                <Text block variant="medium">
                  { vendorName && <span>{vendorName}</span> }
                  { vendorName && vendorUrl && <span>{' - '}</span>}
                  { vendorUrl && <span><a href={vendorUrl} target="_blank" rel="noopener noreferrer">{vendorUrl}</a></span>}
                </Text>
              </>
            )
          }
        </Callout>
      )}
    </>
  )
}

export default RewardCallout
