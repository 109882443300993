import {
  FETCH_COMPANY_REWARDS_REQUESTED,
  FETCH_COMPANY_REWARDS_SUCCESS,
  FETCH_COMPANY_REWARDS_FAILURE,
  CREATE_COMPANY_REQUESTED,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAILURE,
  SET_REWARDS_REQUESTED,
  SET_REWARDS_SUCCESS,
  SET_REWARDS_FAILURE,
  DELETE_REWARD_REQUESTED,
  DELETE_REWARD_SUCCESS,
  DELETE_REWARD_FAILURE,
  SET_DEFAULT_BUNDLE_REQUESTED,
  SET_DEFAULT_BUNDLE_SUCCESS,
  SET_DEFAULT_BUNDLE_FAILURE,
  FETCH_COMPANY_USERS_REQUESTED,
  FETCH_COMPANY_USERS_SUCCESS,
  FETCH_COMPANY_USERS_FAILURE,
  FETCH_COMPANY_ROLES_REQUESTED,
  FETCH_COMPANY_ROLES_SUCCESS,
  FETCH_COMPANY_ROLES_FAILURE,
  SEND_USER_BUNDLE_REQUESTED,
  SEND_USER_BUNDLE_SUCCESS,
  SEND_USER_BUNDLE_FAILURE,
  UPLOAD_EMPLOYEES_REQUESTED,
  UPLOAD_EMPLOYEES_SUCCESS,
  UPLOAD_EMPLOYEES_FAILURE,
  EXPORT_EMPLOYEES_REQUESTED,
  EXPORT_EMPLOYEES_SUCCESS,
  EXPORT_EMPLOYEES_FAILURE,
  ADD_ROLE_REQUESTED,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAILURE,
  EDIT_ROLE_REQUESTED,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_FAILURE,
  ADD_EMPLOYEE_REQUESTED,
  ADD_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_FAILURE,
  EDIT_EMPLOYEE_REQUESTED,
  EDIT_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_FAILURE,
  BULK_SEND_BUNDLE_REQUESTED,
  BULK_SEND_BUNDLE_SUCCESS,
  BULK_SEND_BUNDLE_FAILURE,
  REQUEST_DEMO_REQUESTED,
  REQUEST_DEMO_SUCCESS,
  REQUEST_DEMO_FAILURE,
  SELECT_REWARDS,
  FETCH_COMPANY_USER_REQUESTED,
  FETCH_COMPANY_USER_SUCCESS,
  FETCH_COMPANY_USER_FAILURE,
} from 'constants/actions'

import {
  getCompanyRewards as getCompanyRewardsRequest,
  getCompanyUsers as getCompanyUsersRequest,
  getCompanyUser as getCompanyUserRequest,
  getCompanyRoles as getCompanyRolesRequest,
  createCompany as createCompanyRequest,
  submitRewards as submitRewardsRequest,
  setDefaultBundle as setDefaultBundleRequest,
  sendUserBundle as sendUserBundleRequest,
  uploadEmployees as uploadEmployeesRequest,
  exportEmployees as exportEmployeesRequest,
  addRole as addRoleRequest,
  editRole as editRoleRequest,
  addEmployee as addEmployeeRequest,
  editEmployee as editEmployeeRequest,
  archiveEmployee as archiveEmployeeRequest,
  unarchiveEmployee as unarchiveEmployeeRequest,
  bulkSendBundle as bulkSendBundleRequest,
  requestDemo as requestDemoRequest,
  deleteReward as deleteRewardRequest,
} from 'api/requests'

export const getCompanyRewards = (companyId) => async (dispatch) => {
  dispatch({
    type: FETCH_COMPANY_REWARDS_REQUESTED,
  })

  try {
    const response = await getCompanyRewardsRequest(companyId)

    const responseData = response.data?.data
    if (responseData) {
      const rewards = responseData.map((reward) => {
        return {
          id: reward.id,
          ...reward.attributes,
        }
      })

      dispatch({
        type: FETCH_COMPANY_REWARDS_SUCCESS,
        data: {
          rewards,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: FETCH_COMPANY_REWARDS_FAILURE,
      error,
    })
  }
}

export const getCompanyUsers = (companyId) => async (dispatch) => {
  dispatch({
    type: FETCH_COMPANY_USERS_REQUESTED,
  })

  try {
    const response = await getCompanyUsersRequest(companyId)

    const responseData = response.data?.data
    if (responseData) {
      const bundles = response.data.included?.filter(obj => obj.type === 'bundle') || []
      const users = responseData.map((user) => {
        return {
          id: user.id,
          ...user.attributes,
        }
      })
      const bundlesData = bundles.map((bundle) => {
        return {
          id: bundle.id,
          ...bundle.attributes,
        }
      })

      dispatch({
        type: FETCH_COMPANY_USERS_SUCCESS,
        data: {
          users,
          bundles: bundlesData,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: FETCH_COMPANY_USERS_FAILURE,
      error,
    })
  }
}

export const getCompanyUser = (companyId, userId) => async (dispatch) => {
  dispatch({
    type: FETCH_COMPANY_USER_REQUESTED,
  })

  try {
    const response = await getCompanyUserRequest(companyId, userId)

    const responseData = response.data?.data
    if (responseData) {
      const bundle = response.data?.included?.filter(obj => obj.type === 'bundle') || []
      const user = {
        id: responseData.id,
        ...responseData.attributes,
      }
      const bundleData = {
        id: bundle[0]?.id,
        ...bundle[0]?.attributes,
      }

      dispatch({
        type: FETCH_COMPANY_USER_SUCCESS,
        data: {
          user,
          bundle: bundleData,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: FETCH_COMPANY_USER_FAILURE,
      error,
    })
  }
}

export const getCompanyRoles = (companyId) => async (dispatch) => {
  dispatch({
    type: FETCH_COMPANY_ROLES_REQUESTED,
  })

  try {
    const response = await getCompanyRolesRequest(companyId)

    const responseData = response.data?.data
    if (responseData) {
      const roles = responseData.map((role) => {
        return {
          id: role.id,
          ...role.attributes,
        }
      })
      const rewardDefaults = response.data.included?.filter(obj => obj.type === 'rewardDefault') || []
      const rewardDefaultsData = rewardDefaults.map((rewardDefault) => {
        return {
          id: rewardDefault.id,
          ...rewardDefault.attributes,
        }
      })

      dispatch({
        type: FETCH_COMPANY_ROLES_SUCCESS,
        data: {
          roles,
          rewardDefaults: rewardDefaultsData,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: FETCH_COMPANY_ROLES_FAILURE,
      error,
    })
  }
}

export const sendUserBundle = (userId, companyId) => async (dispatch) => {
  dispatch({
    type: SEND_USER_BUNDLE_REQUESTED,
  })

  try {
    const response = await sendUserBundleRequest(userId, companyId)

    if (response.status === 200) {
      dispatch({
        type: SEND_USER_BUNDLE_SUCCESS,
      })
    }
  } catch (error) {
    dispatch({
      type: SEND_USER_BUNDLE_FAILURE,
      data: {
        message: error.response?.data?.message,
      }
    })
  }
}

export const bulkSendBundle = (companyId) => async (dispatch) => {
  dispatch({
    type: BULK_SEND_BUNDLE_REQUESTED,
  })

  try {
    const response = await bulkSendBundleRequest(companyId)

    if (response.status === 200) {
      dispatch({
        type: BULK_SEND_BUNDLE_SUCCESS,
      })
    }
  } catch (error) {
    dispatch({
      type: BULK_SEND_BUNDLE_FAILURE
    })
  }
}

export const createCompany = (companyData) => async (dispatch) => {
  dispatch({
    type: CREATE_COMPANY_REQUESTED,
  })

  try {
    const response = await createCompanyRequest(companyData)

    const responseData = response.data?.data
    if (responseData) {
      dispatch({
        type: CREATE_COMPANY_SUCCESS,
        data: {
          id: responseData.id,
          ...responseData.attributes,
        }
      })
    }
  } catch (error) {
    dispatch({
      type: CREATE_COMPANY_FAILURE
    })
  }
}

export const selectRewards = (selections) => async (dispatch) => {
  dispatch({
    type: SELECT_REWARDS,
    data: selections,
  })
}

export const submitRewards = ({ id, rewards }) => async (dispatch) => {
  dispatch({
    type: SET_REWARDS_REQUESTED,
  })

  try {
    const response = await submitRewardsRequest({ id, rewards })

    const responseData = response.data?.data
    if (responseData) {
      const rewards = responseData.map((reward) => {
        return {
          id: reward.id,
          ...reward.attributes,
        }
      })

      dispatch({
        type: SET_REWARDS_SUCCESS,
        data: {
          rewards,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: SET_REWARDS_FAILURE
    })
  }
}

export const deleteReward = ({ id, rewardId }) => async (dispatch) => {
  dispatch({
    type: DELETE_REWARD_REQUESTED,
  })

  try {
    const response = await deleteRewardRequest({ id, rewardId })

    const responseData = response.data?.data
    if (responseData) {
      const rewards = responseData.map((reward) => {
        return {
          id: reward.id,
          ...reward.attributes,
        }
      })

      dispatch({
        type: DELETE_REWARD_SUCCESS,
        data: {
          rewards,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: DELETE_REWARD_FAILURE,
      error,
    })
  }
}

export const setDefaultBundle = (bundleData) => async (dispatch) => {
  dispatch({
    type: SET_DEFAULT_BUNDLE_REQUESTED,
  })

  try {
    const response = await setDefaultBundleRequest(bundleData)
    const responseData = response.data?.data

    if (responseData) {
      const rolesData = responseData.map((role) => {
        return {
          id: role.id,
          ...role.attributes,
        }
      })
      const rewardDefaults = response.data.included?.filter(obj => obj.type === 'rewardDefault') || []
      const rewardDefaultsData = rewardDefaults.map((rewardDefault) => {
        return {
          id: rewardDefault.id,
          ...rewardDefault.attributes,
        }
      })

      dispatch({
        type: SET_DEFAULT_BUNDLE_SUCCESS,
        data: {
          roles: rolesData,
          rewardDefaults: rewardDefaultsData,
        }
      })
    }
  } catch (error) {
    dispatch({
      type: SET_DEFAULT_BUNDLE_FAILURE
    })
  }
}

export const setDefaultBundleError = (message) => async (dispatch) => {
  dispatch({
    type: SET_DEFAULT_BUNDLE_FAILURE,
    data: {
      message,
    }
  })
}

export const uploadEmployees = (formData, companyId) => async (dispatch) => {
  dispatch({
    type: UPLOAD_EMPLOYEES_REQUESTED,
  })

  try {
    const response = await uploadEmployeesRequest(formData)

    const responseData = response.data?.data
    if (responseData) {
      const users = responseData.map((user) => {
        return {
          id: user.id,
          bundleId: user.relationships.bundle.data?.id,
          ...user.attributes,
        }
      })

      dispatch({
        type: UPLOAD_EMPLOYEES_SUCCESS,
        data: {
          users,
        }
      })

      // TODO: Do something better than this
      // Need to refetch roles after upload
      dispatch(getCompanyRoles(companyId))
    }
  } catch (error) {
    dispatch({
      type: UPLOAD_EMPLOYEES_FAILURE
    })
  }
}

export const exportEmployees = (companyId) => async (dispatch) => {
  dispatch({
    type: EXPORT_EMPLOYEES_REQUESTED,
  })

  try {
    const response = await exportEmployeesRequest(companyId)
    const responseData = response.data

    if (responseData) {
      const today = new Date()
      const downloadUrl = window.URL.createObjectURL(new Blob([responseData]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', `bundl_employees_${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}.csv`)
      document.body.appendChild(link)
      link.click()
      link.remove()

      dispatch({
        type: EXPORT_EMPLOYEES_SUCCESS,
      })
    }
  } catch (error) {
    dispatch({
      type: EXPORT_EMPLOYEES_FAILURE
    })
  }
}

export const addRole = (name, department, pointPercent, minSalary, maxSalary, companyId) => async (dispatch) => {
  dispatch({
    type: ADD_ROLE_REQUESTED,
  })

  try {
    const response = await addRoleRequest(name, department, pointPercent, minSalary, maxSalary, companyId)
    const responseData = response.data?.data

    if (responseData) {
      dispatch({
        type: ADD_ROLE_SUCCESS,
        data: {
          id: responseData.id,
          ...responseData?.attributes,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: ADD_ROLE_FAILURE
    })
  }
}

export const editRole = (id, { name, department, percent, minSalary, maxSalary, fourDayRatio }, companyId) => async (dispatch) => {
  dispatch({
    type: EDIT_ROLE_REQUESTED,
  })

  try {
    const response = await editRoleRequest(id, { name, department, percent, minSalary, maxSalary, fourDayRatio }, companyId)
    const responseData = response.data?.data

    if (responseData) {
      dispatch({
        type: EDIT_ROLE_SUCCESS,
        data: {
          id: responseData.id,
          ...responseData?.attributes,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: EDIT_ROLE_FAILURE
    })
  }
}

export const addEmployee = (userData, roleId, companyId) => async (dispatch) => {
  dispatch({
    type: ADD_EMPLOYEE_REQUESTED,
  })

  try {
    const response = await addEmployeeRequest(userData, roleId, companyId)
    const responseData = response.data?.data

    if (responseData) {
      dispatch({
        type: ADD_EMPLOYEE_SUCCESS,
        data: {
          id: responseData.id,
          ...responseData?.attributes,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: ADD_EMPLOYEE_FAILURE
    })
  }
}

export const editEmployee = (id, userData, companyId) => async (dispatch) => {
  dispatch({
    type: EDIT_EMPLOYEE_REQUESTED,
  })

  try {
    const response = await editEmployeeRequest(id, userData, companyId)
    const responseData = response.data?.data

    if (responseData) {
      dispatch({
        type: EDIT_EMPLOYEE_SUCCESS,
        data: {
          id: responseData.id,
          ...responseData?.attributes,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: EDIT_EMPLOYEE_FAILURE
    })
  }
}

export const archiveEmployee = (id, companyId) => async (dispatch) => {
  dispatch({
    type: EDIT_EMPLOYEE_REQUESTED,
  })

  try {
    const response = await archiveEmployeeRequest(id, companyId)
    const responseData = response.data?.data

    if (responseData) {
      dispatch({
        type: EDIT_EMPLOYEE_SUCCESS,
        data: {
          id: responseData.id,
          ...responseData?.attributes,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: EDIT_EMPLOYEE_FAILURE
    })
  }
}

export const unarchiveEmployee = (id, companyId) => async (dispatch) => {
  dispatch({
    type: EDIT_EMPLOYEE_REQUESTED,
  })

  try {
    const response = await unarchiveEmployeeRequest(id, companyId)
    const responseData = response.data?.data

    if (responseData) {
      dispatch({
        type: EDIT_EMPLOYEE_SUCCESS,
        data: {
          id: responseData.id,
          ...responseData?.attributes,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: EDIT_EMPLOYEE_FAILURE
    })
  }
}

export const requestDemo = (companyId) => async (dispatch) => {
  dispatch({
    type: REQUEST_DEMO_REQUESTED,
  })

  try {
    const response = await requestDemoRequest(companyId)
    const responseData = response.data

    if (responseData) {
      dispatch({
        type: REQUEST_DEMO_SUCCESS,
      })
    }
  } catch (error) {
    dispatch({
      type: REQUEST_DEMO_FAILURE
    })
  }
}
