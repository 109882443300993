import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import {
  selectUserBundle,
  selectUserId,
  selectUserRole,
  selectUser,
  selectUserRewardDefaults,
  selectUserIsError,
} from "selectors/user"
import {
  selectCompanyRewards,
} from "selectors/company"

import { createBundle } from "actions/bundle"
import { getCompanyRewards } from "actions/company"

import { Checkbox, DefaultButton, Dialog, DialogFooter, PrimaryButton, TextField } from "@fluentui/react"
import BundleBuilder from "components/shared/BundleBuilder"

const EmployeeOfferLetter = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [verifyModalOpen, setVerifyModalOpen] = useState(false)
  const [bundleValues, setBundleValues] = useState([])
  const [fourDayEnabled, setFourDayEnabled] = useState(false)
  const [notes, setNotes] = useState("")
  const [signature, setSignature] = useState("")
  const [checked, setChecked] = useState(false)

  const userError = useSelector(selectUserIsError)
  const companyRole = useSelector(selectUserRole)
  const user = useSelector(selectUser)
  const userId = useSelector(selectUserId)
  const userBundle = useSelector(selectUserBundle)
  const rewardDefaults = useSelector(selectUserRewardDefaults)
  const rewards = useSelector(selectCompanyRewards)

  const companyId = user.company?.id
  const { firstName, lastName, offerLetterBase: baseSalary } = user

  const handleSubmit = () => {
    dispatch(createBundle(userId, bundleValues, fourDayEnabled, notes))
    handleCloseVerifyModal()
  }

  const handleCloseVerifyModal = () => {
    setVerifyModalOpen(false)
    setSignature("")
    setChecked(false)
  }

  const handleOpenVerifyModal = (values, _pointsRemaining, fourDayEnabled, noteValue) => {
    setVerifyModalOpen(true)
    setBundleValues(values)
    setFourDayEnabled(fourDayEnabled)
    setNotes(noteValue)
  }

  const handleUpdateSignature = (ev, value) => {
    setSignature(value)
  }

  const handleSignatureError = (value) => {
    const trimmedValue = value.trim()

    if (trimmedValue === `${firstName} ${lastName}`) {
      return ''
    } else {
      return 'Please ensure your name matches the name on your Bundl profile'
    }
  }

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyRewards(companyId))
    }
  }, [dispatch, companyId])

  useEffect(() => {
    if (userError) {
      history.push('/link-expired')
    }
  }, [userError, history])
  
  const dialogContentProps = {
    title: 'Verify Submission',
    closeButtonAriaLabel: 'Cancel',
    subText: 'Clicking "Verify" will complete your Bundl submission, after which you can no longer modify your selections. Please type your full name (as displayed in your Bundl profile) to serve as your signature and continue, or click "Go Back" to continue modifying your selections.',
  }

  const trimmedValue = signature.trim()
  const disabled = !(trimmedValue === `${firstName} ${lastName}`) || !checked

  return (
    <>
      <div className="createBundle">
        <h1>Configure Offer</h1>
        {
          !userBundle ?
            <div className="employeeCard">
              <div>
                <span className="cardLabel">Name:</span> {firstName} {lastName}
              </div>
              <div>
                <span className="cardLabel">Suggested Base Salary:</span> ${baseSalary?.toLocaleString('en-us')}
              </div>
              <div>
                <span className="cardLabel">Role:</span> {companyRole?.name}
              </div>
              <div>
                <span className="cardLabel">Department:</span> {companyRole?.department}
              </div>
            </div>
            : null
        }
        { !!userBundle ?
          <div className="submitSuccess">
            <h3>Submission successful!</h3>
            Thank you for your submission! You have been emailed a copy of your selections for your records. We have also notified your company admin to review your Bundl. Once they have confirmed your Bundl, you will receive an official offer letter with your selections. Please get in touch with your company point of contact with any questions.
          </div> :
            rewards.length > 0 ?
              <BundleBuilder
                onSubmit={handleOpenVerifyModal}
                baseSalary={baseSalary}
                minSalary={companyRole?.minSalary}
                maxSalary={companyRole?.maxSalary}
                pointPercent={companyRole?.pointPercent}
                fourDayPercentage={companyRole?.fourDayRatio}
                rewardDefaults={rewardDefaults}
                includeBase
                includeNotes
                includeTutorial
              /> : null
        }
      </div>
      <Dialog
        className="createVerifyModal"
        titleAriaId='Verify Submission'
        isOpen={verifyModalOpen}
        onDismiss={handleCloseVerifyModal}
        modalProps={{ isBlocking: false }}
        dialogContentProps={dialogContentProps}
      >
        <TextField
          label="Signature"
          onChange={handleUpdateSignature}
          onGetErrorMessage={handleSignatureError}
          deferredValidationTime={1500}
          validateOnLoad={false}
        />
        <Checkbox
          className="verifyCheckbox"
          label="I verify that these are my own selections and that I cannot modify them after submission"
          isChecked={checked}
          onChange={(_ev, checked) => setChecked(checked)}
        />
        <DialogFooter>
          <PrimaryButton disabled={disabled} onClick={handleSubmit}>
            Verify Submission
          </PrimaryButton>
          <DefaultButton onClick={handleCloseVerifyModal}>
            Go Back
          </DefaultButton>
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default EmployeeOfferLetter
