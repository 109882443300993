import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CopyToClipboard } from 'react-copy-to-clipboard'
import isEmpty from 'lodash/isEmpty'
import { DateTime } from 'luxon'
import startCase from 'lodash/startCase'

import {
  getCompanyRewards,
  getCompanyRoles,
  getCompanyUsers,
} from "actions/company"
import { selectCompany, selectCompanyRoles } from "selectors/company"

import {
  PrimaryButton,
  DialogType,
  ActionButton,
  TooltipHost,
} from "@fluentui/react"

import { EMAIL_REGEX } from 'constants/email'

import './ViewOfferLetter.scss'
import { selectCurrentOfferLetter } from "selectors/offerLetters"
import {
  getOfferLetter,
  createOfferApprover,
  deleteOfferApprover,
  sendOfferApprovers,
  copiedLink,
  archiveOffer,
  unarchiveOffer,
} from "actions/offerLetters"
import { useParams } from "react-router-dom"
import BackButton from "components/shared/BackButton"
import EditOfferLetter from "./EditOfferLetter"
import EditApprovers from "./EditApprovers"
import BundleBuilder from "components/shared/BundleBuilder"
import { Link } from "react-router-dom"
import ArchiveButton from "./ArchiveButton"

const CopyOfferLetterLink = ({ offerLink, status }) => {
  const dispatch = useDispatch()
  const disabled = status !== 'approved'
  const handleCopiedLink = () => {
    dispatch(copiedLink())
  }

  if (disabled) {
    return (
      <PrimaryButton onClick={handleCopiedLink} disabled={disabled}>
        Offer Link
      </PrimaryButton>
      )
  } else {
    return (
      <CopyToClipboard text={offerLink}>
        <PrimaryButton onClick={handleCopiedLink}>
          Offer Link
        </PrimaryButton>
      </CopyToClipboard>
    )
  }
}

const ViewOfferLetter = () => {
  const { id: offerId } = useParams()
  const dispatch = useDispatch()
  const company = useSelector(selectCompany)
  const companyRoles = useSelector(selectCompanyRoles)
  const currentOffer = useSelector(selectCurrentOfferLetter)
  const { statusSummary, firstName, lastName, baseSalary, accessToken, companyRoleId, approvalSummary, userId, email } = currentOffer
  const currentRole = companyRoles.find(role => role.id === companyRoleId)

  const [approverModalOpen, setApproverModalOpen] = useState(false)
  const [approverEmail, setApproverEmail] = useState("")
  const [approverEmailList, setApproverEmailList] = useState([])

  useEffect(() => {
    if (isEmpty(userId)) {
      dispatch(getCompanyUsers(company?.id))
    }
  }, [dispatch, company, userId])

  useEffect(() => {
    dispatch(getCompanyRewards(company?.id))
    dispatch(getCompanyRoles(company?.id))
  }, [dispatch, company])

  useEffect(() => {
    dispatch(getOfferLetter(offerId))
  }, [dispatch, company, offerId])

  useEffect(() => {
    if (!isEmpty(approvalSummary)) {
      const approverEmails = approvalSummary.map((approver) => approver.email)
      setApproverEmailList(approverEmails)
    } else {
      setApproverEmailList([])
    }
  }, [approvalSummary])

  const handleOpenApprovers = () => {
    setApproverModalOpen(true)
  }

  const handleCloseApprovers = () => {
    setApproverModalOpen(false)
  }

  const handleCreateApprover = () => {
    if (!isEmpty(approverEmail) && EMAIL_REGEX.test(approverEmail)) {
      dispatch(createOfferApprover(offerId, approverEmail))
      setApproverEmail("")
    }
  }

  const handleDeleteApprover = (email) => {
    dispatch(deleteOfferApprover(offerId, email))
  }

  const handleUpdateApproverEmail = (event) => {
    setApproverEmail(event.target.value)
  }

  const handleSendApprovers = () => {
    dispatch(sendOfferApprovers(offerId))
  }

  const handleArchive = () => {
    dispatch(archiveOffer(offerId))
  }

  const handleUnarchive = () => {
    dispatch(unarchiveOffer(offerId))
  }

  const approverModalProps = {
    type: DialogType.normal,
    title: "Set Approvers",
    closeButtonAriaLabel: "Close",
    subText: "Manage who is required for approval of this offer letter by entering their email addresses below",
  }

  const offerLink = `${process.env.REACT_APP_BASE_URL}/offer?token=${accessToken}`

  const getApprovalTimestamp = (approver) => {
    let timestamp
    if (approver.status === 'approved' && approver.approved_at) {
      timestamp = approver.approved_at
    }

    if (approver.status === 'rejected' && approver.rejected_at) {
      timestamp = approver.rejected_at
    }

    if (timestamp) {
      return (
        <span>
          &nbsp; - {DateTime.fromISO(timestamp).toFormat("MMMM dd, yyyy ttt")}
        </span>
      )
    }
  }

  return (
    <>
      <div className="viewOfferLetter">
        <BackButton />
        <div className="offerPrimaryActions">
          <TooltipHost
            content="Send to any pending approvers"
            id="send-approver"
          >
            <PrimaryButton onClick={handleSendApprovers}>
              Send To Approvers
            </PrimaryButton>
          </TooltipHost>
          <TooltipHost
            content="Share link with candidate"
            id="copy-candidate-link"
          >
            <CopyOfferLetterLink offerLink={offerLink} status={statusSummary} />
          </TooltipHost>
        </div>
        { currentOffer && <div className="offerCard">
                            <div className="offerActions">
                              <ArchiveButton
                                status={currentOffer.status}
                                onArchive={handleArchive}
                                onUnarchive={handleUnarchive}
                              />
                              <EditOfferLetter
                                id={offerId}
                                firstName={firstName}
                                lastName={lastName}
                                email={email}
                                roleId={currentRole?.id}
                                baseSalary={baseSalary}
                              />
                            </div>
                            <div><strong>Status:</strong> {startCase(statusSummary)}</div><br/>
                            <div>
                              <span className="cardLabel">Name:</span> {firstName} {lastName}
                            </div>
                            <div>
                              <span className="cardLabel">Email:</span> {email}
                            </div>
                            <div>
                              <span className="cardLabel">Base Salary:</span> ${baseSalary?.toLocaleString('en-us')}
                            </div>
                            <div>
                            <span className="cardLabel">Role:</span> {currentRole?.name}
                            </div>
                            <div>
                            <span className="cardLabel">Department:</span> {currentRole?.department}
                            </div>
                          </div>
        }
        {
          approvalSummary && (
            <div className="offerApprovers">
              <h3>Offer Letter Approvers</h3>
              <div className="offerActions">
                <ActionButton
                  className="editPencil"
                  iconProps={{ iconName: "Edit" }}
                  onClick={handleOpenApprovers}
                  text="Edit Approvers"
                />
              </div>
              { !isEmpty(approvalSummary) && approvalSummary.map((approver) => {
                return (
                  <div key={approver.id}>
                    {approver.email} - <strong>{startCase(approver.status)}</strong>{getApprovalTimestamp(approver)}
                    { approver.note && (
                      <div className="approvalNote">
                        Note: {approver.note}
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          )
        }
        {
          currentRole && statusSummary !== 'accepted' && (
            <>
              <h2>Offer Preview</h2>
              <BundleBuilder
                className="offerBundlePreview"
                baseSalary={baseSalary}
                minSalary={currentRole.minSalary}
                maxSalary={currentRole.maxSalary}
                pointPercent={currentRole.pointPercent}
                fourDayPercentage={currentRole.fourDayRatio}
                rewardDefaults={currentRole.rewardDefaults}
                disabled
                includeBase
              />
            </>
          )
        }
        {
          currentRole && statusSummary === 'accepted' && (
            <h3>View accepted offer Bundl: <Link to={`/employee/${userId}`}>View Employee</Link></h3>
          )
        }
      </div>
      <EditApprovers
        isOpen={approverModalOpen}
        onClose={handleCloseApprovers}
        onCreate={handleCreateApprover}
        onChange={handleUpdateApproverEmail}
        onDelete={handleDeleteApprover}
        modalProps={approverModalProps}
        approverEmailList={approverEmailList}
        approverEmail={approverEmail}
      />
    </>
  )
}

export default ViewOfferLetter
