import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"

import { selectCompany } from "selectors/company"
import { uploadEmployees } from "actions/company"

import { Dialog, DialogFooter, PrimaryButton } from "@fluentui/react"

const ImportEmployees = () => {
  const dispatch = useDispatch()
  const company = useSelector(selectCompany)
  const [modalOpen, setModalOpen] = useState(false)
  const [uploadedFile, setUploadedFile] = useState(null)

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setUploadedFile(null)
  }

  const handleFile = (ev) => {
    const file = ev.target.files[0]

    if (file.size > 50000) {
      // TODO: Show error
      return
    }

    setUploadedFile(file)
  }

  const handleUpload = () => {
    const formData = new FormData()
    formData.append("file", uploadedFile)
    formData.append("id", company.id)

    dispatch(uploadEmployees(formData, company.id))
    handleCloseModal()
  }

  const dialogContentProps = {
    title: 'Import Employees via CSV',
    closeButtonAriaLabel: 'Close',
    subText: 'CSV format must match our provided template.  Please use the column headers as follows:',
  }

  return (
    <>
      <PrimaryButton onClick={handleOpenModal}>
        Import
      </PrimaryButton>
      <Dialog
        hidden={!modalOpen}
        onDismiss={handleCloseModal}
        dialogContentProps={dialogContentProps}
      >

        <code>
          First Name,Last Name,Email,Base Salary,Role,Department
        </code>
        <br/><br/>
        <input type="file" accept=".csv" onChange={handleFile} />
        <DialogFooter>
          <PrimaryButton onClick={handleUpload} text="Submit" disabled={!uploadedFile} />
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default ImportEmployees
