import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { TextField, PrimaryButton, Spinner } from '@fluentui/react'

import { signUp } from 'actions/user'
import { isAuthenticated } from 'util/auth'
import {
  selectUserIsError,
  selectUserIsLoading,
} from 'selectors/user'

import { EMAIL_REGEX } from 'constants/email'

import './SignUp.scss'

const SignUp = () => {
  const history = useHistory()
  const isUserAuthenticated = isAuthenticated()
  const isLoading = useSelector(selectUserIsLoading)
  const isError = useSelector(selectUserIsError)

  const dispatch = useDispatch()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const handleSetEmail = (e) => setEmail(e.target.value)
  const handleSetPassword = (e) => setPassword(e.target.value)
  const handleSignUp = () => dispatch(signUp(email, password))

  const handleEmailError = (value) => {
    if (EMAIL_REGEX.test(value) || value === "") {
      return ''
    } else {
      return "Email must be a valid syntax"
    }
  }

  const handlePasswordError = (value) => {
    if (value?.length > 8 || value === "") {
      return ''
    } else {
      return "Password must be at least 8 characters"
    }
  }

  const disabled = isEmpty(email) || (!EMAIL_REGEX.test(email)) || isEmpty(password) || password.length < 8

  useEffect(() => {
    if (isUserAuthenticated && !isLoading) {
      history.push('/')
    }
  }, [isUserAuthenticated, history, isLoading])

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 && !disabled) {
      handleSignUp()
    }
  }

  return (
    <div className="signUp">
      { isError ? <p>An error occured while signing up.  Please try again.</p> : null }
      <h4>Sign Up</h4>
      <TextField
        label="Email"
        value={email}
        onChange={handleSetEmail}
        onGetErrorMessage={handleEmailError}
        deferredValidationTime={750}
        onKeyDown={handleKeyPress}
      />
      <TextField
        type="password"
        canRevealPassword
        revealPasswordAriaLabel="Show password"
        label="Password"
        value={password}
        onChange={handleSetPassword}
        onGetErrorMessage={handlePasswordError}
        deferredValidationTime={750}
        onKeyDown={handleKeyPress}
      />
      <div className="loginLink">Already have an account? <Link to="login">Click here.</Link></div>
      { isLoading ? <Spinner /> : <PrimaryButton className="submit" type="submit" text="Submit" onClick={handleSignUp} disabled={disabled} /> }
    </div>
  )
}

export default SignUp
