import {
  SUBMIT_FEEDBACK_REQUESTED,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_FAILURE,
  FETCH_FEEDBACK_SUMMARY_REQUESTED,
  FETCH_FEEDBACK_SUMMARY_SUCCESS,
  FETCH_FEEDBACK_SUMMARY_FAILURE,
} from 'constants/actions'

import {
  submitFeedback as submitFeedbackRequest,
  fetchFeedbackSummary as fetchFeedbackSummaryRequest,
} from 'api/requests'

export const submitFeedback = (feedbackData) => async (dispatch) => {
  dispatch({
    type: SUBMIT_FEEDBACK_REQUESTED,
  })

  try {
    const response = await submitFeedbackRequest(feedbackData)

    if (response.status === 201) {
      dispatch({
        type: SUBMIT_FEEDBACK_SUCCESS,
      })
    }
  } catch (error) {
    dispatch({
      type: SUBMIT_FEEDBACK_FAILURE
    })
  }
}

export const fetchFeedbackSummary = (companyId) => async (dispatch) => {
  dispatch({
    type: FETCH_FEEDBACK_SUMMARY_REQUESTED,
  })

  try {
    const response = await fetchFeedbackSummaryRequest(companyId)
    const responseData = response.data

    if (responseData) {
      dispatch({
        type: FETCH_FEEDBACK_SUMMARY_SUCCESS,
        data: {
          summary: responseData,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: FETCH_FEEDBACK_SUMMARY_FAILURE
    })
  }
}
