import { useSelector } from 'react-redux'

import SelectRewards from 'components/onboarding/SelectRewards'
import BundlePreview from 'components/preview/BundlePreview'
import WelcomePreview from 'components/preview/WelcomePreview'
import SetSalary from 'components/preview/SetSalary'

import { selectPreviewState } from 'selectors/preview'

import './PreviewDashboard.scss'

import {
  SET_SALARY,
  SELECT_REWARDS,
  PREVIEW_BUNDLE,
  WELCOME,
} from 'constants/preview'
import { selectPreviewRewards } from 'actions/preview'

const PREVIEW_STEP_MAP = {
  [WELCOME]: <WelcomePreview />,
  [SET_SALARY]: <SetSalary />,
  [SELECT_REWARDS]: <SelectRewards onSubmit={selectPreviewRewards} includeCustom={false} includePreviewText />,
  [PREVIEW_BUNDLE]: <BundlePreview />,
}

const PreviewDashboard = () => {
  const previewState = useSelector(selectPreviewState)

  return (
    <div className="previewStep">
      { PREVIEW_STEP_MAP[previewState] }
    </div>
  )
}

export default PreviewDashboard
