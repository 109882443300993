import { uniqueId } from 'lodash'

import {
  CLEAR_NOTIFICATION,
  ADD_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_SUCCESS,
  ADD_ROLE_SUCCESS,
  BULK_SEND_BUNDLE_SUCCESS,
  EXPORT_EMPLOYEES_SUCCESS,
  SEND_USER_BUNDLE_SUCCESS,
  SET_DEFAULT_BUNDLE_SUCCESS,
  UPLOAD_EMPLOYEES_SUCCESS,
  ADD_EMPLOYEE_FAILURE,
  EDIT_EMPLOYEE_FAILURE,
  ADD_ROLE_FAILURE,
  BULK_SEND_BUNDLE_FAILURE,
  EXPORT_EMPLOYEES_FAILURE,
  SEND_USER_BUNDLE_FAILURE,
  SET_DEFAULT_BUNDLE_FAILURE,
  UPLOAD_EMPLOYEES_FAILURE,
  VERIFY_BUNDLE_SUCCESS,
  VERIFY_BUNDLE_FAILURE,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_FAILURE,
  SET_REWARDS_SUCCESS,
  SET_REWARDS_FAILURE,
  DELETE_REWARD_SUCCESS,
  DELETE_REWARD_FAILURE,
  REQUEST_DEMO_FAILURE,
  REQUEST_DEMO_SUCCESS,
  CREATE_OFFER_LETTER_FAILURE,
  CREATE_OFFER_LETTER_SUCCESS,
  SEND_OFFER_APPROVERS_SUCCESS,
  OFFER_LETTER_LINK_COPIED,
  UPDATE_OFFER_LETTER_SUCCESS,
  CREATE_OFFER_APPROVER_SUCCESS,
  DELETE_OFFER_APPROVER_SUCCESS,
  APPROVE_OFFER_SUCCESS,
  REJECT_OFFER_SUCCESS,
  UPDATE_OFFER_LETTER_FAILURE,
  CREATE_OFFER_APPROVER_FAILURE,
  CREATE_DEFAULT_OFFER_APPROVER_FAILURE,
  SEND_OFFER_APPROVERS_FAILURE,
  APPROVE_OFFER_FAILURE,
  REJECT_OFFER_FAILURE,
  ARCHIVE_OFFER_SUCCESS,
  UNARCHIVE_OFFER_SUCCESS,
  ARCHIVE_OFFER_FAILURE,
  UNARCHIVE_OFFER_FAILURE,
} from 'constants/actions'

import { TYPES } from 'constants/notifications'

const initialState = {
  active: [],
}

const createNewNotification = (message, type) => {
  return {
    id: uniqueId(),
    message,
    type,
  }
}

const reducer = (state = initialState, { type, data }) => {
  let message

  switch (type) {
    case ADD_ROLE_SUCCESS:
      message = "Role was added successfully!"

      return {
        ...state,
        active: [...state.active, createNewNotification(message, TYPES.success)]
      }
    case EDIT_ROLE_SUCCESS:
      message = "Role was updated successfully!"

      return {
        ...state,
        active: [...state.active, createNewNotification(message, TYPES.success)]
      }
    case SET_DEFAULT_BUNDLE_SUCCESS:
      message = "Default Bundl was updated successfully!"

      return {
        ...state,
        active: [...state.active, createNewNotification(message, TYPES.success)]
      }
    case BULK_SEND_BUNDLE_SUCCESS:
      message = "Your Bundl invite links were sent successfully!"

      return {
        ...state,
        active: [...state.active, createNewNotification(message, TYPES.success)]
      }
    case SEND_USER_BUNDLE_SUCCESS:
      message = "Your Bundl invite link was sent successfully!"

      return {
        ...state,
        active: [...state.active, createNewNotification(message, TYPES.success)]
      }
    case EXPORT_EMPLOYEES_SUCCESS:
      message = "Employee export completed successfully!"

      return {
        ...state,
        active: [...state.active, createNewNotification(message, TYPES.success)]
      }
    case UPLOAD_EMPLOYEES_SUCCESS:
      message = "Employee import completed successfully!"

      return {
        ...state,
        active: [...state.active, createNewNotification(message, TYPES.success)]
      }
    case ADD_EMPLOYEE_SUCCESS:
      message = "Employee was added successfully!"

      return {
        ...state,
        active: [...state.active, createNewNotification(message, TYPES.success)]
      }
    case EDIT_EMPLOYEE_SUCCESS:
      message = "Employee was updated successfully!"

      return {
        ...state,
        active: [...state.active, createNewNotification(message, TYPES.success)]
      }
    case VERIFY_BUNDLE_SUCCESS:
      message = 'Employee Bundl has been verified!'

      return {
        ...state,
        active: [...state.active, createNewNotification(message, TYPES.success)]
      }
    case SET_REWARDS_SUCCESS:
    case DELETE_REWARD_SUCCESS:
      message = 'Rewards updated successfully!'

      return {
        ...state,
        active: [...state.active, createNewNotification(message, TYPES.success)]
      }
    case REQUEST_DEMO_SUCCESS:
      message = 'Demo requested. We will be in touch!'

      return {
        ...state,
        active: [...state.active, createNewNotification(message, TYPES.success)]
      }
    case CREATE_OFFER_LETTER_SUCCESS:
      message = 'Offer letter has been created!'

      return {
        ...state,
        active: [...state.active, createNewNotification(message, TYPES.success)]
      }
    case UPDATE_OFFER_LETTER_SUCCESS:
    case CREATE_OFFER_APPROVER_SUCCESS:
    case DELETE_OFFER_APPROVER_SUCCESS:
    case ARCHIVE_OFFER_SUCCESS:
    case UNARCHIVE_OFFER_SUCCESS:
      message = 'Offer letter updated succesfully!'

      return {
        ...state,
        active: [...state.active, createNewNotification(message, TYPES.success)]
      }
    case SEND_OFFER_APPROVERS_SUCCESS:
      message = 'Approval emails sent to pending approvers!'

      return {
        ...state,
        active: [...state.active, createNewNotification(message, TYPES.success)]
      }
    case OFFER_LETTER_LINK_COPIED:
      message = 'Offer letter link copied to clipboard!'

      return {
        ...state,
        active: [...state.active, createNewNotification(message, TYPES.success)]
      }
    case APPROVE_OFFER_SUCCESS:
    case REJECT_OFFER_SUCCESS:
      message = 'Approval response successfully updated!'

      return {
        ...state,
        active: [...state.active, createNewNotification(message, TYPES.success)]
      }
    case VERIFY_BUNDLE_FAILURE:
    case ADD_EMPLOYEE_FAILURE:
    case ADD_ROLE_FAILURE:
    case EDIT_ROLE_FAILURE:
    case BULK_SEND_BUNDLE_FAILURE:
    case EXPORT_EMPLOYEES_FAILURE:
    case SEND_USER_BUNDLE_FAILURE:
    case SET_DEFAULT_BUNDLE_FAILURE:
    case UPLOAD_EMPLOYEES_FAILURE:
    case EDIT_EMPLOYEE_FAILURE:
    case SET_REWARDS_FAILURE:
    case REQUEST_DEMO_FAILURE:
    case DELETE_REWARD_FAILURE:
    case CREATE_OFFER_LETTER_FAILURE:
    case UPDATE_OFFER_LETTER_FAILURE:
    case CREATE_OFFER_APPROVER_FAILURE:
    case CREATE_DEFAULT_OFFER_APPROVER_FAILURE:
    case SEND_OFFER_APPROVERS_FAILURE:
    case APPROVE_OFFER_FAILURE:
    case REJECT_OFFER_FAILURE:
    case ARCHIVE_OFFER_FAILURE:
    case UNARCHIVE_OFFER_FAILURE:
      message = data?.message || "An error occurred with your request. Please try again."

      return {
        ...state,
        active: [...state.active, createNewNotification(message, TYPES.error)]
      }
    case CLEAR_NOTIFICATION:
      const current = state.active.slice()
      const removed = current.filter(notification => notification.id !== data.id)
      return {
        ...state,
        active: removed,
      }
    default:
      return state
  }
}

export default reducer
