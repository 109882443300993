import {
  AUTHENTICATE_USER_REQUESTED,
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER_FAILURE,
  SET_PASSWORD_REQUESTED,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAILURE,
  FETCH_USER_REQUESTED,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  USER_SIGN_OUT,
  SIGN_UP_REQUESTED,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
} from 'constants/actions'

import {
  authenticateUser,
  fetchUser,
  setUserPassword as setUserPasswordReqest,
  signUpUser,
} from 'api/requests'
import { clearAuthHeader, setAuthHeader } from 'util/auth'

const resolveUserData = (responseData) => {
  const companyData = responseData.included?.find(obj => obj.type === 'company')
  const companyRoleData = responseData.included?.filter(obj => obj.type === 'companyRole') || []
  const rewardDefaults = responseData.included?.filter(obj => obj.type === 'rewardDefault') || []
  const bundleData = responseData.included?.filter(obj => obj.type === 'bundle') || []

  const company = companyData ? {
    id: companyData?.id,
    rewardDefaults: rewardDefaults.map(({ id, attributes }) => {
      return { id, ...attributes }
    }),
    ...companyData?.attributes,
  } : null

  const bundle = bundleData[0] ? {
    id: bundleData[0].id,
    ...bundleData[0].attributes,
  } : null

  const companyRole = companyRoleData[0] ? {
    id: companyRoleData[0].id,
    ...companyRoleData[0].attributes,
  } : null

  return {
    id: responseData.data.id,
    company,
    bundle,
    companyRole,
    ...responseData.data.attributes,
  }
}

export const login = (email, password) => async (dispatch) => {
  dispatch({
    type: AUTHENTICATE_USER_REQUESTED,
  })

  try {
    const response = await authenticateUser(email, password)

    if (response.data.token && response.data.user?.data) {
      setAuthHeader(response.data.token)
      const resolved = resolveUserData(response.data.user)

      dispatch({
        type: AUTHENTICATE_USER_SUCCESS,
        data: resolved,
      })
    }
  } catch (error) {
    dispatch({
      type: AUTHENTICATE_USER_FAILURE
    })
  }
}

export const signUp = (email, password) => async (dispatch) => {
  dispatch({
    type: SIGN_UP_REQUESTED,
  })

  try {
    const response = await signUpUser(email, password)

    if (response.data.token && response.data.user?.data) {
      setAuthHeader(response.data.token)
      const resolved = resolveUserData(response.data.user)

      dispatch({
        type: SIGN_UP_SUCCESS,
        data: resolved,
      })
    }
  } catch (error) {
    dispatch({
      type: SIGN_UP_FAILURE
    })
  }
}

export const setUserPassword = (id, password) => async (dispatch) => {
  dispatch({
    type: SET_PASSWORD_REQUESTED,
  })

  try {
    const response = await setUserPasswordReqest(id, password)

    if (response.data) { 
      dispatch({
        type: SET_PASSWORD_SUCCESS,
      })
    }
  } catch (error) {
    dispatch({
      type: SET_PASSWORD_FAILURE
    })
  }
}

export const signOut = () => async (dispatch) => {
  clearAuthHeader()

  dispatch({
    type: USER_SIGN_OUT,
  })
}

export const getUser = () => async (dispatch) => {
  dispatch({
    type: FETCH_USER_REQUESTED,
  })

  try {
    const response = await fetchUser()

    if (response.data) {
      const resolved = resolveUserData(response.data)
  
      dispatch({
        type: FETCH_USER_SUCCESS,
        data: resolved,
      })
    }
  } catch (error) {
    if (error.response?.status === 401) {
      clearAuthHeader()
    }

    dispatch({
      type: FETCH_USER_FAILURE,
    })
  }
}
