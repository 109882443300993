export const selectPreviewState = (state) => {
  return state.preview.status
}

export const selectPreviewRewards = (state) => {
  return state.preview.rewards.selections
}

export const selectPreviewSalary = (state) => {
  return state.preview.salary
}

export const selectPreviewBundle = (state) => {
  return state.preview.bundleData
}
