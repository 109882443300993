import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"

import { getCompanyRewards, submitRewards, deleteReward } from "actions/company"
import { selectCompany, selectCompanyRewards } from "selectors/company"
import { generateRewardMapping } from 'util/rewards'

import Rewards from "components/shared/Rewards"

const EditRewards = () => {
  const dispatch = useDispatch()

  const company = useSelector(selectCompany)
  const companyRewards = useSelector(selectCompanyRewards)
  const mappedRewards = generateRewardMapping(companyRewards) || {}
  const allRewards = Object.values(mappedRewards)

  useEffect(() => {
    dispatch(getCompanyRewards(company?.id))
  }, [company?.id, dispatch])

  const submit = (rewards) => {
    dispatch(submitRewards({
      id: company?.id,
      rewards,
    }))
  }

  const handleDelete = (rewardId) => {
    dispatch(deleteReward({
      id: company?.id,
      rewardId,
    }))
  }

  return (
    <div>
      <Rewards rewards={allRewards} handleSubmit={submit} handleDelete={handleDelete} />
    </div>
  )
}

export default EditRewards
