import './WelcomePreview.scss'

import bundlPreview from 'assets/bundl-preview.png'
import { PrimaryButton } from '@fluentui/react'
import { useDispatch } from 'react-redux'
import { setPreviewStatus } from 'actions/preview'
import { SET_SALARY } from 'constants/preview'

const WelcomePreview = () => {
  const dispatch = useDispatch()

  const handleSubmit = () => {
    dispatch(setPreviewStatus(SET_SALARY))
  }

  return (
    <>
      <h1>Welcome to Bundl!</h1>
      <div className="previewContents">
        <div>
          <p>
            The only totally customizable total reward tool built for employees. Give it a whirl, why don’t you build your dream compensation package?
          </p>
          <PrimaryButton onClick={handleSubmit}>
            Let's go
          </PrimaryButton>
        </div>
        <img src={bundlPreview} alt="Bundl Preview" />
      </div>
    </>
  )
}

export default WelcomePreview
