import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  ActionButton,
  PrimaryButton,
} from "@fluentui/react"

import { addRole } from "actions/company"
import { selectCompany } from "selectors/company"

import './AddRole.scss'
import RoleModal from "./RoleModal"

const AddRole = ({ className, buttonType }) => {
  const dispatch = useDispatch()
  const company = useSelector(selectCompany)
  const [modalOpen, setModalOpen] = useState(false)

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleCreateRole = ({ name, department, percent, minSalary, maxSalary }) => {
    handleCloseModal()
    dispatch(addRole(name, department, percent, minSalary, maxSalary, company?.id))
  }

  const Button = buttonType === 'primary' ? PrimaryButton : ActionButton

  return (
    <div className={className}>
      <Button iconProps={{ iconName: 'Add' }} onClick={handleOpenModal}>
        Add New Role
      </Button>
      <RoleModal
        isOpen={modalOpen}
        onSubmit={handleCreateRole}
        onClose={handleCloseModal}
        submitLabel="Add"
        modalTitle="Add New Role"
      />
    </div>
  )
}

AddRole.defaultProps = {
  buttonType: 'action',
}

export default AddRole
