export const selectMemberCount = (state) => {
  return state.analytics.summary.memberCount || 0
}

export const selectSubmittedUserCount = (state) => {
  return state.analytics.summary.submittedUserCount || 0
}

export const selectVerifiedUserCount = (state) => {
  return state.analytics.summary.verifiedUserCount || 0
}

export const selectTotalValue = (state) => {
  return state.analytics.summary.totalValue || 0
}

export const selectRewardSummary = (state) => {
  return state.analytics.summary.rewardSummary || []
}

export const selectDepartmentSummary = (state) => {
  return state.analytics.summary.departmentSummary || []
}

export const selectRoleSummary = (state) => {
  return state.analytics.summary.roleSummary || []
}
