import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useQuery } from "util/browser"

import { selectCompanyRewards } from "selectors/company"

import { submitFeedback } from "actions/feedback"

import { Dropdown, PrimaryButton, Slider, SpinButton, Spinner } from "@fluentui/react"
import RewardTile from "components/onboarding/RewardTile"
import BundleBuilder from "components/shared/BundleBuilder"
import { selectFeedbackSuccess } from "selectors/feedback"

import { DEPARTMENTS } from "constants/feedback"

import './Feedback.scss'

const Feedback = () => {
  const dispatch = useDispatch()
  const [step, setStep] = useState("EMPLOYEE_INFO")
  const [baseSalary, setBaseSalary] = useState(0)
  const [department, setDepartment] = useState(null)
  const [overallRating, setOverallRating] = useState(5)
  const [customizeRating, setCustomizeRating] = useState(5)
  const [topReward, setTopReward] = useState(null)

  const query = useQuery()
  const companyId = query.get("id")
  const companyRewards = useSelector(selectCompanyRewards)
  const submitFeedbackSuccess = useSelector(selectFeedbackSuccess)

  const handleBaseSalaryChange = (_ev, value) => {
    setBaseSalary(parseInt(value))
  }

  const handleValidateSalary = (value, _ev) => {
    return Number(value.replace(/[^0-9.]+/g,""))
  }

  const handleIncrementSalary = () => {
    return baseSalary + 100
  }

  const handleDecrementSalary = () => {
    return Math.max(0, baseSalary - 100)
  }

  const handleDepartmentChange = (_ev, item) => {
    setDepartment(item.key)
  }

  const handleChangeOverallRating = (value) => {
    setOverallRating(value)
  }

  const handleChangeCustomizeRating = (value) => {
    setCustomizeRating(value)
  }

  const handleClickReward = (id) => {
    setTopReward(id)
  }

  const handleSubmitInfo = () => {
    setStep("QUESTIONS")
  }

  const handleSubmitQuestions = () => {
    setStep("BUNDLE")
  }

  const handleFinalSubmit = (bundleValues) => {
    setStep("FINAL")
    dispatch(submitFeedback({
      companyId,
      bundleValues,
      baseSalary,
      department,
      overallRating,
      customizeRating,
      topReward,
    }))
  }

  const infoSubmitDisabled = baseSalary <= 0 || !department
  const questionSubmitDisabled = !topReward

  return (
    <div className="feedback">
      { step !== 'FINAL' && (
        <>
          <h1>Bundl Employee Feedback</h1>
          <p>
            You have been invited by your company to take part in a total rewards feedback questionnaire where you will be given the ability to build out your ideal total rewards package.
          </p>
          <p>
            This version is for feedback purposes only and no selections made will be executed by your employer. Please <a href="https://bundl.co" target="_blank" rel="noreferrer">click here</a> to learn more about the full Bundl experience that allows employees to build and customize their own total reward packages.
          </p>
          <p>
            <strong>Please fill in the below information to build your total reward package.</strong>
          </p>
          <p className="feedbackSubtext">
            This personal information will remain confidential and any individual selections will not be shared with your employer.
          </p>
        </>
      )}
      { step === 'EMPLOYEE_INFO' && (
        <div className="employeeInfo">
          <h3>Your Information</h3>
          <SpinButton
            className="salarySpinner"
            label="Base Salary"
            labelPosition={"top"}
            value={`$${baseSalary.toLocaleString('en-us')}`}
            min={0}
            step={100}
            onChange={handleBaseSalaryChange}
            onValidate={handleValidateSalary}
            onIncrement={handleIncrementSalary}
            onDecrement={handleDecrementSalary}
            incrementButtonAriaLabel="Increase value by 100"
            decrementButtonAriaLabel="Decrease value by 100"
          />
          <Dropdown
            label="Department"
            selectedKey={department}
            options={DEPARTMENTS}
            onChange ={handleDepartmentChange}
          />
          <PrimaryButton onClick={handleSubmitInfo} text="Get Started" disabled={infoSubmitDisabled} />
        </div>
      )}

      { step === 'QUESTIONS' && (
        <div className="questions">
          <div className="question">
            <h4>Question 1</h4>
            <p>
            On a scale of 1 to 10, how satisfied are you with the current benefits, rewards, and perks offered by your employer?
            </p>
            <Slider
              min={1}
              max={10}
              value={overallRating}
              onChange={handleChangeOverallRating}
            />
          </div>
          <div className="question">
            <h4>Question 2</h4>
            <p>
            On a scale of 1 to 10, how satisfied are you with your ability to customize your benefits, rewards, and perks to meet your specific needs?
            </p>
            <Slider
              min={1}
              max={10}
              value={customizeRating}
              onChange={handleChangeCustomizeRating}
            />
          </div>
          <div className="question">
            <h4>Question 3</h4>
            <p>
              Please select the reward that is most important to you.
            </p>
            <div className="rewards">
              {companyRewards.map((reward) => {
                const isActive = topReward === reward.id
                return (
                  <RewardTile
                    className="feedbackRewardTile"
                    key={`${reward.id}-${isActive}`}
                    id={reward.id}
                    label={reward.label}
                    onClick={handleClickReward}
                    isActive={isActive}
                  />
                )
              })}
            </div>
          </div>
          <PrimaryButton onClick={handleSubmitQuestions} text="Next" disabled={questionSubmitDisabled} />
        </div>
      )}

      { step === 'BUNDLE' && (
        <BundleBuilder
          onSubmit={handleFinalSubmit}
          baseSalary={baseSalary}
          pointPercent={25}
          companyRewards={companyRewards}
          showTotalValue={false}
        />
      )}

      { step === 'FINAL' && !submitFeedbackSuccess && <Spinner />}
      { step === 'FINAL' && submitFeedbackSuccess && (
        <div className="success">
          <div className="successMessage">
            Successfully submitted!
          </div>
          <p>
            Did you enjoy building a customized benefit package? Well now you can! Ask your employer about using Bundl for fully customized rewards that can directly change your total compensation package.
          </p>
          <p>
            Share <a href="https://bundl.co" rel="noreferrer">Bundl</a> with your peers and follow us on <a href="https://www.linkedin.com/company/bundl-customized-total-rewards/" rel="noreferrer">LinkedIn</a> to follow our latest updates.
          </p>
        </div>

      )}
    </div>
  )
}

export default Feedback
