import _ from 'lodash'

export const selectUserIsLoading = (state) => state.user.isLoading

export const selectUserIsError= (state) => state.user.isError

export const selectUserIsAdmin = (state) => state.user.role === 'admin'

export const selectUserIsMember = (state) => state.user.role === 'member'

export const selectUserIsCandidate = (state) => state.user.role === 'candidate'

export const selectUser = (state) => state.user

export const selectUserId = (state) => state.user.id

export const selectUserHasSetPassword = (state) => state.user.hasSetPassword

export const selectUserRole = (state) => {
  return state.user.companyRole
}

export const selectUserBundle = (state) => {
  return state.user.bundle
}

export const selectUserBaseSalary = (state) => {
  return state.user.baseSalary
}

export const selectUserRewardDefaults = (state) => {
  const rewardDefaults = state.user.company?.rewardDefaults || []

  if (rewardDefaults.length <= 0) {
    return []
  }

  return _.chain(rewardDefaults).keyBy('rewardId').mapValues((reward) => ({
    value: reward.value,
    visible: reward.visible,
  })).value()
}
