import { Icon, PrimaryButton } from "@fluentui/react"
import { requestDemo } from "actions/company"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { selectCompany } from "selectors/company"

import './UpgradePlan.scss'

const UpgradeTeam = () => {
  const dispatch = useDispatch()
  const company = useSelector(selectCompany)

  const handleRequest = () => {
    dispatch(requestDemo(company?.id))
  }

  return (
    <div>
      <h1>Upgrade to Bundl Standard</h1>
      <p>
        Upgrade to our standard version of Bundl to realize the full total reward customization software offered by Bundl. Customize rewards offered by role type, adjust reward allocations by individual, get deeper data insights and trends, and export employee selections to allow for individual reward customization with your HRIS. See what the standard version of Bundl can offer below.
      </p>
      <PrimaryButton className="requestDemo" onClick={handleRequest}>
        Request a Demo
      </PrimaryButton>
      <div className="planComparison">
        <div className="plan">
          <h2>Bundl Trial</h2>
          <p>
            Ideal for basic employee surveys, high level reward feedback, and insights into what employees want in a reward package.
          </p>
          <div className="features">
            <div>
              <Icon
                iconName="SkypeCheck"
              />
              Reward Selections
            </div>
            <div>
              <Icon
                iconName="SkypeCheck"
              />
              Basic Employee Point Allocation
            </div>
            <div>
              <Icon
                iconName="SkypeCheck"
              />
              Employee Invite Link
            </div>
            <div>
              <Icon
                iconName="SkypeCheck"
              />
              Basic Data Dashboard
            </div>
          </div>
        </div>
        <div className="plan">
          <h2>Bundl Standard</h2>
          <p>
            Ideal for basic employee surveys, high level reward feedback, and insights into what employees want in a reward package.
          </p>
          <div className="features">
            <div>
              <Icon
                iconName="SkypeCheck"
              />
              Custom Implementation &amp; Onboarding
            </div>
            <div>
              <Icon
                iconName="SkypeCheck"
              />
              Employer Reward Selections
            </div>
            <div>
              <Icon
                iconName="SkypeCheck"
              />
              Min and Max Reward Allocations
            </div>
            <div>
              <Icon
                iconName="SkypeCheck"
              />
              Custom Point Allocations Per Employee
            </div>
            <div>
              <Icon
                iconName="SkypeCheck"
              />
              Individual Email Invites Per Employee
            </div>
            <div>
              <Icon
                iconName="SkypeCheck"
              />
              Custom Rewards Offered Per Title/Role
            </div>
            <div>
              <Icon
                iconName="SkypeCheck"
              />
              Full Employee Directory Modifications
            </div>
            <div>
              <Icon
                iconName="SkypeCheck"
              />
              Default Reward Selections
            </div>
            <div>
              <Icon
                iconName="SkypeCheck"
              />
              Advanced Data Dashboard &amp; Reporting
            </div>
            <div>
              <Icon
                iconName="SkypeCheck"
              />
              Export Employee Selections for HRIS
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpgradeTeam
