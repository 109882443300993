import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useQuery } from 'util/browser'
import { isAuthenticated, setAuthHeader } from "util/auth"
import { selectUserId, selectUserIsAdmin, selectUserIsLoading, selectUserIsMember } from 'selectors/user'
import { getUser } from 'actions/user'
import { getCompanyRewards } from 'actions/company'

import AppLayout from './components/layout/AppLayout'
import DefaultBundleBuilder from './components/admin/DefaultBundleBuilder'
import CreateBundle from 'components/employee/CreateBundle'
import Feedback from 'components/employee/Feedback'
import EmployeeList from './components/admin/EmployeeList'
import Employee from './components/admin/Employee'
import RoleList from './components/admin/RoleList'
import InviteTeam from './components/admin/InviteTeam'
import UpgradePlan from './components/admin/UpgradePlan'
import EditRewards from './components/admin/EditRewards'
import Dashboard from './components/Dashboard'
import Login from './components/Login'
import SignUp from './components/SignUp'
import NotFound from './components/NotFound'
import LinkExpired from './components/LinkExpired'
import SetPassword from './components/SetPassword'
import OfferLetters from './components/admin/OfferLetters'
import CreateOfferLetter from './components/admin/CreateOfferLetter'
import EmployeeOfferLetter from 'components/employee/EmployeeOfferLetter'
import ViewOfferLetter from 'components/admin/ViewOfferLetter'
import ApproveOfferLetter from 'components/approver/ApproveOfferLetter'
import PreviewDashboard from 'components/dashboards/PreviewDashboard'
import BundlePreview from 'components/preview/BundlePreview'

// TODO: Find a better pattern for AppLayout
export default function App() {
  return (
    <Switch>
      <PrivateRoute exact path={['/', '/dashboard']}>
        <AdminRoute>
          <AppLayout>
            <Dashboard />
          </AppLayout>
        </AdminRoute>
      </PrivateRoute>
      <PrivateRoute path='/invite'>
        <AdminRoute>
          <AppLayout>
            <InviteTeam />
          </AppLayout>
        </AdminRoute>
      </PrivateRoute>
      <PrivateRoute path='/upgrade'>
        <AdminRoute>
          <AppLayout>
            <UpgradePlan />
          </AppLayout>
        </AdminRoute>
      </PrivateRoute>
      <PrivateRoute path='/rewards'>
        <AdminRoute>
          <AppLayout>
            <EditRewards />
          </AppLayout>
        </AdminRoute>
      </PrivateRoute>
      <PrivateRoute path='/offers/new'>
        <AdminRoute>
          <AppLayout>
            <CreateOfferLetter />
          </AppLayout>
        </AdminRoute>
      </PrivateRoute>
      <PrivateRoute path='/offers'>
        <AdminRoute>
          <AppLayout>
            <OfferLetters />
          </AppLayout>
        </AdminRoute>
      </PrivateRoute>
      <PrivateRoute path='/offer/:id'>
        <AdminRoute>
          <AppLayout>
            <ViewOfferLetter />
          </AppLayout>
        </AdminRoute>
      </PrivateRoute>
      <PrivateRoute path='/employees'>
        <AdminRoute>
          <AppLayout>
            <EmployeeList />
          </AppLayout>
        </AdminRoute>
      </PrivateRoute>
      <PrivateRoute path='/roles'>
        <AdminRoute>
          <AppLayout>
            <RoleList />
          </AppLayout>
        </AdminRoute>
      </PrivateRoute>
      <PrivateRoute path='/role/:id'>
        <AdminRoute>
          <AppLayout>
            <DefaultBundleBuilder />
          </AppLayout>
        </AdminRoute>
      </PrivateRoute>
      <PrivateRoute path='/employee/:id'>
        <AdminRoute>
          <AppLayout>
            <Employee />
          </AppLayout>
        </AdminRoute>
      </PrivateRoute>
      <AuthenticatedLinkRoute path='/approve-offer/:id'>
        <PrivateRoute path='/approve-offer/:id'>
          <AppLayout>
            <ApproveOfferLetter />
          </AppLayout>
        </PrivateRoute>
      </AuthenticatedLinkRoute>
      <AuthenticatedLinkRoute path='/offer'>
        <PrivateRoute>
          <AppLayout>
            <EmployeeOfferLetter />
          </AppLayout>
        </PrivateRoute>
      </AuthenticatedLinkRoute>
      <AuthenticatedLinkRoute path='/create-bundl'>
        <PrivateRoute>
          <AppLayout>
            <CreateBundle />
          </AppLayout>
        </PrivateRoute>
      </AuthenticatedLinkRoute>
      <AuthenticatedLinkRoute path='/set-password'>
        <PrivateRoute>
          <AppLayout>
            <SetPassword />
          </AppLayout>
        </PrivateRoute>
      </AuthenticatedLinkRoute>
      <CompanyRoute path='/feedback'>
        <AppLayout>
          <Feedback />
        </AppLayout>
      </CompanyRoute>
      <Route path='/preview/:id'>
        <AppLayout>
          <BundlePreview />
        </AppLayout>
      </Route>
      <Route path='/preview'>
        <AppLayout>
          <PreviewDashboard />
        </AppLayout>
      </Route>
      <Route path='/login'>
        <AppLayout>
          <Login />
        </AppLayout>
      </Route>
      <Route path='/sign-up'>
        <AppLayout>
          <SignUp />
        </AppLayout>
      </Route>
      <Route path='/link-expired'>
        <AppLayout>
          <LinkExpired />
        </AppLayout>
      </Route>
      <Route>
        <AppLayout>
          <NotFound />
        </AppLayout>
      </Route>
    </Switch>
  )
}


function CompanyRoute({ children, ...rest }) {
  const dispatch = useDispatch()
  const query = useQuery()
  const companyId = query.get("id")

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyRewards(companyId))
    }
  }, [dispatch, companyId])

  if (!companyId) { return null }

  return (
    <Route
      {...rest}
      render={() => ( children )}
    />
  )
}

function AuthenticatedLinkRoute({ children, path, ...rest }) {
  const query = useQuery()
  const token = query.get("token")
  // const parsed = parseJwt(token)
  // let email, userId

  if (token) {
    setAuthHeader(token)
    // email = parsed.data.email
    // userId = parsed.data.user_id
    query.delete("token")
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return (
          token ? (
            <Redirect to={{
              pathname: location.pathname,
              search: query.toString(),
              // state: { userId: userId, email }
            }}
          />
          ) : (
            children
          )
        )
      }
      }
    />
  )
}

function PrivateRoute({ children, ...rest }) {
  const dispatch = useDispatch()
  const isUserAuthenticated = isAuthenticated()
  const location = useLocation()

  useEffect(() => {
    if (isUserAuthenticated) {
      dispatch(getUser())
    }
  }, [dispatch, isUserAuthenticated])

  const Redirection = location.pathname === '/create-bundl' ?
    <Redirect to={{ pathname: '/link-expired' }} /> :
    <Redirect to={{
        pathname: '/login',
        state: { from: location }
      }}
    />

  return (
    <Route
      {...rest}
      render={() =>
        isUserAuthenticated ? (
          children
        ) : Redirection
      }
    />
  )
}

function AdminRoute({ children, ...rest }) {
  const loading = useSelector(selectUserIsLoading)
  const isUserAdmin = useSelector(selectUserIsAdmin)
  const isUserMember = useSelector(selectUserIsMember)
  const userId = useSelector(selectUserId)

  if (loading || !userId || (!isUserAdmin && !isUserMember)) { return null }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isUserAdmin ? (
          children
        ) : (
          <Redirect to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}
