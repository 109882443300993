import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isEmpty } from "lodash"

import { selectCompany, selectCompanyRoles } from 'selectors/company'
import {
  setDefaultBundle,
  setDefaultBundleError,
  getCompanyRoles,
  getCompanyRewards,
} from "actions/company"

import { SpinButton } from '@fluentui/react'
import BundleBuilder from 'components/shared/BundleBuilder'

import './DefaultBundleBuilder.scss'
import BackButton from 'components/shared/BackButton'
import { MAX_SALARY } from 'constants/roles'


const DefaultBundleBuilder = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const companyId = useSelector(selectCompany)?.id
  const roles = useSelector(selectCompanyRoles)
  const role = roles.find(role => role.id === id)

  const [currentRole, setCurrentRole] = useState(role)
  const pointPercent = currentRole?.pointPercent || 0
  const defaultMinSalary = currentRole?.defaultMinSalary || 0
  const minSalary = currentRole?.minSalary || defaultMinSalary
  const maxSalary = currentRole?.maxSalary || minSalary

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatch(getCompanyRewards(companyId))
      dispatch(getCompanyRoles(companyId))
    }
  }, [dispatch, companyId, id])

  useEffect(() => {
    if (!currentRole) {
      setCurrentRole(role)
    }
  }, [role, currentRole])

  const handlePointChange = (_ev, value) => {
    setCurrentRole({ ...currentRole, pointPercent: value })
  }

  const handleMinSalaryChange = (_ev, value) => {
    const cappedMaximum = defaultMinSalary > 0 ? Math.min(value, defaultMinSalary) : value
    setCurrentRole({ ...currentRole, minSalary: cappedMaximum })
  }

  const handleMaxSalaryChange = (_ev, value) => {
    const cappedMinimum = Math.max(minSalary, value)
    setCurrentRole({ ...currentRole, maxSalary: cappedMinimum })
  }

  const handleValidateSalary = (value, _ev) => {
    return Number(value.replace(/[^0-9.]+/g,""))
  }

  const handleIncrementMinSalary = () => {
    if (minSalary + 100 <= MAX_SALARY) {
      return minSalary + 100
    }
  }

  const handleDecrementMinSalary = () => {
    if (minSalary - 100 > 0) {
      return minSalary - 100
    }
  }

  const handleIncrementMaxSalary = () => {
    if (minSalary + 100 <= MAX_SALARY) {
      return minSalary + 100
    }
  }

  const handleDecrementMaxSalary = () => {
    if (minSalary - 100 > 0) {
      return minSalary - 100
    }
  }

  const handleSubmit = (values, pointsRemaining) => {
    if (pointsRemaining >= 0) {
      dispatch(setDefaultBundle({
        id: companyId,
        roleId: currentRole.id,
        rolePercent: pointPercent,
        roleMinSalary: minSalary,
        roleMaxSalary: maxSalary,
        rewardValues: values,
      }))
    } else {
      dispatch(setDefaultBundleError(
        `Update failed.  Not enough points allocated (${pointsRemaining*-1} needed)`
      ))
    }
  }

  const shouldShowBuilder = pointPercent > 0 && minSalary > 1 && (minSalary <= defaultMinSalary || defaultMinSalary === 0)

  return (
    <>
      <BackButton />
      <div className="defaultBundleBuilderPage">
        <h1>Set Default Bundl</h1>

        { currentRole &&
          <div className="roleBox">
            <h4>{currentRole.name}</h4>
            <SpinButton
              className="pointSpinner"
              label="Point Allocation %"
              value={pointPercent}
              onChange={handlePointChange}
              min={0}
              max={100}
              step={1}
              incrementButtonAriaLabel="Increase value by 1"
              decrementButtonAriaLabel="Decrease value by 1"
            />
            <>
              <SpinButton
                className="salarySpinner"
                label="Min Salary"
                value={`$${minSalary?.toLocaleString('en-us')}`}
                onChange={handleMinSalaryChange}
                onIncrement={handleIncrementMinSalary}
                onDecrement={handleDecrementMinSalary}
                onValidate={handleValidateSalary}
                min={1}
                max={defaultMinSalary}
                step={100}
                incrementButtonAriaLabel="Increase value by 100"
                decrementButtonAriaLabel="Decrease value by 100"
              />
              { currentRole.defaultMinSalary && <p className="spinnerSubtext">
                                                    Min salary must be less than or equal to the minimum salary, which is
                                                    ${currentRole.defaultMinSalary.toLocaleString('en-us')}
                                                  </p>
              }
            </>
            <>
              <SpinButton
                className="salarySpinner"
                label="Max Salary"
                value={`$${maxSalary?.toLocaleString('en-us')}`}
                onChange={handleMaxSalaryChange}
                onIncrement={handleIncrementMaxSalary}
                onDecrement={handleDecrementMaxSalary}
                onValidate={handleValidateSalary}
                min={minSalary}
                step={100}
                incrementButtonAriaLabel="Increase value by 100"
                decrementButtonAriaLabel="Decrease value by 100"
              />
              { currentRole.minSalary && <p className="spinnerSubtext">
                                                    Max salary must be greater than or equal to the minimum salary, which is
                                                    ${minSalary.toLocaleString('en-us')}
                                                  </p>
              }
            </>
          </div>
        }


        { shouldShowBuilder ? <BundleBuilder
                                className="defaultBundleBuilder"
                                showEditDisableMessage
                                showTotalValue={false}
                                onSubmit={handleSubmit}
                                disabled={!currentRole}
                                baseSalary={minSalary}
                                pointPercent={pointPercent}
                                rewardDefaults={currentRole?.rewardDefaults}
                                visibilityToggle
                                includeHidden
                              /> : (
                                <div className="noRole">
                                  { (pointPercent <= 0 || minSalary <= 0) &&
                                    <h3>
                                      Select a point allocation and salary range to get started!
                                    </h3>
                                  }
                                  { (minSalary > defaultMinSalary) && currentRole?.defaultMinSalary &&
                                    <h3>
                                      Please decrease the base salary to be less than or equal the minimum salary of
                                      ${currentRole.defaultMinSalary.toLocaleString('en-us')}
                                    </h3>
                                  }
                                </div>
                              )
        }
      </div>
    </>
  )
}

export default DefaultBundleBuilder
