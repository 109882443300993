import {
  CREATE_BUNDLE_REQUESTED,
  CREATE_BUNDLE_SUCCESS,
  CREATE_BUNDLE_FAILURE,
  VERIFY_BUNDLE_REQUESTED,
  VERIFY_BUNDLE_SUCCESS,
  VERIFY_BUNDLE_FAILURE,
} from 'constants/actions'

import {
  createBundle as createBundleRequest,
  verifyBundle as verifyBundleRequest,
} from 'api/requests'

export const createBundle = (userId, config, fourDayEnabled = false, notes) => async (dispatch) => {
  dispatch({
    type: CREATE_BUNDLE_REQUESTED,
  })

  try {
    const response = await createBundleRequest(userId, config, fourDayEnabled, notes)

    const responseData = response.data?.data
    if (responseData) {
      dispatch({
        type: CREATE_BUNDLE_SUCCESS,
        data: {
          id: responseData.id,
          ...responseData?.attributes,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: CREATE_BUNDLE_FAILURE
    })
  }
}

export const verifyBundle = (id) => async (dispatch) => {
  dispatch({
    type: VERIFY_BUNDLE_REQUESTED,
  })

  try {
    const response = await verifyBundleRequest(id)

    const responseData = response.data?.data
    if (responseData) {
      dispatch({
        type: VERIFY_BUNDLE_SUCCESS,
        data: {
          id: responseData.id,
          ...responseData?.attributes,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: VERIFY_BUNDLE_FAILURE
    })
  }
}
