import { useState } from "react"
import { useDispatch } from "react-redux"

import {
  ActionButton,
} from "@fluentui/react"

import EmployeeModal from "./EmployeeModal"

import './EditOfferLetter.scss'
import { updateOfferLetter } from "actions/offerLetters"

const EditOfferLetter = ({ id, firstName, lastName, email, baseSalary, roleId }) => {
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleEditOffer = ({ firstName, lastName, email, baseSalary, selectedRoleId }) => {
    handleCloseModal()
    dispatch(updateOfferLetter({ offerId: id, firstName, lastName, email, baseSalary, companyRoleId: selectedRoleId }))
  }

  return (
    <>
      <ActionButton
        className="editPencil"
        iconProps= {{ iconName: "Edit" }}
        onClick={handleOpenModal}
      >
        Edit
      </ActionButton>
      { modalOpen &&
        <EmployeeModal
          modalTitle='Edit Offer Letter'
          submitLabel='Submit'
          isOpen={modalOpen}
          onClose={handleCloseModal}
          onSubmit={handleEditOffer}
          employeeFirstName={firstName}
          employeeLastName={lastName}
          employeeEmail={email}
          employeeBaseSalary={baseSalary}
          employeeRoleId={roleId}
          showEmail
        />
      }
    </>
  )
}

export default EditOfferLetter
