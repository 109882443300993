import { useState } from "react"
import isNumber from 'lodash/isNumber'

import {
  PrimaryButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  TextField,
  SpinButton,
  Toggle,
} from "@fluentui/react"

import './RoleModal.scss'
import { MAX_SALARY } from "constants/roles"

const RoleModal = ({
  isOpen,
  roleName,
  rolePercent,
  roleDefaultMinSalary,
  roleMinSalary,
  roleMaxSalary,
  roleDepartment,
  roleFourDayRatio,
  modalTitle,
  onSubmit,
  onClose,
  submitLabel,
}) => {
  const [name, setName] = useState(roleName)
  const [percent, setPercent] = useState(rolePercent)
  const [department, setDepartment] = useState(roleDepartment)
  const [minSalary, setMinSalary] = useState(roleMinSalary || roleDefaultMinSalary || 0)
  const [maxSalary, setMaxSalary] = useState(roleMaxSalary || minSalary || 0)
  const [fourDayRatio, setFourDayRatio] = useState(roleFourDayRatio || 0)
  const [fourDayEnabled, setFourDayEnabled] = useState(isNumber(roleFourDayRatio))

  const handleCloseModal = () => {
    setName("")
    setPercent(0)
    setMinSalary(0)
    setMaxSalary(0)
    setFourDayRatio(0)
    setFourDayEnabled(false)
    setDepartment("")
    onClose()
  }

  const handlePointChange = (_ev, value) => {
    setPercent(value)
  }

  const handleNameChange = (_ev, value) => {
    setName(value)
  }

  const handleDepartmentChange = (_ev, value) => {
    setDepartment(value)
  }

  const handleMinSalaryChange = (_ev, value) => {
    const cappedMaximum = roleDefaultMinSalary > 0 ? Math.min(value, roleDefaultMinSalary) : value
    setMinSalary(cappedMaximum)
  }

  const handleMaxSalaryChange = (_ev, value) => {
    const cappedMinimum = Math.max(minSalary, value)
    setMaxSalary(cappedMinimum)
  }

  const handleFourDayChange = (_ev, value) => {
    const updatedValue = value / 100
    setFourDayRatio(updatedValue)
  }

  const handleFourDayToggle = (_ev, enabled) => {
    setFourDayEnabled(enabled)
  }

  const handleValidateSalary = (value, _ev) => {
    return Number(value.replace(/[^0-9.]+/g,""))
  }

  const handleIncrementMinSalary = () => {
    if (minSalary + 100 <= MAX_SALARY) {
      return minSalary + 100
    }
  }

  const handleDecrementMinSalary = () => {
    if (minSalary - 100 > 0) {
      return minSalary - 100
    }
  }

  const handleIncrementMaxSalary = () => {
    if (minSalary + 100 <= MAX_SALARY) {
      return minSalary + 100
    }
  }

  const handleDecrementMaxSalary = () => {
    if (minSalary - 100 > 0) {
      return minSalary - 100
    }
  }

  const handleSubmit = () => {
    const fourDayValue = fourDayEnabled ? fourDayRatio : null
    onSubmit({ name, department, percent, minSalary, maxSalary, fourDayRatio: fourDayValue })
  }

  const dialogContentProps = {
    title: modalTitle,
    closeButtonAriaLabel: 'Close',
    subText: 'Name must not match any existing roles',
  }

  const disabled = !name || !department || (maxSalary < minSalary) || (roleDefaultMinSalary && minSalary > roleDefaultMinSalary) || minSalary <= 0 || maxSalary <= 0

  return (
    <Dialog
      className="roleModal"
      hidden={!isOpen}
      onDismiss={handleCloseModal}
      dialogContentProps={dialogContentProps}
    >
      <TextField label="Role Name/Title" value={name} onChange ={handleNameChange} />
      <TextField label="Department" value={department} onChange ={handleDepartmentChange} />
      <SpinButton
        className="pointSpinner"
        label="Point Allocation %"
        value={percent}
        onChange={handlePointChange}
        min={0}
        max={100}
        step={1}
        incrementButtonAriaLabel="Increase value by 1"
        decrementButtonAriaLabel="Decrease value by 1"
      />
      <>
        <SpinButton
          className="salarySpinner"
          label="Min Salary"
          labelPosition="top"
          value={`$${minSalary?.toLocaleString('en-us')}`}
          onChange={handleMinSalaryChange}
          onIncrement={handleIncrementMinSalary}
          onDecrement={handleDecrementMinSalary}
          onValidate={handleValidateSalary}
          min={1}
          max={roleDefaultMinSalary}
          step={100}
          incrementButtonAriaLabel="Increase value by 100"
          decrementButtonAriaLabel="Decrease value by 100"
        />
        { !!roleDefaultMinSalary && <p className="spinnerSubtext">
                                      Min salary must be less than or equal to the minimum salary, which is
                                      ${roleDefaultMinSalary.toLocaleString('en-us')}
                                    </p>
        }
      </>
      <>
        <SpinButton
          className="salarySpinner"
          label="Max Salary"
          labelPosition="top"
          value={`$${maxSalary?.toLocaleString('en-us')}`}
          onChange={handleMaxSalaryChange}
          onIncrement={handleIncrementMaxSalary}
          onDecrement={handleDecrementMaxSalary}
          onValidate={handleValidateSalary}
          min={minSalary}
          step={100}
          incrementButtonAriaLabel="Increase value by 100"
          decrementButtonAriaLabel="Decrease value by 100"
        />
        { !!minSalary && <p className="spinnerSubtext">
                          Max salary must be greater than or equal to the minimum salary, which is
                          ${minSalary.toLocaleString('en-us')}
                        </p>
        }
        <div className="fourDay">
          <Toggle label="Enable?" checked={fourDayEnabled} onChange={handleFourDayToggle} onText="On" offText="Off" />
          <SpinButton
            label="4 Day Week %"
            value={Math.round(fourDayRatio * 100)}
            onChange={handleFourDayChange}
            min={0}
            max={100}
            step={1}
            disabled={!fourDayEnabled}
            incrementButtonAriaLabel="Increase value by 1"
            decrementButtonAriaLabel="Decrease value by 1"
          />
        </div>
        <p className="spinnerSubtext">
          If turned on, any amount over 0% will be the percent deducted from the base salary and applicant's total compensation balance in exchange for a 4 day workweek
        </p>
      </>
      <DialogFooter>
        <PrimaryButton disabled={disabled} onClick={handleSubmit} text={submitLabel} />
        <DefaultButton onClick={handleCloseModal} text="Cancel" />
      </DialogFooter>
    </Dialog>
  )
}

RoleModal.defaultProps = {
  roleName: "",
  rolePercent: 0,
  roleDefaultMinSalary: 0,
  roleMinSalary: 0,
  roleMaxSalary: 0,
  roleFourDayRatio: null,
  roleDepartment: "",
  isOpen: false,
  submitLabel: 'Submit',
  modalTitle: 'Role',
  onSubmit: () => {},
  onClose: () => {},
}

export default RoleModal
