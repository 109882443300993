import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { TextField, ChoiceGroup, Dropdown, Slider } from "@fluentui/react"
import classNames from "classnames"
import { useSelector } from "react-redux"
import { useState } from "react"

import { CopyToClipboard } from 'react-copy-to-clipboard'
import StatCard from "components/shared/StatCard"
import TopRewards from 'components/admin/TopRewards'

import { selectCompany, selectCompanyRewards } from "selectors/company"
import { selectAverageCustomizeRating, selectAverageOverallRating, selectDepartmentSummary,
  selectRewardSummary,
  selectSubmittedUserCount,
  selectTopRewards,
  selectTotalValue,
} from "selectors/feedback"

import './InviteTeam.scss'
import { fetchFeedbackSummary } from "actions/feedback"
import { getCompanyRewards } from "actions/company"

import { DEPARTMENTS } from "constants/feedback"

const sortOptions = [
  { key: 'all', text: 'All' },
  { key: 'department', text: 'Department' }
]

const InviteTeam = () => {
  const dispatch = useDispatch()
  const [copied, setCopied] = useState(false)
  const [rewardDepartment, setRewardDepartment] = useState(null)
  const [sortType, setSortType] = useState('all')
  let rewardData

  const company = useSelector(selectCompany)
  const submittedCount = useSelector(selectSubmittedUserCount)
  const totalValue = useSelector(selectTotalValue)
  const rewardSummary = useSelector(selectRewardSummary)
  const departmentSummary = useSelector(selectDepartmentSummary)
  const overallRating = parseFloat(useSelector(selectAverageOverallRating)).toFixed(2)
  const customizeRating = parseFloat(useSelector(selectAverageCustomizeRating)).toFixed(2)
  const companyRewards = useSelector(selectCompanyRewards)
  const topRewards = useSelector(selectTopRewards)

  const inviteLink = `${process.env.REACT_APP_BASE_URL}/feedback?id=${company?.id}`

  useEffect(() => {
    dispatch(fetchFeedbackSummary(company?.id))
    dispatch(getCompanyRewards(company?.id))
  }, [company?.id, dispatch])

  const handleCopy = () => {
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }

  const handleClick = (e) => {
    e.target.select();
  }

  const handleChangeDepartment = (_ev, value) => {
    setRewardDepartment(value)
  }

  const handleChangeSort = (_ev, value) => {
    setSortType(value.key)
    setRewardDepartment(null)
  }

  const selectedDepartment = sortType === 'department' && rewardDepartment ? departmentSummary.find(dept => dept.id === rewardDepartment.key) : null

  if (selectedDepartment) {
    rewardData = selectedDepartment.summary
  } else if (rewardDepartment) {
    rewardData = null
  } else {
    rewardData = rewardSummary
  }

  return (
    <div className="inviteTeam">
      <h1>Invite Your Team</h1>
      <p>
        Invite your team to take survey questions and build their preferred Bundl.  Please note that no selections are confirmed as final and will only be used for feedback purposes.
      </p>
      <p>
        Send this invite link to your employees via email, messenger, etc. to let them fill out their Bundl feedback tool.
      </p>
      <div className="inviteLink">
        <CopyToClipboard text={inviteLink} onCopy={handleCopy}>
           <TextField
             label="Invite Link"
             iconProps={{ iconName: 'Copy', className: 'inviteCopy' }}
             value={inviteLink}
             readOnly
             onClick={handleClick}
           />
         </CopyToClipboard>
        <span className={classNames('inviteCopied', { 'fadeIn': copied })}>Copied!</span>
      </div>
      <div className="inviteResponses">
        <div className="statCards">
          <StatCard
            value={submittedCount}
            title="TOTAL SUBMISSIONS"
          />
          <StatCard
            value={`$${totalValue.toLocaleString('en-us')}`}
            title="TOTAL REWARD VALUE"
          />
        </div>
        { submittedCount > 0 && rewardSummary.length > 0 ?
          <div>
            <div className="statTable">
              <div className="statTitle">Top Rewards</div>
              <div className="statSort">
                <ChoiceGroup
                  className="statSortSelect"
                  options={sortOptions}
                  label='Sort by'
                  selectedKey={sortType}
                  onChange={handleChangeSort}
                />
                { sortType === 'department' && <Dropdown
                                                  className="statSortOption"
                                                  label="Sort by department"
                                                  options={DEPARTMENTS}
                                                  onChange={handleChangeDepartment}
                                                  value={rewardDepartment}
                                                />
                }
              </div>
              { rewardData && <TopRewards rewardSummary={rewardData} /> }
            </div>
          </div>: null
        }
        { submittedCount > 0 && (
          <div className="statTable">
            <div className="statTitle">Question Results</div>
            <div className="question">
              <h4>Question 1</h4>
              <p>
              On a scale of 1 to 10, how satisfied are you with the current benefits, rewards, and perks offered by your employer?
              </p>
              <Slider
                min={1}
                max={10}
                value={overallRating}
                readOnly
              />
            </div>
            <div className="question">
              <h4>Question 2</h4>
              <p>
              On a scale of 1 to 10, how satisfied are you with your ability to customize your benefits, rewards, and perks to meet your specific needs?
              </p>
              <Slider
                min={1}
                max={10}
                value={customizeRating}
                readOnly
              />
            </div>
            <div className="question">
              <h4>Question 3</h4>
              <p>
                Please select the reward that is most important to you.
              </p>
              <div className="rewards">
                {companyRewards.map((reward) => {
                  const topRewardValue = topRewards[reward.id] || 0
                  const topRewardPercent = (topRewardValue * 100 / submittedCount).toFixed(0)

                  return (
                    <StatCard
                      title={`${topRewardPercent}%`}
                      value={reward.label}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default InviteTeam
