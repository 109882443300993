import { Icon } from '@fluentui/react'
import { Slider, Tooltip } from '@material-ui/core'

import RewardCallout from './RewardCallout'

import classnames from 'classnames'

import './Reward.scss'

const MarkLabel = (
  <div className="defaultMarker">
  </div>
)

const ValueLabel = ({ value, children, open, disabled }) => {
  return (
    <Tooltip
      classes={{
        tooltip: classnames('rewardTooltip', { 'rewardDisabled': disabled }),
        arrow: classnames('rewardTooltipArrow', { 'rewardDisabled': disabled }),
      }}
      open={open}
      placement='top'
      title={value}
      arrow
      enterTouchDelay={0}
      PopperProps={{
        popperOptions: {
          modifiers: {
            flip: { enabled: false },
          },
        },
        disablePortal: true,
      }}
    >
      {children}
    </Tooltip>
  )
}

const Reward = ({
  id,
  name,
  value,
  valueType,
  min,
  max,
  step,
  disabled,
  locked,
  onChange,
  onLock,
  defaultValue,
  readOnly,
  description,
  notes,
  vendorUrl,
  vendorName,
  visibilityToggle,
  visible,
  onToggleVisibility
 }) => {
  const handleChange = (ev, newValue) => {
    return onChange(id, newValue)
  }

  const handleLock = () => {
    return onLock(id)
  }

  const handleToggleVisibility = () => {
    return onToggleVisibility(id)
  }

  const marks = defaultValue ? [{ value: defaultValue, label: MarkLabel }] : null

  const handleFormatLabel = (value) => {
    const isPlural = value > 1
    switch(valueType) {
      case 'dollar':
        return `$${value}`
      case 'percent':
        return `${value}%`
      case 'number':
        return value
      case 'day':
        return isPlural ? `${value} days` : `${value} day`
      case 'share':
        return isPlural ? `${value} shares` : `${value} share`
      default:
        return value
    }
  }

  const isDisabled = disabled || !visible

  return (
    <div className="reward" key={id} id={id}>
      <div className="infoNameWrapper">
        <div className="infoWrapper">
          { description && <RewardCallout
                              id={id}
                              description={description}
                              notes={notes}
                              vendorName={vendorName}
                              vendorUrl={vendorUrl}
                            />
          }
        </div>
        <div className="name">
          {name}
        </div>
      </div>
      <div className="sliderWrapper">
        { visibilityToggle && (
            <div className={classnames('lock', {'disabled': isDisabled })} onClick={handleToggleVisibility}>
              { visible ? 
                <Icon className="lockIcon" iconName="RedEye" /> :
                <Icon className="lockIcon" iconName="Hide" />
              }
            </div>
          )
        }
        <div className={classnames('lock', {'disabled': isDisabled })} onClick={handleLock} id={`${id}-lock`}>
          { locked ? 
            <Icon className="lockIcon" iconName="LockSolid" /> :
            <Icon className="lockIcon" iconName="UnlockSolid" />
          }
        </div>
        <div className="slider">
          <Slider
            disabled={isDisabled}
            min={min}
            max={max || value}
            step={step}
            value={value}
            marks={!readOnly && marks}
            onChange={handleChange}
            valueLabelDisplay="on"
            valueLabelFormat={handleFormatLabel}
            ValueLabelComponent={ValueLabel}
          />
        </div>
      </div>
    </div>
  )
}

Reward.defaultProps = {
  readOnly: false,
  onToggleVisibility: () => {},
}

export default Reward
