import {
  SELECT_REWARDS,
} from 'constants/actions'

const initialState = {
  rewards: {
    selections: [],
    customSelections: [],
  },
}

const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SELECT_REWARDS:
      return {
        ...state,
        rewards: {
          selections: data.selections,
          customSelections: data.customSelections,
        },
      }
    default:
      return state
  }
}

export default reducer
