import { useEffect, useState } from "react"
import { Modal, TextField } from "@fluentui/react"
import find from "lodash/find"
import isNumber from "lodash/isNumber"

import BundleBuilder from "components/shared/BundleBuilder"

import { REWARDS, REWARD_DEFAULTS } from "constants/preview"

import './BundlePreview.scss'
import { useSelector } from "react-redux"
import { selectPreviewBundle, selectPreviewRewards, selectPreviewSalary } from "selectors/preview"
import { filter, includes } from "lodash"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useDispatch } from "react-redux"
import { createBundlePreview, fetchBundlePreview } from "actions/preview"
import { Link, useParams } from "react-router-dom"
import { generateRewardMapping } from "util/rewards"
import BundleSelections from "components/shared/BundleSelections"

const POINT_PERCENT = 20

const BundlePreview = () => {
  const dispatch = useDispatch()
  const { id: bundlePreviewId } = useParams()

  const [modalOpen, setModalOpen] = useState(false)
  const bundlePreview = useSelector(selectPreviewBundle)
  let baseSalary = useSelector(selectPreviewSalary)
  const rewardSelections = useSelector(selectPreviewRewards) || []
  const rewardsToPreview = filter(REWARDS, (reward) => {
    return includes(rewardSelections, reward.id)
  })

  const bundleConfig = bundlePreview?.config || null
  const parsed = bundleConfig || []

  if (bundlePreviewId && bundlePreview) {
    baseSalary = find(parsed, {id: "BASE_SALARY"})["value"]
  }

  const fourDayEnabled = bundlePreview?.fourDayEnabled || false
  const parsedValues = parsed.reduce((obj, item) => (obj[item.id] = item.value, obj), {}) //eslint-disable-line
  const parsedKeys = Object.keys(parsedValues) || []
  const filteredRewards = REWARDS.filter((reward) => parsedKeys.includes(reward.id))
  const mappedRewards = generateRewardMapping(filteredRewards, parsedValues, baseSalary, true)
  const chartData = parsed.map((item) => {
    return {
      id: item.name,
      value: item.computedValue,
    }
  })

  useEffect(() => {
    dispatch(fetchBundlePreview(bundlePreviewId))
  }, [bundlePreviewId, dispatch])

  const handleSubmit = (values, _pointsRemaining, fourDayEnabled) => {
    dispatch(createBundlePreview(values, fourDayEnabled))
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleFocus = (ev) => ev.target.select()

  const bundlePreviewLink = bundlePreview ? `${process.env.REACT_APP_BASE_URL}/preview/${bundlePreview.id}` : ""

  const totalPointValue = chartData?.map(items => items.value).reduce((accumulator, current) => accumulator + current, 0)
  const displayedTotalPointValue = isNumber(baseSalary) ? totalPointValue - baseSalary : totalPointValue
  const displayedBaseSalary = baseSalary

  return (
    <>
      <div className="createBundle">
        <h1>Build Your Bundl</h1>
        <p>
          { !bundlePreviewId ? (<span>Wonderful! We have your salary and your desired rewards, now it’s time to use your allocated point balance to build your total rewards package. Follow the steps:</span>) :
            <span>Check out this Bundl someone created!  Want to make your own?  Get started <Link to="/preview">here!</Link></span> }
        </p>
        { baseSalary > 0 && !bundlePreviewId && (
          <BundleBuilder
            previewMode
            includeBase
            includeTutorial
            onSubmit={handleSubmit}
            baseSalary={baseSalary}
            minSalary={Math.round(baseSalary / 2)}
            maxSalary={Math.round(baseSalary * 2)}
            pointPercent={POINT_PERCENT}
            fourDayPercentage={0.15}
            rewardDefaults={REWARD_DEFAULTS}
            companyRewards={rewardsToPreview}
          />
        )}
        {
          bundlePreviewId && baseSalary && (
            <div className="bundlePreview">
              { baseSalary && chartData && mappedRewards ?
                <>
                  <h2>Bundl Preview</h2>
                  <div className="totalCompensation">
                      ${baseSalary?.toLocaleString('en-us')} + ${displayedTotalPointValue?.toLocaleString('en-us')} = ${(displayedBaseSalary + displayedTotalPointValue).toLocaleString('en-us')}
                      {' '}TOTAL COMPENSATION
                  </div>
                  {
                    fourDayEnabled && (
                      <div className="fourDayEnabled">
                        User opted for 4 day work week
                      </div>
                    )
                  }
                  <BundleSelections
                    chartData={chartData}
                    sliderData={mappedRewards}
                    readOnly
                  />
                </> : null
              }
            </div>
          )
        }
      </div>
      <Modal
        isOpen={modalOpen}
        onDismiss={handleModalClose}
      >
        <p className="previewModalText">
          Congrats! You have used Bundl to build your dream compensation package! Interested in having this at your workplace? Share your results with your colleagues or Human Resources Department, or feel free to share your results on social media.
        </p>
        <div className="previewCopy">
          <CopyToClipboard text={bundlePreviewLink}>
            <TextField readOnly iconProps={{ iconName: 'Link' }} value={bundlePreviewLink} onFocus={handleFocus}></TextField>
          </CopyToClipboard>
        </div>
        <p className="previewModalText">
          If you want to learn more, feel free to <a href="https://bundl.co/">Learn More</a> or <a href="https://bundl.co/#get-notified">Book a Demo</a>
        </p>
      </Modal>
    </>
  )
}

export default BundlePreview
