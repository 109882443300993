import { CREATE_OFFER_LETTER_SUCCESS,
  FETCH_OFFER_LETTERS_FAILURE,
  FETCH_OFFER_LETTERS_SUCCESS,
  FETCH_OFFER_LETTER_SUCCESS,
  FETCH_OFFER_LETTER_APPROVERS_SUCCESS,
  CREATE_DEFAULT_OFFER_APPROVER_SUCCESS,
  DELETE_DEFAULT_OFFER_APPROVER_SUCCESS,
  CREATE_OFFER_APPROVER_SUCCESS,
  DELETE_OFFER_APPROVER_SUCCESS,
  UPDATE_OFFER_LETTER_SUCCESS,
  APPROVE_OFFER_SUCCESS,
  REJECT_OFFER_SUCCESS,
  ARCHIVE_OFFER_SUCCESS,
  UNARCHIVE_OFFER_SUCCESS,
} from 'constants/actions'

const initialState = {
  isLoading: false,
  isError: false,
  offerLetters: [],
  approvers: [],
  currentOfferLetter: {},
}

const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case FETCH_OFFER_LETTER_SUCCESS:
    case CREATE_OFFER_APPROVER_SUCCESS:
    case DELETE_OFFER_APPROVER_SUCCESS:
    case UPDATE_OFFER_LETTER_SUCCESS:
    case APPROVE_OFFER_SUCCESS:
    case REJECT_OFFER_SUCCESS:
    case ARCHIVE_OFFER_SUCCESS:
    case UNARCHIVE_OFFER_SUCCESS:
      return {
        ...state,
        currentOfferLetter: data?.currentOfferLetter,
      }
    case FETCH_OFFER_LETTERS_SUCCESS:
    case CREATE_OFFER_LETTER_SUCCESS:
      return {
        ...state,
        offerLetters: data?.offerLetters,
      }
    case FETCH_OFFER_LETTER_APPROVERS_SUCCESS:
    case CREATE_DEFAULT_OFFER_APPROVER_SUCCESS:
    case DELETE_DEFAULT_OFFER_APPROVER_SUCCESS:
      return {
        ...state,
        approvers: data?.approvers,
      }
    case FETCH_OFFER_LETTERS_FAILURE:
      return {
        ...state,
        isError: true,
      }
    default:
      return state
  }
}

export default reducer
