import {
  AUTHENTICATE_USER_FAILURE,
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER_REQUESTED,
  SET_PASSWORD_FAILURE,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_REQUESTED,
  FETCH_USER_FAILURE,
  FETCH_USER_SUCCESS,
  FETCH_USER_REQUESTED,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_REQUESTED,
  CREATE_COMPANY_FAILURE,
  USER_SIGN_OUT,
  CREATE_BUNDLE_SUCCESS,
  SIGN_UP_SUCCESS,
  SIGN_UP_REQUESTED,
  SIGN_UP_FAILURE,
  SET_REWARDS_SUCCESS,
} from 'constants/actions'

const initialState = {
  isLoading: false,
  isError: false,
  id: null,
  email: null,
  company: null,
  role: null,
  metadata: null,
  bundle: null,
  baseSalary: null,
  hasSetPassword: false,
  hasActiveBundle: false,
}

// TODO: Split out company information into separate reducer
const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case CREATE_COMPANY_REQUESTED:
    case FETCH_USER_REQUESTED:
    case AUTHENTICATE_USER_REQUESTED:
    case SET_PASSWORD_REQUESTED:
    case SIGN_UP_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case SET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        hasSetPassword: true,
      }
    case FETCH_USER_SUCCESS:
    case AUTHENTICATE_USER_SUCCESS:
    case SIGN_UP_SUCCESS:
      return {
        ...initialState,
        ...data,
      }
    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        company: data,
      }
    case SET_REWARDS_SUCCESS:
      if (state.company?.status === 'setup') {
        return {
          ...state,
          company: {
            ...state.company,
            status: 'trial',
          }
        }
      } else {
        return {
          ...state,
        }
      }
    case FETCH_USER_FAILURE:
    case AUTHENTICATE_USER_FAILURE:
    case CREATE_COMPANY_FAILURE:
    case SET_PASSWORD_FAILURE:
    case SIGN_UP_FAILURE:
      return {
        ...state,
        isError: true,
        isLoading: false,
      }
    case CREATE_BUNDLE_SUCCESS:
      return {
        ...state,
        bundle: data,
      }
    case USER_SIGN_OUT:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default reducer
