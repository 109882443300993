export const DEPARTMENTS = [
  { key: 'SALES', text: "Sales" },
  { key: 'MARKETING', text: "Marketing" },
  { key: 'FINANCE', text: "Finance/Accounting" },
  { key: 'ENGINEERING', text: "Engineering" },
  { key: 'PRODUCT', text: "Product" },
  { key: 'LEGAL', text: "Legal" },
  { key: 'HR', text: "Human Resources" },
  { key: 'OTHER', text: "Other" },
]
