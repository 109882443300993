import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import isNumber from 'lodash/isNumber'

import { selectCompanyRewards } from 'selectors/company'
import { generateRewardMapping } from 'util/rewards'

import BundleSelections from './BundleSelections'
import { PrimaryButton, DefaultButton, Toggle, TextField, TeachingBubble, DirectionalHint, Modal, Coachmark } from '@fluentui/react'

import './BundleBuilder.scss'
import classNames from 'classnames'

const BundleBuilder = ({ className, baseSalary, minSalary, maxSalary, pointPercent, fourDayPercentage, rewardDefaults, companyRewards, onSubmit, disabled, showTotalValue, showEditDisableMessage, visibilityToggle, includeBase, includeNotes, includeTutorial, includeHidden, previewMode }) => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;
  
  const currentCompanyRewards = useSelector(selectCompanyRewards)
  const rewards = companyRewards || currentCompanyRewards
  const mappedRewards = generateRewardMapping(rewards, rewardDefaults, baseSalary, includeBase, minSalary, maxSalary, includeHidden)

  const prevBaseSalary = useRef(baseSalary)
  const [tourStep, setTourStep] = useState(includeTutorial ? "tourIntro" : null)
  const [notes, setNotes] = useState("")
  const [fourDayEnabled, setFourDayEnabled] = useState(false)
  const [sliderData, setSliderData] = useState(mappedRewards)
  const [count, setCount] = useState(0)
  const chartData = Object.keys(sliderData).map((key) => {
    const computedValue = sliderData[key].computedValue
    const discountValue = sliderData[key].discountValue
    const name = sliderData[key].name

    return {
      id: name,
      value: computedValue,
      discountValue,
    }
  })

  const totalPointValue = chartData.map(items => items.value).reduce((accumulator, current) => accumulator + current, 0)
  const totalPointValueWithDiscounts = chartData.map(items => items.discountValue).reduce((accumulator, current) => accumulator + current, 0)

  let maxPoints = Math.round((baseSalary * pointPercent / 100) || 0)
  if (includeBase) { maxPoints = maxPoints + baseSalary }
  if (fourDayEnabled) { maxPoints = maxPoints * (1 - fourDayPercentage) }

  const pointsRemaining = maxPoints - totalPointValueWithDiscounts
  const cappedPointsRemaining = Math.max(0, pointsRemaining)
  const disableMessageVisible = showEditDisableMessage && pointsRemaining < 0

  // Reset with updated values if the base salary changes, since this changed the mapped rewards values
  useEffect(() => {
    if (minSalary !== prevBaseSalary) {
      setSliderData(mappedRewards)
      prevBaseSalary.current = minSalary
    }
  }, [baseSalary]) //eslint-disable-line

  useEffect(() => {
    if (pointsRemaining < 0) {
      const absPoints = Math.abs(pointsRemaining)
      const currentBase = sliderData["BASE_SALARY"]

      if (currentBase?.value) {
        const newBaseValue = currentBase.value - absPoints
        const roundedNewValue = Math.floor(newBaseValue / currentBase.step) * currentBase.step
        const cappedNewValue = Math.max(roundedNewValue, 0)

        setSliderData({
          ...sliderData,
          "BASE_SALARY": {
            ...currentBase,
            value: cappedNewValue,
            computedValue: currentBase.computeValue(cappedNewValue)
          }
        })
      }
    }
  }, [pointsRemaining, sliderData])

  const handleUpdateSliderData = (key, value) => {
    const reward = sliderData[key]

    if (reward.locked) { return }

    const previousValue = reward.computedValue
    const updatedValue = reward.computeValue(value)
    let computedDiff = updatedValue - previousValue

    const modifiableRewards = []

    if (totalPointValue + computedDiff > maxPoints) {
      Object.keys(sliderData).forEach((currentKey) => {
        const currentReward = sliderData[currentKey]
        const computedRewardValue = currentReward.computedValue
        const computedMin = currentReward.computedMin
        const possibleSteps = currentReward.computedStep ? Math.floor((computedRewardValue - computedMin)/ currentReward.computedStep) : 0
        const possibleDiff = possibleSteps * currentReward.computedStep

        if (key !== currentKey &&
          !currentReward.disabled &&
          !currentReward.locked &&
          possibleSteps > 0) {
            modifiableRewards.push({ ...currentReward, key: currentKey, possibleSteps, possibleDiff })
        }
      })

      if (modifiableRewards.length <= 0) {
        return
      }

      const totalPossibleDiff = modifiableRewards.map(items => items.possibleDiff).reduce((accumulator, current) => accumulator + current, 0) + cappedPointsRemaining

      if (totalPossibleDiff < computedDiff) {
        return
      }

      /*eslint-disable*/
      while (computedDiff > cappedPointsRemaining) {
        modifiableRewards.some((reward) => {
          if (reward.possibleSteps > 0) {
            reward.possibleSteps -= 1
            reward.value = (reward.value - reward.step).toFixed(2)
            reward.computedValue = reward.computedValue - reward.computedStep
            reward.discountValue = reward.discountValue - reward.computedStep
            computedDiff -= reward.computedStep
          }
          return computedDiff <= cappedPointsRemaining
        })
      }

      const mappedModifiableRewards = modifiableRewards.reduce((obj, item) => (obj[item.key] = item, obj), {})
      /*eslint-enable*/

      return setSliderData({ ...sliderData,  ...mappedModifiableRewards, [key]: { ...reward, value, computedValue: reward.computeValue(value), discountValue: reward.computeDiscountedValue(value) }})
    }

    return setSliderData({ ...sliderData, [key]: { ...reward, value, computedValue: reward.computeValue(value), discountValue: reward.computeDiscountedValue(value) } })
  }

  const handleLockReward = (id) => {
    const reward = sliderData[id]
    return setSliderData({ ...sliderData, [id]: { ...reward, locked: !reward.locked }})
  }

  const handleToggleVisibility = (id) => {
    const reward = sliderData[id]
    return setSliderData({ ...sliderData, [id]: { ...reward, visible: !reward.visible }})
  }

  const handleSubmit = () => {
    const sliderValues = Object.keys(sliderData).map((key) => {
      const reward = sliderData[key]
      return {
        id: key,
        name: reward.name,
        value: reward.value,
        valueType: reward.valueType,
        computedValue: reward.computedValue,
        visible: reward.visible
      }
    })

    onSubmit(sliderValues, maxPoints - totalPointValue, fourDayEnabled, notes)
  }

  const handleToggleFourDay = (e, checked) => {
    setFourDayEnabled(checked)
    const currentBase = { ...sliderData["BASE_SALARY"] }

    if (checked) {
      const newMin = Math.round(minSalary * (1 - fourDayPercentage))
      const newMax = Math.round(maxSalary * (1 - fourDayPercentage))
      const newDefault = Math.round(baseSalary * (1 - fourDayPercentage))
      currentBase.min = newMin
      currentBase.computedMin = newMin
      currentBase.max = newMax
      currentBase.defaultValue = newDefault
    } else {
      currentBase.min = minSalary
      currentBase.computedMin = minSalary
      currentBase.max = maxSalary
      currentBase.defaultValue = baseSalary
    }

    return setSliderData({ ...sliderData, "BASE_SALARY": { ...currentBase } })
  }

  const handleUpdateNotes = (e, value) => {
    setNotes(value)
  }

  const handleStopTour = (ev) => {
    setTourStep("")
  }

  const handleScrollEvent = (ev) => {
    if (ev.type ==="scroll" || ev.type === "resize") {
      setCount(count + 1)
      return true
    }
  }

  const handleSetTourStep = (nextTourStep) => {
    return () => setTourStep(nextTourStep)
  }

  const handleReset = () => {
    setSliderData(mappedRewards)
    setFourDayEnabled(false)
  }

  const baseSalaryValue = sliderData["BASE_SALARY"]?.value || 0
  const displayedTotalPointValue = includeBase ? totalPointValue - baseSalaryValue : totalPointValue
  const displayedBaseSalary = includeBase ? Math.round(baseSalaryValue) : baseSalary
  const needPointsMessage = showEditDisableMessage ? "Not enough points allocated.  Please increase the point allocation to continue editing." : "Not enough points. Please adjust your selections or click reset below."

  const showFourDay = isNumber(fourDayPercentage) && !showEditDisableMessage
  const calloutProps ={
    directionalHint: DirectionalHint.topCenter,
    preventDismissOnEvent: handleScrollEvent,
    style: { textAlign: 'left' },
  }

  const totalTourCount = showFourDay ? "6" : "5"

  return (
    <div className={classNames("bundleBuilder", className)}>
      <div className="pointsRemaining" id="pointsRemaining">
        {cappedPointsRemaining} Points Remaining
      </div>
      { showTotalValue &&
        <div className="totalCompensation">
          <h1>
            ${displayedBaseSalary?.toLocaleString('en-us')} + ${displayedTotalPointValue.toLocaleString('en-us')} = ${(displayedBaseSalary + displayedTotalPointValue).toLocaleString('en-us')}
            {' '}TOTAL COMPENSATION
          </h1>
        </div>
      }
      {
        showFourDay && (
          <div className="fourDay" id="fourDay">
            <div className="fourDayTitle">Opt for 4 day work week?</div>
            <span>This will lower your total compensation by {`${fourDayPercentage * 100}`}%.</span>
            <div className="fourDayToggle">
              <Toggle
                onText="Let's do it!"
                offText="Off"
                checked={fourDayEnabled}
                onChange={handleToggleFourDay}
              />
            </div>
          </div>
        )
      }
      { pointsRemaining < 0 &&
        <div className="disableMessage">
          {needPointsMessage} ({pointsRemaining * -1} needed)
        </div>
      }
      <BundleSelections
        readOnly={disableMessageVisible}
        visibilityToggle={visibilityToggle}
        sliderData={sliderData}
        chartData={chartData}
        onLock={handleLockReward}
        onToggleVisibility={handleToggleVisibility}
        onChange={handleUpdateSliderData}
      />
      { includeNotes && (
        <div className="notes">
          <TextField multiline rows={3} label="Notes / Questions?" value={notes} onChange={handleUpdateNotes} />
        </div>
      )}
      <PrimaryButton id="submitButton" onClick={handleSubmit} disabled={disabled || pointsRemaining < 0}>
        Submit
      </PrimaryButton>
      <DefaultButton className="resetButton" onClick={handleReset}>
        Reset to default
      </DefaultButton>
      { tourStep === "" && (
        <Coachmark target="#pointsRemaining" positioningContainerProps={{ ...calloutProps, directionalHint: DirectionalHint.leftCenter }} onDismiss={handleStopTour} onAnimationOpenStart={handleSetTourStep("tourPoints")}>
        </Coachmark>
      )}
      { tourStep === "tourPoints" && (
        <TeachingBubble
          target="#pointsRemaining"
          footerContent={`1 of ${totalTourCount}`}
          headline="View Point Balance"
          hasCloseButton
          onDismiss={handleStopTour}
          primaryButtonProps={{ children: "Next", onClick: handleSetTourStep("tourRewards")}}
          calloutProps={{ ...calloutProps, directionalHint: isMobile ? DirectionalHint.topCenter : DirectionalHint.leftCenter}}
        >
          See your base salary, your total compensation amount, as well as the remaining balance of points to spend on rewards. Your balance will adjust as you modify your reward selections.
        </TeachingBubble>
      )}
      { tourStep === "tourRewards" && (
        <TeachingBubble
          target="#sliders"
          footerContent={`2 of ${totalTourCount}`}
          headline="Learn About Your Rewards"
          hasCloseButton
          onDismiss={handleStopTour}
          primaryButtonProps={{ children: "Next", onClick: handleSetTourStep("tourSliders") }}
          secondaryButtonProps={{ children: "Back", onClick: handleSetTourStep("tourPoints") }}
          calloutProps={calloutProps}
        >
          { previewMode ? <span>Here you can see all the rewards you selected. Click on the "i" button to learn more about the offered rewards (vendors, accrual schedules, etc.).</span> :
            <span>Here you can see all the rewards offered by the company. Click on the "i" button to learn more about the offered rewards (vendors, accrual schedules, etc.).</span> }
        </TeachingBubble>
      )}
      { tourStep === "tourSliders" && (
        <TeachingBubble
          target="#BASE_SALARY"
          footerContent={`3 of ${totalTourCount}`}
          headline="Customize Your Rewards"
          hasCloseButton
          onDismiss={handleStopTour}
          primaryButtonProps={{ children: "Next", onClick: showFourDay ? handleSetTourStep("tourFourDay") : handleSetTourStep("tourLock") }}
          secondaryButtonProps={{ children: "Back", onClick: handleSetTourStep("tourRewards") }}
          calloutProps={calloutProps}
        >
          { previewMode ? <span>Use the sliders to adjust your base salary and each reward. As you increase/decrease rewards, see your point balance change.</span> :
            <span>Use the sliders to adjust your base salary and each reward offered by the company. As you increase/decrease rewards, see your point balance change.</span> }
        </TeachingBubble>
      )}
      { tourStep === "tourFourDay" && showFourDay && (
        <TeachingBubble
          target="#fourDay"
          footerContent={`4 of ${totalTourCount}`}
          headline="4 Day Workweek"
          hasCloseButton
          onDismiss={handleStopTour}
          primaryButtonProps={{ children: "Next", onClick: handleSetTourStep("tourLock") }}
          secondaryButtonProps={{ children: "Back", onClick: handleSetTourStep("tourSliders") }}
          calloutProps={calloutProps}
        >
          This offer allows you to opt into a 4 day workweek. If selected, this will lower your total compensation and base salary by the value shown below in exchange for a 4 day workweek.
        </TeachingBubble>
      )}
      { tourStep === "tourLock" && (
        <TeachingBubble
          target="#BASE_SALARY-lock"
          footerContent={`${showFourDay ? "5" : "4"} of ${totalTourCount}`}
          headline="Lock Your Rewards"
          hasCloseButton
          onDismiss={handleStopTour}
          primaryButtonProps={{ children: "Next", onClick: handleSetTourStep("tourSubmit") }}
          secondaryButtonProps={{ children: "Back", onClick: showFourDay ? handleSetTourStep("tourFourDay") : handleSetTourStep("tourSliders") }}
          calloutProps={calloutProps}
        >
          Once satisfied, click the ‘lock’ icon to freeze a reward in place. Any rewards that are ‘grayed’ out are not modifiable, but the rest are fair game. Customize away! 
        </TeachingBubble>
      )}
      { tourStep === "tourSubmit" && (
        <TeachingBubble
          target="#submitButton"
          footerContent={`${showFourDay ? "6" : "5"} of ${totalTourCount}`}
          headline="Complete Submission"
          hasCloseButton
          onDismiss={handleStopTour}
          primaryButtonProps={{ children: "Done", onClick: handleSetTourStep("") }}
          secondaryButtonProps={{ children: "Back", onClick: handleSetTourStep("tourLock") }}
          calloutProps={calloutProps}
        >
          { previewMode ? <span>When you are ready, click "Submit" to complete your submission and finish your Bundl. Then you can share out your Bundl with friends and colleagues!</span> :
            <span>When you are ready, click "Submit" to send your submission back to your company admin. You will receive an email with your selections, and the employer will send over an offer letter with your selections shortly.</span> }
        </TeachingBubble>
      )}
      <Modal
        className="welcomeTour"
        isOpen={tourStep === "tourIntro"}
        onDismiss={handleSetTourStep("tourSliders")}
        isBlocking={false}
        calloutProps={{ width: "600px" }}
      >
        <div className="tourIntro">
          <h2>Welcome to Bundl!</h2>
          <p>
          Welcome to Bundl, the only tool that allows candidates to build and customize their own compensation package within their offer letter! Easily select and weigh all the rewards and benefits to the values that matter to you and build a total rewards package that fits your lifestyle.
          </p>
          <p>
            So what are you waiting for? Follow the next steps and change the way offer letters are built.
          </p>
          <PrimaryButton className="tourIntroSubmit" onClick={handleSetTourStep("tourPoints")}>
            Let's Go!
          </PrimaryButton>
        </div>
      </Modal>
    </div>
  )
}

BundleBuilder.defaultProps = {
  showTotalValue: true,
  showEditDisableMessage: false,
  companyRewards: null,
  visibilityToggle: false,
  includeBase: false,
  fourDayPercentage: null,
  includeNotes: false,
  includeTutorial: false,
  includeHidden: false,
  previewMode: false,
}

export default BundleBuilder
