import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  Icon,
} from "@fluentui/react"

import { editRole } from "actions/company"
import { selectCompany } from "selectors/company"

import './EditRole.scss'
import RoleModal from "./RoleModal"

const EditRole = ({ className, id, name, percent, fourDayRatio, minSalary, maxSalary, defaultMinSalary, department }) => {
  const dispatch = useDispatch()
  const company = useSelector(selectCompany)
  const [modalOpen, setModalOpen] = useState(false)

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleEditRole = ({ name, department, percent, minSalary, maxSalary, fourDayRatio }) => {
    handleCloseModal()
    dispatch(editRole(id, { name, department, percent, minSalary, maxSalary, fourDayRatio }, company?.id))
  }

  return (
    <div className={className}>
      <Icon className="" iconName="Edit" onClick={handleOpenModal} />
      {modalOpen && <RoleModal
                      roleName={name}
                      rolePercent={percent}
                      roleDepartment={department}
                      roleMaxSalary={maxSalary}
                      roleMinSalary={minSalary}
                      roleDefaultMinSalary={defaultMinSalary}
                      roleFourDayRatio={fourDayRatio}
                      isOpen={modalOpen}
                      onSubmit={handleEditRole}
                      onClose={handleCloseModal}
                      submitLabel="Update"
                      modalTitle="Edit Role"
                    />
      }
    </div>
  )
}

export default EditRole
