import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ChoiceGroup, Dropdown, MessageBar, MessageBarType } from '@fluentui/react'
import TopRewards from 'components/admin/TopRewards'
import StatCard from 'components/shared/StatCard'

import { fetchAnalytics } from 'actions/analytics'

import { selectCompany } from 'selectors/company'
import {
  selectMemberCount,
  selectRewardSummary,
  selectDepartmentSummary,
  selectRoleSummary,
  selectSubmittedUserCount,
  selectTotalValue,
  selectVerifiedUserCount,
} from 'selectors/analytics'

import "./AdminDashboard.scss"

const sortOptions = [
  { key: 'all', text: 'All' },
  { key: 'role', text: 'Role' },
  { key: 'department', text: 'Department' }
]

const AdminDashboard = () => {
  const dispatch = useDispatch()
  const [rewardRole, setRewardRole] = useState(null)
  const [rewardDepartment, setRewardDepartment] = useState(null)
  const [sortType, setSortType] = useState('all')
  let rewardData

  const company = useSelector(selectCompany)

  useEffect(() => {
    dispatch(fetchAnalytics(company?.id))
  }, [company, dispatch])

  const memberCount = useSelector(selectMemberCount)
  const submittedCount = useSelector(selectSubmittedUserCount)
  const verifiedCount = useSelector(selectVerifiedUserCount)
  const totalValue = useSelector(selectTotalValue)
  const rewardSummary = useSelector(selectRewardSummary)
  const departmentSummary = useSelector(selectDepartmentSummary)
  const roleSummary = useSelector(selectRoleSummary)

  const departmentOptions = departmentSummary.map((department) => {
    return { key: department.id, text: department.name }
  })

  const roleOptions = roleSummary.map((role) => {
    return { key: role.id, text: role.name }
  })


  const handleChangeRole = (_ev, value) => {
    setRewardRole(value)
  }

  const handleChangeDepartment = (_ev, value) => {
    setRewardDepartment(value)
  }

  const handleChangeSort = (_ev, value) => {
    setSortType(value.key)
    setRewardDepartment(null)
    setRewardRole(null)
  }

  const submittedPercent = memberCount > 0 ? Math.round((submittedCount * 100 / memberCount)) : 0
  const verifiedPercent = memberCount > 0 ? Math.round((verifiedCount * 100 / memberCount)) : 0

  const selectedRole = sortType === 'role' && rewardRole ? roleSummary.find(role => role.id === rewardRole.key) : null
  const selectedDepartment = sortType === 'department' && rewardDepartment ? departmentSummary.find(dept => dept.id === rewardDepartment.key) : null

  if (selectedRole) {
    rewardData = selectedRole.summary
  } else if (selectedDepartment) {
    rewardData = selectedDepartment.summary
  } else {
    rewardData = rewardSummary
  }

  return (
    <div className="adminDashboard">
      <MessageBar
        className="infoMessage"
        messageBarType={MessageBarType.warning}>
        Your account is in a 30 day trial period.  At the end of your trial, you must upgrade your account to a paid plan for continued access.
        Please contact<a href="mailto:support@bundl.co">support@bundl.co</a> with any questions.
      </MessageBar>
      <div className="adminDashboardContent">
        <h1>Admin Dashboard</h1>
        <div className="statCards">
          <StatCard
            value={`$${totalValue.toLocaleString('en-us')}`}
            title="TOTAL REWARD VALUE"
          />
          <StatCard
            value={memberCount}
            title="TOTAL EMPLOYEES"
          />
          <StatCard
            value={submittedPercent}
            title="% Submitted"
          />
          <StatCard
            value={verifiedPercent}
            title="% Verified"
          />
        </div>
        { memberCount > 0 && rewardSummary.length > 0 ?
          <div>
            <div className="statTable">
              <div className="statTitle">Top Rewards</div>
              <div className="statSort">
                <ChoiceGroup
                  className="statSortSelect"
                  options={sortOptions}
                  label='Sort by'
                  selectedKey={sortType}
                  onChange={handleChangeSort}
                />
                { sortType === 'role' && <Dropdown
                                          className="statSortOption"
                                          label="Sort by role"
                                          options={roleOptions}
                                          onChange={handleChangeRole}
                                          value={rewardRole}
                                        />
                }
                { sortType === 'department' && <Dropdown
                                                  className="statSortOption"
                                                  label="Sort by department"
                                                  options={departmentOptions}
                                                  onChange={handleChangeDepartment}
                                                  value={rewardDepartment}
                                                />
                }
              </div>
              <TopRewards rewardSummary={rewardData} />
            </div>
          </div>: null
        }
      </div>
    </div>
  )
}

export default AdminDashboard
