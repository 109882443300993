import classnames from 'classnames'

import { ReactComponent as DeleteIcon } from "@fluentui/svg-icons/icons/delete_24_filled.svg"

import './RewardTile.scss'

const RewardTile = ({ children, className, id, label, onClick, onDelete, isActive, deleteable }) => {
  const classes = classnames("rewardTile", { 'active': isActive }, className)

  const handleClick = (_event) => {
    onClick(id)
  }

  const handleDelete = (_event) => {
    onDelete(id)
  }

  return (
    <div className={classes} onClick={handleClick}>
      { deleteable ? <DeleteIcon className="delete" onClick={handleDelete} /> : null }
      <div className="rewardIcon">
        {children}
      </div>
      <span className="rewardTileLabel">{label}</span>
    </div>
  )
}

RewardTile.defaultProps = {
  onClick: () => {},
  onDelete: () => {},
  deleteable: false,
  children: null,
  className: null,
}

export default RewardTile
