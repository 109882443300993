import { useState } from "react"
import { TextField, PrimaryButton, Dropdown, ResponsiveMode } from "@fluentui/react"
import { isEmpty, some } from "lodash"

import { createCompany } from "actions/company"
import { useDispatch } from "react-redux"

import { US_STATES } from "constants/onboarding"

import './VerifyCompany.scss'

const VerifyCompany = () => {
  const dispatch = useDispatch()

  const stateOptions = Object.keys(US_STATES).map((key) => {
    return { key, text: US_STATES[key] }
  })

  const [name, setName] = useState("")
  const [url, setUrl] = useState("")
  const [addressStreet, setAddressStreet] = useState("")
  const [addressCity, setAddressCity] = useState("")
  const [addressState, setAddressState] = useState(null)
  const [addressZip, setAddressZip] = useState("")

  const isDisabled = some([name, url, addressStreet, addressCity, addressState, addressZip], isEmpty)

  const handleTextChangeCurry = (setFn) => {
    return (_event, newValue) => {
      setFn(newValue || "")
    }
  }

  const handleChangeState = (ev, value) => {
    setAddressState(value)
  }

  const handleSubmit = () => {
    dispatch(
      createCompany({
        name,
        url,
        address: {
          street: addressStreet,
          city: addressCity,
          state: addressState.key,
          zip: addressZip,
        },
      })
    )
  }

  return (
    <div className="verifyCompany">
      <h3>Verify Company Info</h3>
      <TextField
        label="Company Name"
        value={name}
        onChange={handleTextChangeCurry(setName)}
      />
      <TextField
        label="Company Site"
        prefix="https://"
        value={url}
        onChange={handleTextChangeCurry(setUrl)}
      />
      <TextField
        label="Company Address"
        value={addressStreet}
        onChange={handleTextChangeCurry(setAddressStreet)}
        maxLength={100}
      />
      <TextField
        label="City"
        value={addressCity}
        onChange={handleTextChangeCurry(setAddressCity)}
        maxLength={100}
      />
      <Dropdown
        label="State"
        options={stateOptions}
        onChange={handleChangeState}
        value={addressState}
        responsiveMode={ResponsiveMode.small}
      />
      <TextField
        label="Zip"
        value={addressZip}
        onChange={handleTextChangeCurry(setAddressZip)}
        maxLength={5}
      />

      <PrimaryButton
        className="submitButton"
        text="Submit"
        onClick={handleSubmit}
        disabled={isDisabled}
      />
    </div>
  )
}

export default VerifyCompany
