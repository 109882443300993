import classnames from "classnames"

import './StatCard.scss'

const StatCard = ({ className, value, title }) => {
  return (
    <div className={classnames("statCard", className)}>
      <div className="stat">
        {value}
      </div>
      <div className="statTitle">
        {title}
      </div>
    </div>
  )
}

export default StatCard
