import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  ActionButton,
} from "@fluentui/react"

import { editEmployee } from "actions/company"
import { selectCompany } from "selectors/company"

import EmployeeModal from "./EmployeeModal"

import './EditEmployee.scss'

const EditEmployee = ({ id, firstName, lastName, email, baseSalary, roleId }) => {
  const dispatch = useDispatch()
  const company = useSelector(selectCompany)
  const [modalOpen, setModalOpen] = useState(false)

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleEditEmployee = ({ firstName, lastName, email, baseSalary, selectedRoleId }) => {
    handleCloseModal()
    dispatch(editEmployee(id, { firstName, lastName, email, baseSalary, roleId: selectedRoleId }, company?.id))
  }

  return (
    <>
      <ActionButton
        className="editPencil"
        iconProps= {{ iconName: "Edit" }}
        onClick={handleOpenModal}
      >
        Edit
      </ActionButton>
      { modalOpen &&
        <EmployeeModal
          modalTitle='Edit Employee'
          submitLabel='Submit'
          isOpen={modalOpen}
          onClose={handleCloseModal}
          onSubmit={handleEditEmployee}
          employeeFirstName={firstName}
          employeeLastName={lastName}
          employeeEmail={email}
          employeeBaseSalary={baseSalary}
          employeeRoleId={roleId}
        />
      }
    </>
  )
}

export default EditEmployee
