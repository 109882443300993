import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from 'react-router-dom'

import {
  getCompanyUsers,
  sendUserBundle,
  getCompanyRoles,
  exportEmployees,
} from "actions/company"
import { selectCompany, selectCompanyMembers, selectCompanyRoles } from "selectors/company"

import {
  ChoiceGroup,
  PrimaryButton,
  DetailsList,
  Icon,
  TextField,
} from "@fluentui/react"
import AddEmployee from "./AddEmployee"
import ImportEmployees from "./ImportEmployees"
import SendBundleAll from './SendBundleAll'

import './EmployeeList.scss'

const filters = [
  { key: 'all', text: 'All Employees' },
  { key: 'needsEmail', text: 'Needs Email'},
  { key: 'submitted', text: 'Needs Verification' },
  { key: 'needSubmission', text: 'Needs Submission' },
  { key: 'verified', text: 'Verified' },
]

const SendBundleLink = ({ userId }) => {
  const dispatch = useDispatch()
  const company = useSelector(selectCompany)

  const sendBundle = () => {
    dispatch(sendUserBundle(userId, company.id))
  }

  return (
    <Icon onClick={sendBundle} className="tableIcon" iconName="MailSolid" />
  )
}

const defaultColumns = [
  {
    key: 'employeeStatus',
    name: 'Status',
    minWidth: 120,
    maxWidth: 120,
    fieldName: 'employeeStatus',
    isResizable: true,
  },
  {
    key: 'name',
    name: 'Name',
    minWidth: 125,
    maxWidth: 125,
    fieldName: 'name',
    isResizable: true,
  },
  {
    key: 'email',
    name: 'Email',
    minWidth: 200,
    fieldName: 'email',
    isResizable: true,
  },
  {
    key: 'department',
    name: 'Department',
    minWidth: 100,
    fieldName: 'department',
    isResizable: true,
  },
  {
    key: 'companyRole',
    name: 'Role',
    minWidth: 150,
    fieldName: 'companyRole',
    isResizable: true,
  },
  {
    key: 'send',
    name: 'Send',
    minWidth: 40,
    maxWidth: 40,
    onRender: (item) => (
      <SendBundleLink userId={item.id} />
    ),
  },
  {
    key: 'view',
    name: 'View',
    minWidth: 40,
    maxWidth: 40,
    onRender: (item) => (
      <Link to={`/employee/${item.id}`}>
        <Icon className="tableIcon navigateIcon" iconName="NavigateExternalInline" />
      </Link>
    ),
  },
]

const EmployeeList = () => {
  const dispatch = useDispatch()
  const company = useSelector(selectCompany)
  const users = useSelector(selectCompanyMembers)
  const roles = useSelector(selectCompanyRoles)

  const [statusFilter, setStatusFilter] = useState('all')
  const [nameFilter, setNameFilter] = useState(null)
  const [columns, setColumns] = useState(defaultColumns)
  const [userList, setUserList] = useState(users)
  const computedUserList = userList.map((user) => {
    let employeeStatus

    if (user.status === 'archived') {
      employeeStatus = 'Archived'
    } else if (!user.email) {
      employeeStatus = 'Needs Email'
    } else {
      const bundleSubmittedStatus = !user.hasVerifiedBundle ? "Needs Verification" : "Verified"
      employeeStatus = user.hasActiveBundle ? bundleSubmittedStatus : "Needs Submission"
    }
  
    const userRole = roles.find(role => role.id === user.companyRoleId) || {}
    const name = user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : ''

    return {
      ...user,
      name,
      companyRole: userRole.name,
      department: userRole.department,
      employeeStatus,
    }
  })

  useEffect(() => {
    dispatch(getCompanyUsers(company.id))
    dispatch(getCompanyRoles(company.id))
  }, [dispatch, company])

  useEffect(() => {
    setUserList(users)
  }, [users])

  const handleSetStatusKey = (ev, filter) => {
    setStatusFilter(filter.key)
  }

  const handleExport = () => {
    dispatch(exportEmployees(company.id))
  }

  const handleColumnClick = (ev, column) => {
    const newColumns = columns.slice()

    if (column.key === 'view') { return }

    const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0]
    newColumns.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending
        currColumn.isSorted = true
      } else {
        newCol.isSorted = false
        newCol.isSortedDescending = true
      }
    })

    const newItems = copyAndSort(computedUserList, currColumn.fieldName, currColumn.isSortedDescending)

    setColumns(columns)
    setUserList(newItems)
  }

  const copyAndSort = (items, columnKey, isSortedDescending) => {
    const key = columnKey
    return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1))
  }

  const columnsWithClickHandler = columns.map((column) => {
    return {
      ...column,
      onColumnClick: handleColumnClick,
    }
  })

  const statusFilteredList = computedUserList.filter((user) => {
    switch (statusFilter) {
      case 'all':
        return user.status === 'active'
      case 'needsEmail':
        return !user.email
      case 'verified':
        return user.status === 'active' && user.hasActiveBundle && user.hasVerifiedBundle
      case 'needSubmission':
        return user.status === 'active' && !user.hasActiveBundle
      case 'submitted':
        return user.status === 'active' && user.hasActiveBundle && !user.hasVerifiedBundle
      default:
        return user.status === 'active'
    }
  })

  const filteredUserList = statusFilteredList.filter((user) => {
    if (nameFilter) {
      return user.name.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1
    } else {
      return true
    }
  })

  const handleFilterName = (ev, text) => {
    setNameFilter(text)
  }

  return (
    <div className="employeeList" data-is-scrollable="true">
      <h1>Employees</h1>
      <div className="employeeActions">
        <SendBundleAll />
        <AddEmployee />
        <ImportEmployees />
        <PrimaryButton onClick={handleExport}>
          Export
        </PrimaryButton>
      </div>

      <ChoiceGroup
        className="employeeStatusFilters"
        label="Filter by status"
        defaultSelectedKey="all"
        options={filters}
        onChange={handleSetStatusKey}
      />
      <TextField className="employeeNameFilter" label="Filter by name:" onChange={handleFilterName} />
      <div className="employeeTable" data-is-scrollable={true}>
        <DetailsList
          items={filteredUserList}
          columns={columnsWithClickHandler}
          getKey={(item) => item.id}
          setKey="none"
          selectionMode="none"
          checkboxVisibility={2}
        />
      </div>
    </div>
  )
}

export default EmployeeList
