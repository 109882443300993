import axios from 'axios'

import { getAuthHeader } from 'util/auth'

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  contentType: 'application/json',
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: [
    function (data, headers) {
      headers['Authorization'] = getAuthHeader()
      return JSON.stringify(data)
    }
  ],
})

export default client
