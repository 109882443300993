import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { TextField, PrimaryButton, Spinner } from '@fluentui/react'

import { setUserPassword } from 'actions/user'

import { selectUserIsLoading, selectUserId, selectUserHasSetPassword } from 'selectors/user'

import './SetPassword.scss'

const SetPassword = () => {
  const history = useHistory()
  const isLoading = useSelector(selectUserIsLoading)
  const hasSetPassword = useSelector(selectUserHasSetPassword)
  const userId = useSelector(selectUserId)

  const dispatch = useDispatch()
  const [password, setPassword] = useState("")
  const [passwordConfirmation, setPasswordConfirmation] = useState("")

  const handleSetPassword = (e) => setPassword(e.target.value)
  const handleSetPasswordConfirmation = (e) => setPasswordConfirmation(e.target.value)
  const handleSubmit = () => dispatch(setUserPassword(userId, password))

  const disabled = password.length < 8 || (password !== passwordConfirmation)

  useEffect(() => {
    if (hasSetPassword && !isLoading) {
      history.push('/')
    }
  }, [hasSetPassword, history, isLoading])

  return (
    <div className="setPassword">
      <TextField
        type="password"
        canRevealPassword
        revealPasswordAriaLabel="Show password"
        label="Password (min 8 characters)"
        value={password}
        onChange={handleSetPassword}
      />
      <TextField
        type="password"
        canRevealPassword
        revealPasswordAriaLabel="Show password"
        label="Confirm Password"
        value={passwordConfirmation}
        onChange={handleSetPasswordConfirmation}
      />
      { isLoading ? <Spinner /> : <PrimaryButton className="submit" text="Set Password" onClick={handleSubmit} disabled={disabled} /> }
    </div>
  )
}

export default SetPassword
