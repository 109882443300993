import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import {
  selectUserBundle,
  selectUserId,
  selectUserRole,
  selectUserBaseSalary,
  selectUser,
  selectUserRewardDefaults,
  selectUserIsError,
} from "selectors/user"
import {
  selectCompanyRewards,
} from "selectors/company"

import { createBundle } from "actions/bundle"
import { getCompanyRewards } from "actions/company"

import { Checkbox, DefaultButton, Dialog, DialogFooter, PrimaryButton, TextField } from "@fluentui/react"
import BundleBuilder from "components/shared/BundleBuilder"

import './CreateBundle.scss'

const CreateBundle = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [verifyModalOpen, setVerifyModalOpen] = useState(false)
  const [bundleValues, setBundleValues] = useState([])
  const [signature, setSignature] = useState("")
  const [checked, setChecked] = useState(false)

  const userError = useSelector(selectUserIsError)
  const companyRole = useSelector(selectUserRole)
  const user = useSelector(selectUser)
  const userId = useSelector(selectUserId)
  const baseSalary = useSelector(selectUserBaseSalary)
  const userBundle = useSelector(selectUserBundle)
  const rewardDefaults = useSelector(selectUserRewardDefaults)
  const rewards = useSelector(selectCompanyRewards)

  const companyId = user.company?.id
  const { firstName, lastName, email } = user

  const handleSubmit = () => {
    dispatch(createBundle(userId, bundleValues))
    handleCloseVerifyModal()
  }

  const handleCloseVerifyModal = () => {
    setVerifyModalOpen(false)
    setSignature("")
    setChecked(false)
  }

  const handleOpenVerifyModal = (values, _pointsRemaining) => {
    setVerifyModalOpen(true)
    setBundleValues(values)
  }

  const handleUpdateSignature = (ev, value) => {
    setSignature(value)
  }

  const handleSignatureError = (value) => {
    const trimmedValue = value.trim()

    if (trimmedValue === `${user.firstName} ${user.lastName}`) {
      return ''
    } else {
      return 'Please ensure your name matches the name on your Bundl profile'
    }
  }

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyRewards(companyId))
    }
  }, [dispatch, companyId])

  useEffect(() => {
    if (userError) {
      history.push('/link-expired')
    }
  }, [userError, history])
  
  const dialogContentProps = {
    title: 'Verify Submission',
    closeButtonAriaLabel: 'Cancel',
    subText: 'Clicking "Verify" will complete your Bundl submission, after which you can no longer modify your selections. Please type your full name (as displayed in your Bundl profile) to serve as your signature and continue, or click "Go Back" to continue modifying your selections.',
  }

  const trimmedValue = signature.trim()
  const disabled = !(trimmedValue === `${user.firstName} ${user.lastName}`) || !checked

  return (
    <>
      <div className="createBundle">
        <h1>Create Bundl</h1>
        <div className="employeeCard">
          <div>
            <span className="cardLabel">Email:</span> {email}
          </div>
          <div>
            <span className="cardLabel">Name:</span> {firstName} {lastName}
          </div>
          <div>
            <span className="cardLabel">Base Salary:</span> ${baseSalary?.toLocaleString('en-us')}
          </div>
          <div>
          <span className="cardLabel">Role:</span> {companyRole?.name}
          </div>
          <div>
          <span className="cardLabel">Department:</span> {companyRole?.department}
          </div>
        </div>
        { userBundle && userBundle.hasActiveVersion ?
          <div className="submitSuccess">
            <h3>Submission successful!</h3>
            Thank you for your submission! We have now notified your company admin to review your Bundl. Once they have confirmed your Bundl, you will receive a confirmation email. Please contact your company admin with any questions.
          </div> :
            rewards.length > 0 ?
              <BundleBuilder
                onSubmit={handleOpenVerifyModal}
                baseSalary={baseSalary}
                pointPercent={companyRole?.pointPercent}
                rewardDefaults={rewardDefaults}
              /> : null
        }
      </div>
      <Dialog
        className="createVerifyModal"
        titleAriaId='Verify Submission'
        isOpen={verifyModalOpen}
        onDismiss={handleCloseVerifyModal}
        modalProps={{ isBlocking: false }}
        dialogContentProps={dialogContentProps}
      >
        <TextField
          label="Signature"
          onChange={handleUpdateSignature}
          onGetErrorMessage={handleSignatureError}
          deferredValidationTime={1500}
          validateOnLoad={false}
        />
        <Checkbox
          className="verifyCheckbox"
          label="I verify that these are my own selections and that I cannot modify them after submission"
          isChecked={checked}
          onChange={(_ev, checked) => setChecked(checked)}
        />
        <DialogFooter>
          <PrimaryButton disabled={disabled} onClick={handleSubmit}>
            Verify Submission
          </PrimaryButton>
          <DefaultButton onClick={handleCloseVerifyModal}>
            Go Back
          </DefaultButton>
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default CreateBundle
