import {
  FETCH_ANALYTICS_FAILURE,
  FETCH_ANALYTICS_SUCCESS,
  FETCH_ANALYTICS_REQUESTED,
} from 'constants/actions'

import {
  fetchAnalytics as fetchAnalyticsRequest
} from 'api/requests'

export const fetchAnalytics = (companyId) => async (dispatch) => {
  dispatch({
    type: FETCH_ANALYTICS_REQUESTED,
  })

  try {
    const response = await fetchAnalyticsRequest(companyId)
    const responseData = response.data

    if (responseData) {
      dispatch({
        type: FETCH_ANALYTICS_SUCCESS,
        data: {
          summary: responseData,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: FETCH_ANALYTICS_FAILURE
    })
  }
}
