import { DefaultButton, Dropdown, Position, PrimaryButton, SpinButton, TextField } from "@fluentui/react"
import { getCompanyRoles } from "actions/company"
import { createOfferLetter } from "actions/offerLetters"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { selectCompany, selectCompanyRoles, selectLatestRoleId } from "selectors/company"
import AddRole from "./AddRole"

import { EMAIL_REGEX } from 'constants/email'

import "./CreateOfferLetter.scss"

const CreateOfferLetter = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const company = useSelector(selectCompany)
  const companyRoles = useSelector(selectCompanyRoles)
  const latestRoleId = useSelector(selectLatestRoleId)

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(false)
  const [selectedRoleId, setSelectedRoleId] = useState(null)
  const [baseSalary, setBaseSalary] = useState(null)

  const currentRole = companyRoles.find((role) => role.id === selectedRoleId)

  // TODO: This is a little hacky.  Consider a better strategy
  // This is for redirecting to default bundl after creating role
  useEffect(() => {
    if (companyRoles.length > 0 && latestRoleId) {
      history.push(`/role/${latestRoleId}`)
    }
  }, [companyRoles, latestRoleId, history])

  useEffect(() => {
    dispatch(getCompanyRoles(company.id))
  }, [dispatch, company])

  const handleFirstNameChange = (_ev, value) => {
    setFirstName(value)
  }

  const handleLastNameChange = (_ev, value) => {
    setLastName(value)
  }

  const handleEmailChange = (_ev, value) => {
    setEmail(value)
  }

  const handleEmailError = (value) => {
    if (EMAIL_REGEX.test(value) || value === "") {
      setEmailError(false)
      return ''
    } else {
      setEmailError(true)
      return "Email must be a valid syntax"
    }
  }

  const handleRoleChange = (_ev, value) => {
    setSelectedRoleId(value.key)
  }

  const handleBaseSalaryChange = (_ev, value) => {
    let newValue = parseInt(value)
    if (currentRole?.minSalary) {
      newValue = Math.max(newValue, currentRole.minSalary)
    }
    if (currentRole?.maxSalary) {
      newValue = Math.min(newValue, currentRole.maxSalary)
    }
    setBaseSalary(newValue)
  }

  const handleValidateBaseSalary = (value, _ev) => {
    return Number(value.replace(/[^0-9.]+/g,""))
  }

  const handleIncrementBaseSalary = () => {
    return baseSalary + 100
  }

  const handleDecrementBaseSalary = () => {
    return Math.max(0, baseSalary - 100)
  }

  const handleSubmit = () => {
    dispatch(createOfferLetter({ companyId: company?.id,
      firstName,
      lastName,
      email,
      companyRoleId: selectedRoleId,
      baseSalary,
    }))
    history.push('/offers')
  }

  const disabled = !firstName || !lastName || !baseSalary || !selectedRoleId || emailError
  const baseSalaryWithDefault = baseSalary || 0

  const getMaxSalaryStatement = () => {
    if (currentRole?.maxSalary) {
      return ` and less than or equal to the max salary of $${currentRole.maxSalary.toLocaleString('en-us')}`
    }
  }

  return (
    <div className="createOfferLetter">
      <h1>Create Offer Letter</h1>
      <div className="createForm">
        <TextField className="offerTextField" label="First Name" value={firstName} onChange ={handleFirstNameChange} />
        <TextField className="offerTextField"  label="Last Name" value={lastName} onChange ={handleLastNameChange} />
        <TextField
          label="Email"
          className="offerTextField"
          value={email}
          onChange ={handleEmailChange}
          onGetErrorMessage={handleEmailError}
          deferredValidationTime={750}
        />
        <div className="addEmployeeRole">
          <Dropdown
            label="Role"
            selectedKey={selectedRoleId}
            options={companyRoles.map(role => ({ key: role.id, text: role.name }))}
            onChange={handleRoleChange}
            placeholder="Select a role"
          />
          <AddRole className="addEmployeeRoleButton" />
        </div>
        <SpinButton
          className="salarySpinner"
          label="Base Salary"
          labelPosition={Position.top}
          value={`$${baseSalaryWithDefault.toLocaleString('en-us')}`}
          min={0}
          step={100}
          onChange={handleBaseSalaryChange}
          onValidate={handleValidateBaseSalary}
          onIncrement={handleIncrementBaseSalary}
          onDecrement={handleDecrementBaseSalary}
          incrementButtonAriaLabel="Increase value by 100"
          decrementButtonAriaLabel="Decrease value by 100"
        />
        { currentRole?.minSalary && <p className="spinnerSubtext">
                                      Base salary must be greater than or equal to the minimum salary, which is
                                      ${currentRole.minSalary.toLocaleString('en-us')} {getMaxSalaryStatement()}
                                    </p>
        }
      </div>
      <div className="createOfferButtons">
        <PrimaryButton onClick={handleSubmit} text="Create" disabled={disabled} />
        <Link to="/offers">
          <DefaultButton text="Cancel" />
        </Link>
      </div>
    </div>
  )
}

export default CreateOfferLetter
