import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { filter } from "lodash"

import {
  PrimaryButton,
  DefaultButton,
  Dialog,
  DialogFooter,
} from "@fluentui/react"

import { bulkSendBundle } from "actions/company"

import { selectCompany, selectCompanyMembers } from "selectors/company"



const SendBundleAll = () => {
  const dispatch = useDispatch()
  const company = useSelector(selectCompany)
  const users = useSelector(selectCompanyMembers)

  const activeBundleUsersCount = filter(users, (user) => user.hasActiveBundle).length
  const invalidRoleUsersCount = filter(users, (user) => !user.hasValidRole && !user.hasActiveBundle).length
  const noEmailUsersCount = filter(users, (user) => !user.email).length
  const totalSendCount = users.length - activeBundleUsersCount - invalidRoleUsersCount

  const [modalOpen, setModalOpen] = useState(false)

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleSend = () => {
    dispatch(bulkSendBundle(company.id))
    setModalOpen(false)
  }

  const dialogContentProps = {
    title: 'Send Bundl Links',
    closeButtonAriaLabel: 'Close',
    subText: 'This will send Bundl links to all employees that still need a submission.  Employees with an already active Bundl, or on a role without an active Default Bundl will be skipped. Would you like to continue?',
  }

  return (
    <>
      <PrimaryButton onClick={handleOpenModal}>
        Send All
      </PrimaryButton>
      <Dialog
        hidden={!modalOpen}
        onDismiss={handleCloseModal}
        dialogContentProps={dialogContentProps}
        modalProps={{ isBlocking: true }}
      >
        <p>
          <strong>Total Send Count</strong>: {totalSendCount}
        </p>
        <p>
          <strong>Skipping (already active)</strong>: {activeBundleUsersCount}
        </p>
        <p>
          <strong>Skipping (need valid role)</strong>: {invalidRoleUsersCount}
        </p>
        <p>
          <strong>Skipping (need email)</strong>: {noEmailUsersCount}
        </p>
        <DialogFooter>
          <PrimaryButton onClick={handleSend} text="Continue" />
          <DefaultButton onClick={handleCloseModal} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default SendBundleAll
