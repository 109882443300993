import {
  VERIFY_COMPANY,
  SELECT_REWARDS,
  SET_REWARDS,
  PENDING,
  SETUP_ERROR,
} from 'constants/onboarding'

export const selectOnboardingState = (state) => {
  if (!state.user.company) {
    return VERIFY_COMPANY
  }

  if (state.user.company?.status === 'setup') {
    if (state.onboarding.rewards.selections.length > 0 || state.onboarding.rewards.customSelections.length > 0 ) {
      return SET_REWARDS
    }
    return SELECT_REWARDS
  }

  if (state.user.company?.status === 'onboarding') {
    return PENDING
  }

  return SETUP_ERROR
}

export const selectRewardSelections = (state) => {
  return state.onboarding.rewards.selections
}

export const selectRewardCustomSelections = (state) => {
  return state.onboarding.rewards.customSelections
}
