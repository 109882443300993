import _ from 'lodash'

export const selectCompany = (state) => {
  return state.user.company || {}
}

export const selectCompanyMembers = (state) => {
  const users = state.company.users
  return users.filter((user) => user.role === 'member' && user.status === 'active')
}

export const selectCompanyUserById = (state, userId) => {
  return state.company.users.find((user) => user.id === userId)
}

export const selectCompanyBundles = (state) => {
  return state.company.bundles
}

export const selectCompanyUserBundle = (state, bundleId) => {
  return state.company.bundles.find((bundle) => bundle.id === bundleId)
}

export const selectCompanyIsOnboarding = (state) => {
  const company = state.user.company
  const companyStatus = company?.status

  return _.isEmpty(company) || companyStatus === 'setup' || companyStatus === 'onboarding'
}

export const selectCompanyIsTrial = (state) => {
  const company = state.user.company
  const companyStatus = company?.status

  return _.isEmpty(company) || companyStatus === 'trial'
}

export const selectCompanyRewards = (state) => {
  const rewards = state.company.rewards || []
  const activeRewards = _.filter(rewards, { status: "active" })
  return activeRewards
}

export const selectAllCompanyRewards = (state) => {
  const rewards = state.company.rewards

  return rewards
}

export const selectCompanyRewardDefaults = (state) => {
  const rewardDefaults = state.company.rewardDefaults

  if (rewardDefaults.length <= 0) {
    return []
  }

  return _.chain(rewardDefaults).keyBy('rewardId').mapValues('value').value()
}

export const selectCompanyRoles = (state) => {
  const companyRoles = state.company.roles
  const rewardDefaults = state.company.rewardDefaults || []

  if (!companyRoles) { return [] }

  const rolesWithRewardDefaults = companyRoles.map((role) => {
    const matchingDefaults = rewardDefaults.filter((item) => item.companyRoleId === role.id)
    const mapped = matchingDefaults && _.chain(matchingDefaults).keyBy('rewardId').mapValues((reward) => ({
      value: reward.value,
      visible: reward.visible,
    })).value()

    return { ...role, rewardDefaults: mapped }
  })

  return rolesWithRewardDefaults
}

export const selectLatestRoleId = (state) => state.company.latestRoleId
