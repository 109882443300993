import { ResponsiveBar } from '@nivo/bar'

import COLORS from 'constants/colors'

const TopRewards = ({ rewardSummary = [] }) => {
  return (
    <div style={{ height: '550px' }}>
      <ResponsiveBar
        theme={{ fontSize: 18 }}
        data={rewardSummary}
        margin={{ top: 50, right: 240, bottom: 50, left: 100 }}
        keys={['sum']}
        indexBy='name'
        colors={COLORS}
        colorBy='indexValue'
        axisLeft={{
          enable: true,
          tickValues: 5,
          format: (value) => ( value && `$${value.toLocaleString()}` )
        }}
        axisBottom={null}
        isInteractive={true}
        tooltip={({ indexValue, value, color }) => (
          <div style={{ padding: 12, color, background: '#FFF', borderRadius: 5, border: '1px solid gray'}}>
            {indexValue} - ${value.toLocaleString()}
          </div>
        )}
        labelTextColor='white'
        enableLabel={false}
        legends={[
          {
            dataFrom: 'indexes',
            anchor: 'top-right',
            direction: 'column',
            justify: false,
            translateX: 160,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 150,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
                {
                    on: 'hover',
                    style: {
                        itemOpacity: 1
                    }
                }
            ]
          }
      ]}
      />
    </div>
  )
}

export default TopRewards
