import { useSelector } from 'react-redux'

import VerifyCompany from 'components/onboarding/VerifyCompany'
import SelectRewards from 'components/onboarding/SelectRewards'
import SetRewards from 'components/onboarding/SetRewards'
import Pending from 'components/onboarding/Pending'
import SetupError from 'components/onboarding/SetupError'

import { selectRewards } from "actions/company"

import { selectOnboardingState } from 'selectors/onboarding'

import './OnboardingDashboard.scss'

import {
  VERIFY_COMPANY,
  SELECT_REWARDS,
  SET_REWARDS,
  PENDING,
  SETUP_ERROR,
} from 'constants/onboarding'

const ONBOARDING_STEP_MAP = {
  [VERIFY_COMPANY]: <VerifyCompany />,
  [SELECT_REWARDS]: <SelectRewards onSubmit={selectRewards} />,
  [SET_REWARDS]: <SetRewards />,
  [PENDING]: <Pending />,
  [SETUP_ERROR]: <SetupError />,
}

const OnboardingDashboard = () => {
  const onboardingState = useSelector(selectOnboardingState)

  return (
    <div className="onboardingStep">
      { ONBOARDING_STEP_MAP[onboardingState] }
    </div>
  )
}

export default OnboardingDashboard
