import { ResponsivePie } from '@nivo/pie'

import Reward from 'components/Reward'

import COLORS from 'constants/colors'

import './BundleSelections.scss'

const BundleSelections = ({ chartData, sliderData, onChange, onLock, readOnly, visibilityToggle, onToggleVisibility }) => {
  const marginBottom = chartData.length * 19

  return (
    <div className="selections">
      <div className="chart">
        <ResponsivePie
          startAngle={360}
          endAngle={0}
          activeOuterRadiusOffset={5}
          cornerRadius={2}
          padAngle={0.7}
          data={chartData}
          innerRadius={0.5}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          arcLinkLabelsDiagonalLength={8}
          colors={COLORS}
          margin={{
            bottom: marginBottom,
            left: 10,
            right: 10
          }}
          tooltip={({ datum: { id, value, color }}) => (
            <div style={{ padding: 12, color, background: '#FFF', borderRadius: 5, border: '1px solid gray', fontWeight: 600 }}>
              {id} - ${value.toLocaleString()}
            </div>
          )}
          legends={[
            {
              data: chartData.map((item, index) => ({ id: item.id, label: `${item.id} $${item.value.toLocaleString('en-us')}`, color: COLORS[index]})),
              anchor: 'bottom',
              direction: 'column',
              justify: false,
              translateX: -100,
              translateY: marginBottom,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: '#999',
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#000'
                  }
                }
              ]
            }
        ]}
        />
      </div>
      <div className="sliders" id="sliders">
        { Object.keys(sliderData).map((key) => {
            const {
              name,
              max,
              min,
              step,
              disabled,
              value,
              valueType,
              locked,
              description,
              notes,
              vendorName,
              vendorUrl,
              visible,
            } = sliderData[key]
            const { defaultValue } = sliderData[key]

            return (
              <Reward
                key={key}
                id={key}
                readOnly={readOnly}
                name={name}
                min={min}
                max={max}
                step={step}
                disabled={readOnly || disabled}
                locked={readOnly || locked || disabled}
                onChange={onChange}
                onLock={onLock}
                value={value}
                valueType={valueType}
                defaultValue={defaultValue}
                description={description}
                notes={notes}
                vendorName={vendorName}
                vendorUrl={vendorUrl}
                visibilityToggle={visibilityToggle}
                onToggleVisibility={onToggleVisibility}
                visible={visible}
              />
            )
          })
        }
      </div>
    </div>
  )
}

BundleSelections.defaultProps = {
  readOnly: false,
  visibilityToggle: false,
  onToggleVisibility: () => {},
  onChange: () => {},
  onLock: () => {},
}

export default BundleSelections
