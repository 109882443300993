import {
  FETCH_ANALYTICS_FAILURE,
  FETCH_ANALYTICS_SUCCESS,
  FETCH_ANALYTICS_REQUESTED,
} from 'constants/actions'

const initialState = {
  isLoading: false,
  isError: false,
  summary: {},
}

const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case FETCH_ANALYTICS_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case FETCH_ANALYTICS_SUCCESS:
      return {
        ...state,
        summary: data.summary,
        isLoading: false,
      }
    case FETCH_ANALYTICS_FAILURE:
      return {
        ...state,
        isError: true,
      }
    default:
      return state
  }
}

export default reducer
