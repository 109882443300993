import {
  FETCH_COMPANY_USERS_FAILURE,
  FETCH_COMPANY_USERS_SUCCESS,
  FETCH_COMPANY_USERS_REQUESTED,
  FETCH_COMPANY_ROLES_FAILURE,
  FETCH_COMPANY_ROLES_SUCCESS,
  FETCH_COMPANY_ROLES_REQUESTED,
  UPLOAD_EMPLOYEES_SUCCESS,
  SET_DEFAULT_BUNDLE_SUCCESS,
  VERIFY_BUNDLE_SUCCESS,
  ADD_ROLE_SUCCESS,
  ADD_EMPLOYEE_SUCCESS,
  FETCH_COMPANY_REWARDS_SUCCESS,
  EDIT_EMPLOYEE_SUCCESS,
  EDIT_ROLE_SUCCESS,
  DELETE_REWARD_SUCCESS,
  SET_REWARDS_SUCCESS,
  FETCH_COMPANY_USER_REQUESTED,
  FETCH_COMPANY_USER_FAILURE,
  FETCH_COMPANY_USER_SUCCESS,
} from 'constants/actions'

import findIndex from "lodash/findIndex"

const initialState = {
  isLoading: false,
  isError: false,
  users: [],
  bundles: [],
  roles: [],
  rewards: [],
  rewardDefaults: [],
  latestRoleId: null,
}

const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case FETCH_COMPANY_REWARDS_SUCCESS:
    case DELETE_REWARD_SUCCESS:
    case SET_REWARDS_SUCCESS:
      return {
        ...state,
        rewards: data.rewards,
      }
    case FETCH_COMPANY_ROLES_REQUESTED:
    case FETCH_COMPANY_USERS_REQUESTED:
    case FETCH_COMPANY_USER_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case FETCH_COMPANY_USERS_SUCCESS:
      return {
        ...state,
        users: data.users,
        bundles: data.bundles,
      }
    case FETCH_COMPANY_USER_SUCCESS:
      const existingUsers = [...state.users]
      const existingUserIndex = findIndex(existingUsers, { id: data?.user?.id })

      if (existingUserIndex >= 0) {
        existingUsers.splice(existingUserIndex, 1, data.user)
      } else {
        existingUsers.push(data.user)
      }

      const existingBundles = [...state.bundles]
      const existingBundleIndex = findIndex(existingBundles, {id: data?.bundle?.id })

      if (existingBundleIndex >= 0) {
        existingBundles.splice(existingBundleIndex, 1, data.bundle)
      } else {
        existingBundles.push(data.bundle)
      }

      return {
        ...state,
        users: existingUsers,
        bundles: existingBundles,
      }
    case UPLOAD_EMPLOYEES_SUCCESS:
      return {
        ...state,
        users: data.users,
        latestRoleId: null,
      }
    case ADD_EMPLOYEE_SUCCESS: {
      const existingUsers = [...state.users]
      existingUsers.push(data)

      return {
        ...state,
        users: existingUsers,
        latestRoleId: null,
      }
    }

    case EDIT_EMPLOYEE_SUCCESS: {
      const existingUsers = [...state.users]
      const updated = existingUsers.map((user) => {
        if (user.id === data.id) {

          return { ...user, ...data }
        } else {
          return user
        }
      })

      return {
        ...state,
        users: updated,
        latestRoleId: null,
      }
    }
    case VERIFY_BUNDLE_SUCCESS: {
      const existingBundles = [...state.bundles]
      const existingIndex = existingBundles.findIndex((bundle) => bundle.id === data.id)
      existingBundles.splice(existingIndex, existingIndex, data)

      return {
        ...state,
        bundles: existingBundles,
      }
    }
    case ADD_ROLE_SUCCESS: {
      const existingRoles = [...state.roles]
      existingRoles.push(data)

      return {
        ...state,
        roles: existingRoles,
        latestRoleId: data.id,
      }
    }
    case EDIT_ROLE_SUCCESS: {
      const existingRoles = [...state.roles]
      const updated = existingRoles.map((role) => {
        if (role.id === data.id) {

          return { ...role, ...data }
        } else {
          return role
        }
      })

      return {
        ...state,
        roles: updated,
      }
    }
    case FETCH_COMPANY_ROLES_SUCCESS:
    case SET_DEFAULT_BUNDLE_SUCCESS:
      return {
        ...state,
        roles: data.roles,
        rewardDefaults: data.rewardDefaults,
        latestRoleId: null,
      }
    case FETCH_COMPANY_ROLES_FAILURE:
    case FETCH_COMPANY_USERS_FAILURE:
    case FETCH_COMPANY_USER_FAILURE:
      return {
        ...state,
        isError: true,
      }
    default:
      return state
  }
}

export default reducer
