import { combineReducers } from "redux"

import user from './user'
import company from './company'
import analytics from './analytics'
import notifications from './notifications'
import onboarding from './onboarding'
import feedback from './feedback'
import offerLetters from './offerLetters'
import preview from './preview'

export default combineReducers({
  user,
  company,
  analytics,
  notifications,
  onboarding,
  feedback,
  offerLetters,
  preview,
})
