import { useEffect, useState } from "react"

import every from 'lodash/every'

import { PrimaryButton } from "@fluentui/react"
import EditReward from "./EditReward"

import './Rewards.scss'

const DEFAULT_NEW_REWARD = {
  name: 'New Reward',
  valueType: 'dollar',
  description: '',
  min: 0,
  max: 0,
  defaultValue: 0,
  discountPercent: 0,
  required: false,
  customize: false,
}

const Rewards = ({ rewards, handleSubmit, handleDelete, buttonText, title }) => {
  const [currentRewards, setCurrentRewards] = useState([])

  useEffect(() => {
    setCurrentRewards(rewards)
  }, [rewards])

  const handleAddNew = () => {
    const updatedRewards = [...currentRewards, {...DEFAULT_NEW_REWARD} ]
    setCurrentRewards(updatedRewards)
  }

  const handleUpdateDescription = (index) => {
    return (newValue) => {
      const updated = currentRewards.map((reward, i) => {
        if (i === index) {
          return { ...reward, description: newValue }
        }

        return reward
      })
      setCurrentRewards(updated)
    }
  }

  const handleUpdateName = (index) => {
    return (newValue) => {
      const updated = currentRewards.map((reward, i) => {
        if (i === index) {
          return { ...reward, name: newValue }
        }

        return reward
      })
      setCurrentRewards(updated)
    }
  }

  const handleUpdateValueType= (index) => {
    return (newValue) => {
      const updated = currentRewards.map((reward, i) => {
        if (i === index) {
          return { ...reward, valueType: newValue }
        }

        return reward
      })
      setCurrentRewards(updated)
    }
  }

  const handleUpdateValueMultiplier = (index) => {
    return (valueMultiplier) => {
      const updated = currentRewards.map((reward, i) => {
        if (i === index) {
          return { ...reward, valueMultiplier: valueMultiplier }
        }

        return reward
      })
      setCurrentRewards(updated)
    }
  }

  const handleUpdateMax = (index) => {
    return (maxValue) => {
      const updated = currentRewards.map((reward, i) => {
        if (i === index) {
          return { ...reward, max: maxValue }
        }

        return reward
      })
      setCurrentRewards(updated)
    }
  }

  const handleUpdateMin = (index) => {
    return (minValue) => {
      const updated = currentRewards.map((reward, i) => {
        if (i === index) {
          return { ...reward, min: minValue }
        }

        return reward
      })
      setCurrentRewards(updated)
    }
  }

  const handleUpdateDefault = (index) => {
    return (defaultValue) => {
      const updated = currentRewards.map((reward, i) => {
        if (i === index) {
          return { ...reward, defaultValue }
        }

        return reward
      })
      setCurrentRewards(updated)
    }
  }

  const handleUpdateDiscount = (index) => {
    return (discountPercent) => {
      const updated = currentRewards.map((reward, i) => {
        if (i === index) {
          return { ...reward, discountPercent }
        }

        return reward
      })
      setCurrentRewards(updated)
    }
  }

  const handleModifyToggle = (index) => {
    return (isModifiable) => {
      const updated = currentRewards.map((reward, i) => {
        if (i === index) {
          return { ...reward, customize: isModifiable }
        }

        return reward
      })
      setCurrentRewards(updated)
    }
  }

  const handleDeleteReward = (index) => {
    return () => {
      const reward = currentRewards[index]
      if (reward.id) {
        handleDelete(reward.id)
      } else {
        const updated = [...currentRewards]
        updated.splice(index, 1)
        setCurrentRewards(updated)
      }
    }
  }

  const submit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    handleSubmit(currentRewards)
  }

  const submitDisabled = every(currentRewards, (reward) => reward.defaultValue === "0" || reward.defaultValue === 0) &&
    every(currentRewards, (reward) => reward.max === "0" || reward.max === 0)


  console.log("rewards", currentRewards)
  return (
    <div className="rewards">
      <PrimaryButton
        className="rewardAddButton"
        iconProps={{ iconName: 'Add' }}
        onClick={handleAddNew}
      >
        Add New
      </PrimaryButton>
      <h1>{title}</h1>
      <h4>Reward values should be set to their <strong>yearly</strong> values.</h4>
      <div className="rewardItems">
        { currentRewards.map((reward, index) => (
            <EditReward
              key={`${reward.id}-${index}`}
              rewardId={reward.id}
              label={reward.name}
              description={reward.description}
              required={reward.required}
              customize={reward.customize}
              valueType={reward.valueType}
              minValue={reward.min}
              maxValue={reward.max}
              discountPercent={reward.discountPercent}
              valueMultiplier={reward.valueMultiplier}
              defaultValue={reward.defaultValue}
              onUpdateDescription={handleUpdateDescription(index)}
              onToggleModify={handleModifyToggle(index)}
              onUpdateValueType={handleUpdateValueType(index)}
              onUpdateValueMultiplier={handleUpdateValueMultiplier(index)}
              onUpdateMin={handleUpdateMin(index)}
              onUpdateMax={handleUpdateMax(index)}
              onUpdateDefault={handleUpdateDefault(index)}
              onUpdateDiscount={handleUpdateDiscount(index)}
              onUpdateName={handleUpdateName(index)}
              onDelete={handleDeleteReward(index)}
            />
        ))}
      </div>
      <PrimaryButton className="submit" onClick={submit} disabled={submitDisabled}>
        {buttonText}
      </PrimaryButton>
    </div>
  )
}

Rewards.defaultProps = {
  rewards: [],
  handleSubmit: () => {},
  handleDelete: () => {},
  buttonText: 'Submit',
  title: 'Configure Company Rewards',
}

export default Rewards
