import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import {
  PrimaryButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  TextField,
  SpinButton,
  Dropdown,
} from "@fluentui/react"

import { selectCompanyRoles, selectLatestRoleId } from "selectors/company"

import { EMAIL_REGEX } from 'constants/email'

import './EmployeeModal.scss'
import AddRole from "./AddRole"

const EmployeeModal = ({
  isOpen,
  employeeFirstName,
  employeeLastName,
  employeeEmail,
  employeeBaseSalary,
  employeeRoleId,
  modalTitle,
  onSubmit,
  onClose,
  submitLabel,
  showEmail,
}) => {
  const companyRoles = useSelector(selectCompanyRoles)
  const latestRoleId = useSelector(selectLatestRoleId)

  const [firstName, setFirstName] = useState(employeeFirstName)
  const [lastName, setLastName] = useState(employeeLastName)
  const [email, setEmail] = useState(employeeEmail)
  const [emailError, setEmailError] = useState(false)
  const [baseSalary, setBaseSalary] = useState(employeeBaseSalary)
  const [selectedRoleId, setSelectedRoleId] = useState(employeeRoleId)

  const currentRole = companyRoles.find((role) => role.id === selectedRoleId)

  // TODO: This is a little hacky.  Consider a better strategy
  // This is for auto-setting a role after creation from this modal
  useEffect(() => {
    if (companyRoles.length > 0 && latestRoleId) {
      setSelectedRoleId(latestRoleId)
    }
  }, [companyRoles, latestRoleId])

  const handleCloseModal = () => {
    setFirstName("")
    setLastName("")
    setEmail("")
    setBaseSalary(0)
    setSelectedRoleId(null)
    setEmailError(false)
    onClose()
  }

  const handleFirstNameChange = (_ev, value) => {
    setFirstName(value)
  }

  const handleLastNameChange = (_ev, value) => {
    setLastName(value)
  }

  const handleEmailChange = (_ev, value) => {
    setEmail(value)
  }

  const handleRoleChange = (_ev, value) => {
    setSelectedRoleId(value.key)
  }

  const handleBaseSalaryChange = (_ev, value) => {
    let newValue = parseInt(value)
    if (currentRole?.minSalary) {
      newValue = Math.max(newValue, currentRole.minSalary)
    }
    if (currentRole?.maxSalary) {
      newValue = Math.min(newValue, currentRole.maxSalary)
    }
    setBaseSalary(newValue)
  }

  const handleValidateSalary = (value, _ev) => {
    return Number(value.replace(/[^0-9.]+/g,""))
  }

  const handleIncrementSalary = () => {
    return baseSalary + 100
  }

  const handleDecrementSalary = () => {
    return Math.max(0, baseSalary - 100)
  }

  const handleEmailError = (value) => {
    if (EMAIL_REGEX.test(value) || value === "") {
      setEmailError(false)
      return ''
    } else {
      setEmailError(true)
      return "Email must be a valid syntax"
    }
  }

  const handleSubmit = () => {
    onSubmit({ firstName, lastName, email, baseSalary, selectedRoleId })
  }

  const dialogContentProps = {
    title: modalTitle,
    closeButtonAriaLabel: 'Close',
  }

  const disabled = (showEmail && !email) || emailError || !firstName || !lastName || !baseSalary || !selectedRoleId
  const baseSalaryWithDefault = baseSalary || 0

  const getMaxSalaryStatement = () => {
    if (currentRole?.maxSalary) {
      return ` and less than or equal to the max salary of $${currentRole.maxSalary.toLocaleString('en-us')}`
    }
  }

  return (
    <Dialog
      className='employeeModal'
      hidden={!isOpen}
      onDismiss={handleCloseModal}
      dialogContentProps={dialogContentProps}
      modalProps={{ isBlocking: true }}
    >
      <TextField label="First Name" value={firstName} onChange ={handleFirstNameChange} />
      <TextField label="Last Name" value={lastName} onChange ={handleLastNameChange} />
      { showEmail && (
        <TextField
          label="Email"
          value={email}
          onChange ={handleEmailChange}
          onGetErrorMessage={handleEmailError}
          deferredValidationTime={750}
        />
      )}
      <div className="addEmployeeRole">
        <Dropdown
          label="Role"
          selectedKey={selectedRoleId}
          options={companyRoles.map(role => ({ key: role.id, text: role.name }))}
          onChange={handleRoleChange}
          placeholder="Select a role"
        />
        <AddRole className="addEmployeeRoleButton" />
      </div>
      <>
        <SpinButton
          className="salarySpinner"
          label="Base Salary"
          value={`$${baseSalaryWithDefault.toLocaleString('en-us')}`}
          min={currentRole?.minSalary || 0}
          max={currentRole?.maxSalary || null}
          step={100}
          onChange={handleBaseSalaryChange}
          onValidate={handleValidateSalary}
          onIncrement={handleIncrementSalary}
          onDecrement={handleDecrementSalary}
          incrementButtonAriaLabel="Increase value by 100"
          decrementButtonAriaLabel="Decrease value by 100"
        />
        { currentRole?.minSalary && <p className="spinnerSubtext">
                                      Base salary must be greater than or equal to the minimum salary, which is
                                      ${currentRole.minSalary.toLocaleString('en-us')} {getMaxSalaryStatement()}
                                    </p>
        }
      </>

      <DialogFooter>
        <PrimaryButton onClick={handleSubmit} text={submitLabel} disabled={disabled} />
        <DefaultButton onClick={handleCloseModal} text="Cancel" />
      </DialogFooter>
    </Dialog>
  )
}

EmployeeModal.defaultProps = {
  employeeFirstName: '',
  employeeLastName: '',
  employeeEmail: '',
  employeeRoleId: null,
  employeeBaseSalary: 0,
  isOpen: false,
  submitLabel: 'Submit',
  modalTitle: 'Employee',
  onSubmit: () => {},
  onClose: () => {},
  showEmail: true,
}

export default EmployeeModal
