import {
  SUBMIT_FEEDBACK_FAILURE,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_REQUESTED,
  FETCH_FEEDBACK_SUMMARY_FAILURE,
  FETCH_FEEDBACK_SUMMARY_REQUESTED,
  FETCH_FEEDBACK_SUMMARY_SUCCESS,
} from 'constants/actions'

const initialState = {
  isLoading: false,
  isError: false,
  isSubmitted: false,
  summary: {},
}

const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SUBMIT_FEEDBACK_REQUESTED:
    case FETCH_FEEDBACK_SUMMARY_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSubmitted: false,
      }
    case SUBMIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSubmitted: true,
      }
    case FETCH_FEEDBACK_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        summary: data.summary,
      }
    case SUBMIT_FEEDBACK_FAILURE:
    case FETCH_FEEDBACK_SUMMARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSubmitted: false,
      }
    default:
      return state
  }
}

export default reducer
