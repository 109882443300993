import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'

import {
  getCompanyRoles,
} from "actions/company"
import { selectCompany, selectCompanyRoles } from "selectors/company"

import {
  ChoiceGroup,
  PrimaryButton,
  DetailsList,
  Icon,
  TextField,
  DialogType,
} from "@fluentui/react"

import { EMAIL_REGEX } from 'constants/email'

import './OfferLetters.scss'
import { selectOfferLetterApprovers, selectOfferLetters } from "selectors/offerLetters"
import {
  getOfferLetters,
  getOfferLetterApprovers,
  createDefaultOfferApprover,
  deleteDefaultOfferApprover,
  copiedLink,
} from "actions/offerLetters"
import EditApprovers from "./EditApprovers"
import classNames from "classnames"
import StatCard from "components/shared/StatCard"
import { ResponsivePie } from "@nivo/pie"

const filters = [
  { key: 'all', text: 'All Offers' },
  { key: 'pendingApproval', text: 'Pending Approval' },
  { key: 'approved', text: 'Approved' },
  { key: 'accepted', text: 'Offer Accepted' },
  { key: 'rejected', text: 'Not Approved'}
]

const CopyOfferLetterLink = ({ accessToken, status }) => {
  const offerLink = `${process.env.REACT_APP_BASE_URL}/offer?token=${accessToken}`
  const disabled = status !== 'approved'
  const dispatch = useDispatch()
  const handleCopiedLink = () => {
    dispatch(copiedLink())
  }

  if (disabled) {
    return (
      <Icon className={classNames("tableIcon", { 'disabled': true })} iconName="Copy" />
    )
  } else {
    return (
      <CopyToClipboard text={offerLink} onCopy={handleCopiedLink}>
        <Icon className="tableIcon" iconName="Copy" />
      </CopyToClipboard>
    )
  }

}

const defaultColumns = [
  {
    key: 'statusSummary',
    name: 'Status',
    minWidth: 120,
    maxWidth: 120,
    fieldName: 'statusSummary',
    isResizable: true,
    onRender: (item) => startCase(item.statusSummary)
  },
  {
    key: 'name',
    name: 'Name',
    minWidth: 125,
    maxWidth: 125,
    fieldName: 'name',
    isResizable: true,
  },
  {
    key: 'department',
    name: 'Department',
    minWidth: 100,
    maxWidth: 200,
    fieldName: 'department',
    isResizable: true,
  },
  {
    key: 'companyRole',
    name: 'Role',
    minWidth: 100,
    maxWidth: 150,
    fieldName: 'companyRole',
    isResizable: true,
  },
  {
    key: 'approvers',
    name: 'Approvers',
    minWidth: 100,
    fieldName: 'approvers',
    isResizable: true,
  },
  {
    key: 'copy',
    name: 'Offer Link',
    minWidth: 70,
    maxWidth: 70,
    onRender: (item) => (
      <div className="copyOfferLink">
        <CopyOfferLetterLink accessToken={item.accessToken} status={item.statusSummary} />
      </div>
    ),
  },
  {
    key: 'view',
    name: 'View',
    minWidth: 40,
    maxWidth: 40,
    onRender: (item) => (
      <Link to={`/offer/${item.id}`}>
        <Icon className="tableIcon navigateIcon" iconName="NavigateExternalInline" />
      </Link>
    ),
  },
]

const OfferLetters = () => {
  const dispatch = useDispatch()
  const company = useSelector(selectCompany)
  const offers = useSelector(selectOfferLetters)
  const roles = useSelector(selectCompanyRoles)
  const approvers = useSelector(selectOfferLetterApprovers)

  const [approverModalOpen, setApproverModalOpen] = useState(false)
  const [approverEmail, setApproverEmail] = useState("")
  const [approverEmailList, setApproverEmailList] = useState([])
  const [statusFilter, setStatusFilter] = useState('all')
  const [nameFilter, setNameFilter] = useState(null)
  const [columns, setColumns] = useState(defaultColumns)
  const [offerList, setOfferList] = useState(offers)
  const computedOfferList = offerList.map((offer) => {
    const userRole = roles.find(role => role.id === offer.companyRoleId) || {}
    const name = offer.firstName && offer.lastName ? `${offer.firstName} ${offer.lastName}` : ''
    const approverEmails = offer.approvalSummary || []

    return {
      ...offer,
      approvers: approverEmails.map((approver) => approver.email).join(", "),
      name,
      companyRole: userRole.name,
      department: userRole.department,
    }
  })

  useEffect(() => {
    dispatch(getCompanyRoles(company?.id))
    dispatch(getOfferLetterApprovers(company?.id))
    dispatch(getOfferLetters(company?.id))
  }, [dispatch, company])

  useEffect(() => {
    setOfferList(offers)
  }, [offers])

  useEffect(() => {
    const approverEmails = approvers.map((approver) => approver.email)
    setApproverEmailList(approverEmails)
  }, [approvers])

  const handleSetStatusKey = (ev, filter) => {
    setStatusFilter(filter.key)
  }

  const handleColumnClick = (ev, column) => {
    const newColumns = columns.slice()

    if (column.key === 'view') { return }

    const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0]
    newColumns.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending
        currColumn.isSorted = true
      } else {
        newCol.isSorted = false
        newCol.isSortedDescending = true
      }
    })

    const newItems = copyAndSort(computedOfferList, currColumn.fieldName, currColumn.isSortedDescending)

    setColumns(columns)
    setOfferList(newItems)
  }

  const copyAndSort = (items, columnKey, isSortedDescending) => {
    const key = columnKey
    return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1))
  }

  const columnsWithClickHandler = columns.map((column) => {
    return {
      ...column,
      onColumnClick: handleColumnClick,
    }
  })

  const statusFilteredList = computedOfferList.filter((offer) => {
    switch (statusFilter) {
      case 'pendingApproval':
        return offer.statusSummary === 'pending'
      case 'approved':
        return offer.statusSummary === 'approved'
      case 'accepted':
        return offer.statusSummary === 'accepted'
      case 'rejected':
        return offer.statusSummary === 'rejected'
      default:
        return true
    }
  })

  const filteredOfferList = statusFilteredList.filter((offer) => {
    if (nameFilter) {
      return offer.name.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1
    } else {
      return true
    }
  })

  const handleFilterName = (ev, text) => {
    setNameFilter(text)
  }

  const handleOpenApprovers = () => {
    setApproverModalOpen(true)
  }

  const handleCloseApprovers = () => {
    setApproverModalOpen(false)
  }

  const handleUpdateApproverEmail = (event) => {
    setApproverEmail(event.target.value)
  }

  const handleCreateApprover = () => {
    if (!isEmpty(approverEmail) && EMAIL_REGEX.test(approverEmail)) {
      dispatch(createDefaultOfferApprover(company?.id, approverEmail))
      setApproverEmail("")
    }
  }

  const handleDeleteApprover = (email) => {
    dispatch(deleteDefaultOfferApprover(company?.id, email))
  }

  const approverModalProps = {
    type: DialogType.normal,
    title: "Set Default Approvers",
    closeButtonAriaLabel: "Close",
    subText: "Manage who is required for approval of each offer letter by entering their email addresses below",
  }

  const offersCreated = offerList.length
  const offersAccepted = computedOfferList.filter((offer) => offer.statusSummary === "accepted").length
  const offersPendingApproval = computedOfferList.filter((offer) => offer.statusSummary === "pending").length
  const offersApproved = computedOfferList.filter((offer) => offer.statusSummary === "approved").length
  const offersRejected = computedOfferList.filter((offer) => offer.statusSummary === "rejected").length

  const statsData = [
    {
      id: "Candidate Accepted",
      value: offersAccepted,
    },
    {
      id: "Pending Candidate",
      value: offersApproved,
    },
    {
      id: "Approved",
      value: offersApproved,
    },
    {
      id: "Approval Rejected",
      value: offersRejected,
    },
    {
      id: "Pending Approval",
      value: offersPendingApproval,
    },
  ]

  const statsDataFiltered = statsData.filter((stat) => stat.value > 0)

  return (
    <>
      <div className="offerLetters">
        <h1>Offer Letters</h1>
        <div className="offerActions">
          <Link to="/offers/new">
            <PrimaryButton>
              Create Offer
            </PrimaryButton>
          </Link>
          <PrimaryButton onClick={handleOpenApprovers}>
            Set Approvers
          </PrimaryButton>
        </div>
        <div className="offerLetterTopSection">
          <div className="offerFilters">
            <ChoiceGroup
              className="offerStatusFilters"
              label="Filter by status"
              defaultSelectedKey="all"
              options={filters}
              onChange={handleSetStatusKey}
            />
            <TextField className="offerNameFilter" label="Filter by name:" onChange={handleFilterName} />
          </div>
          <div className="offerStats">
            <div className="offerStatsLeft">
              <div>
                <span className="offerStatsTitle">
                  Candidate Status
                </span>
                <div className="offerStatsRow">
                  <StatCard
                    className="offerStat"
                    value={offersCreated}
                    title="Created"
                  />
                  <StatCard
                    className="offerStat"
                    value={offersAccepted}
                    title="Accepted"
                  />
                  <StatCard
                    className="offerStat"
                    value={offersApproved}
                    title="Pending"
                  />
                </div>
              </div>
              <div>
                <span className="offerStatsTitle">
                  Internal Approval Status
                </span>
                <div className="offerStatsRow">
                  <StatCard
                    className="offerStat"
                    value={offersApproved}
                    title="Approved"
                  />
                  <StatCard
                    className="offerStat"
                    value={offersRejected}
                    title="Rejected"
                  />
                  <StatCard
                    className="offerStat"
                    value={offersPendingApproval}
                    title="Pending"
                  />
                </div>
              </div>
            </div>
            <div className="offerStatsRight">
              <ResponsivePie
                startAngle={360}
                endAngle={0}
                cornerRadius={2}
                padAngle={0.7}
                data={statsDataFiltered}
                innerRadius={0.5}
                enableArcLinkLabels={false}
                enableArcLabels={false}
              />
            </div>
          </div>
        </div>
        <DetailsList
          className="offerTable"
          items={filteredOfferList}
          columns={columnsWithClickHandler}
          getKey={(item) => item.id}
          setKey="none"
          selectionMode="none"
          checkboxVisibility={2}
        />
      </div>
      <EditApprovers
        isOpen={approverModalOpen}
        onClose={handleCloseApprovers}
        onCreate={handleCreateApprover}
        onChange={handleUpdateApproverEmail}
        onDelete={handleDeleteApprover}
        modalProps={approverModalProps}
        approverEmailList={approverEmailList}
        approverEmail={approverEmail}
      />
    </>
  )
}

export default OfferLetters
