import {
  SET_PREVIEW_STATUS,
  SELECT_PREVIEW_REWARDS,
  SET_PREVIEW_SALARY,
  PREVIEW_CREATED_SUCCESS,
  FETCH_PREVIEW_SUCCESS,
} from 'constants/actions'

import {
  PREVIEW_BUNDLE,
  WELCOME,
} from 'constants/preview'

const initialState = {
  status: WELCOME,
  salary: 0,
  rewards: {
    selections: [],
    customSelections: [],
  },
  bundleData: null,
}

const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case PREVIEW_CREATED_SUCCESS:
    case FETCH_PREVIEW_SUCCESS:
      return {
        ...state,
        bundleData: data,
      }
    case SET_PREVIEW_STATUS:
      return {
        ...state,
        status: data,
      }
    case SELECT_PREVIEW_REWARDS:
      return {
        ...state,
        rewards: {
          selections: data.selections,
          customSelections: data.customSelections,
        },
        status: PREVIEW_BUNDLE,
      }
    case SET_PREVIEW_SALARY:
      return {
        ...state,
        salary: data,
      }
    default:
      return state
  }
}

export default reducer
