import client from "./client"
import multiClient from "./multiClient"

export const authenticateUser = (email, password) => {
  return client.post('/login', {
    email,
    password,
  })
}

export const signUpUser = (email, password) => {
  return client.post('/users', {
    email,
    password,
    role: 'admin',
  })
}

export const setUserPassword = (id, password) => {
  return client.put(`/users/${id}`, {
    id,
    password,
  })
}

export const fetchUser = () => {
  return client.get('/auto_login')
}

export const getCompanyRewards = (companyId) => {
  return client.get(`/companies/${companyId}/rewards`)
}

export const getCompanyUsers = (companyId) => {
  return client.get(`/companies/${companyId}/users`)
}

export const getCompanyUser = (companyId, userId) => {
  return client.get(`/companies/${companyId}/users/${userId}`)
}

export const getCompanyRoles = (companyId) => {
  return client.get(`/companies/${companyId}/roles`)
}

export const sendUserBundle = (userId, companyId) => {
  return client.post('/companies/send_bundle', {
    id: companyId,
    user_id: userId,
  })
}

export const bulkSendBundle = (companyId) => {
  return client.post(`/companies/${companyId}/bulk_send_bundle`)
}

export const createCompany = ({ name, url, address }) => {
  return client.post('/companies', {
    name,
    url,
    address,
  })
}

export const submitRewards = ({ id, rewards }) => {
  return client.post('/companies/rewards', {
    id,
    rewards,
  })
}

export const deleteReward = ({ id, rewardId }) => {
  return client.delete(`/companies/${id}/reward/${rewardId}`)
}

export const setDefaultBundle = ({ id, roleId, rolePercent, roleMinSalary, roleMaxSalary, rewardValues }) => {
  return client.post('/companies/default_bundle', {
    id,
    role_id: roleId,
    point_percent: rolePercent,
    reward_values: rewardValues,
    min_salary: roleMinSalary,
    max_salary: roleMaxSalary,
  })
}

export const createBundle = (userId, config, fourDayEnabled, notes) => {
  return client.post('/bundles', {
    user_id: userId,
    config,
    four_day_enabled: fourDayEnabled,
    notes,
  })
}

export const verifyBundle = (bundleId) => {
  return client.post('/bundles/verify', {
    id: bundleId,
  })
}

export const uploadEmployees = (formData) => {
  return multiClient.post('/companies/upload_employees', formData)
}

export const exportEmployees = (companyId) => {
  return client.get(`/companies/${companyId}/export`, {
    responseType: 'blob',
    headers: { 'Content-Type': 'text/csv' }
  })
}

export const addRole = (name, department, pointPercent, minSalary, maxSalary, companyId) => {
  return client.post('/company_roles', {
    company_id: companyId,
    name,
    department,
    point_percent: pointPercent,
    min_salary: minSalary,
    max_salary: maxSalary,
  })
}

export const editRole = (id, { department, percent, minSalary, maxSalary, name, fourDayRatio }, companyId) => {
  return client.put('/company_roles', {
    id,
    company_id: companyId,
    name,
    department,
    point_percent: percent,
    min_salary: minSalary,
    max_salary: maxSalary,
    four_day_ratio: fourDayRatio,
  })
}

export const addEmployee = ({ firstName, lastName, email, baseSalary}, roleId, companyId) => {
  return client.post('/companies/add_user', {
    id: companyId,
    role_id: roleId,
    first_name: firstName,
    last_name: lastName,
    email,
    base_salary: baseSalary,
  })
}

export const editEmployee = (id, { firstName, lastName, email, baseSalary, roleId }, companyId) => {
  return client.put('/companies/edit_user', {
    id: companyId,
    user_id: id,
    company_role_id: roleId,
    first_name: firstName,
    last_name: lastName,
    email,
    base_salary: baseSalary,
  })
}

export const archiveEmployee = (id, companyId) => {
  return client.put('/companies/archive_user', {
    id: companyId,
    user_id: id,
  })
}

export const unarchiveEmployee = (id, companyId) => {
  return client.put('/companies/unarchive_user', {
    id: companyId,
    user_id: id,
  })
}

export const fetchAnalytics = (companyId) => {
  return client.get(`/analytics/${companyId}`)
}

export const submitFeedback = ({
  companyId,
  bundleValues,
  baseSalary,
  department,
  overallRating,
  customizeRating,
  topReward,
}) => {
  return client.post('/feedback', {
    company_id: companyId,
    bundle_config: bundleValues,
    base_salary: baseSalary,
    department,
    overall_rating: overallRating,
    customize_rating: customizeRating,
    top_reward: topReward,
  })
}

export const fetchFeedbackSummary = (companyId) => {
  return client.get(`/feedback?company_id=${companyId}`)
}

export const requestDemo = (companyId) => {
  return client.post(`/companies/${companyId}/request_demo`)
}

export const fetchOfferLetters = (companyId) => {
  return client.get(`/offer_letters?company_id=${companyId}`)
}

export const fetchOfferLetter = (offerId) => {
  return client.get(`/offer_letters/${offerId}`)
}


export const fetchOfferLetterApprovers = (companyId) => {
  return client.get(`/offer_letters/approvers?company_id=${companyId}`)
}

export const createOfferLetter = ({
  companyId,
  firstName,
  lastName,
  email,
  companyRoleId,
  baseSalary,
}) => {
  return client.post('/offer_letters', {
    company_id: companyId,
    first_name: firstName,
    last_name: lastName,
    email,
    company_role_id: companyRoleId,
    base_salary: baseSalary,
  })
}

export const updateOfferLetter = ({
  offerId,
  firstName,
  lastName,
  email,
  companyRoleId,
  baseSalary,
}) => {
  return client.put(`/offer_letters/${offerId}`, {
    first_name: firstName,
    last_name: lastName,
    email,
    company_role_id: companyRoleId,
    base_salary: baseSalary,
  })
}

export const createDefaultOfferApprover = (companyId, approver) => {
  return client.post('/offer_letters/approvers', {
    company_id: companyId,
    email: approver,
  })
}

export const deleteDefaultOfferApprover = (companyId, approver) => {
  return client.delete('/offer_letters/approvers', {
    data: {
      company_id: companyId,
      email: approver,
    },
  })
}

export const createOfferApprover = (offerId, approver) => {
  return client.post(`/offer_letters/${offerId}/approvers`, {
    email: approver,
  })
}

export const deleteOfferApprover = (offerId, approver) => {
  return client.delete(`/offer_letters/${offerId}/approvers`, {
    data: {
      email: approver,
    },
  })
}

export const sendOfferApprovers = (offerId) => {
  return client.post(`/offer_letters/${offerId}/send_to_approvers`)
}


export const approveOffer = (offerId, note) => {
  return client.put(`/offer_letters/${offerId}/approve`, {
    note,
  })
}

export const rejectOffer = (offerId, note) => {
  return client.put(`/offer_letters/${offerId}/reject`, {
    note,
  })
}

export const archiveOffer = (offerId) => {
  return client.put(`/offer_letters/${offerId}/archive`)
}

export const unarchiveOffer = (offerId) => {
  return client.put(`/offer_letters/${offerId}/unarchive`)
}

export const createBundlePreview = (config, fourDayEnabled) => {
  return client.post('/bundle_previews', {
    config,
    four_day_enabled: fourDayEnabled,
  })
}

export const fetchBundlePreview = (id) => {
  return client.get(`/bundle_previews/${id}`)
}
