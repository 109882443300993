import { useDispatch, useSelector } from "react-redux"

import { selectCompany } from "selectors/company"
import { selectRewardCustomSelections, selectRewardSelections } from "selectors/onboarding"

import { submitRewards } from "actions/company"

import './SetRewards.scss'

import {
  MATCH_401K,
  HEALTHCARE,
  PAID_TIME_OFF,
  COMPANY_EQUITY,
  COMMUTER_BENEFITS,
  WFH_STIPEND,
  MONETARY_STIPEND,
  CHARITABLE_GIVING,
  PEER_TO_PEER_REC,
  PROFESSIONAL_DEVELOPMENT,
  EDUCATION_SAVINGS,
} from 'constants/rewards'
import Rewards from "components/shared/Rewards"

const REWARD_MAPPING = {
  [MATCH_401K]: {
    label: "401K Matching",
    valueType: "percent",
  },
  [HEALTHCARE]: {
    label: "Healthcare",
    valueType: "dollar",
  },
  [PAID_TIME_OFF]: {
    label: "Paid Time Off",
    valueType: "day",
  },
  [COMPANY_EQUITY]: {
    label: "Company Equity",
    valueType: "share",
  },
  [COMMUTER_BENEFITS]: {
    label: "Commuter Benefits",
    valueType: "dollar",
  },
  [WFH_STIPEND]: {
    label: "WFH Stipend",
    valueType: "dollar",
  },
  [MONETARY_STIPEND]: {
    label: "Monetary Stipend",
    valueType: "dollar",
  },
  [CHARITABLE_GIVING]: {
    label: "Charitable Giving",
    valueType: "dollar",
  },
  [PEER_TO_PEER_REC]: {
    label: "Peer-to-peer Recognition",
    valueType: "dollar",
  },
  [PROFESSIONAL_DEVELOPMENT]: {
    label: "Professional Development",
    valueType: "dollar",
  },
  [EDUCATION_SAVINGS]: {
    label: "Education Savings",
    valueType: "dollar",
  },
}

const SetRewards = () => {
  const dispatch = useDispatch()
  const companyId = useSelector(selectCompany)?.id
  const rewardSelections = useSelector(selectRewardSelections) || []
  const rewardCustomSelections = useSelector(selectRewardCustomSelections) || []
  const allRewards = rewardSelections.concat(rewardCustomSelections).map((reward) => {
    return {
      name: REWARD_MAPPING[reward]?.label || reward,
      valueType: REWARD_MAPPING[reward]?.valueType || 'dollar',
      description: "",
      required: false,
      min: 0,
      max: 0,
      defaultValue: 0,
      discountPercent: 0,
      customize: false,
    }
  })

  const submit = (rewards) => {
    dispatch(submitRewards({
      id: companyId,
      rewards,
    }))
  }

  return (
    <div className="setRewards">
      <Rewards
        rewards={allRewards}
        handleSubmit={submit}
        title='Configure Company Rewards'
        buttonText='Complete Setup'
      />
    </div>
  )
}

export default SetRewards
